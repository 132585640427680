import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesEditForm } from '@bones/edit';

import { Drugstore } from '@med/admin/class/Drugstore';
import { DrugstoreService } from '@med/admin/service/drugstore';
import { DrugstoreEditPage } from '../drugstore-edit/drugstore-edit';

@Component({
    selector: 'page-drugstore-list',
    templateUrl: 'drugstore-list.html'
})
export class DrugstoreListPage implements OnInit
{
    public rows: Drugstore[];
    menu: BonesMenuCardAction[];

    constructor(
        private modalCtrl: ModalController,
        private es: BonesErrorService,
        private db: DrugstoreService
    )
    {
        this.menu = [ { title: 'Add', icon: 'pencil', action: () => this.edit() } ];
    }

    async ngOnInit()
    {
        this.getData();
    }

    async getData()
    {
        this.db.getDrugstores()
        .then(rows => this.rows = rows)
        .catch(error => this.es.errorHandler(error));
    }

    edit(row?: Drugstore)
    {
        BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugstoreEditPage,
            pk: row ? row.drugStoreID : undefined,
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                // Refresh page
                this.getData();
            }
        });
    }

}
