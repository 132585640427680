import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { KpsRest } from '@med/core';

import { DrugType } from '../class/DrugType';

/**
 * Access db
 */
@Injectable()
export class DrugTypeService
{

    constructor(
        private rest: KpsRest,
    )
    {
    }

    async getDrugTypes() : Promise<DrugType[]>
    {
        return this.rest.send('admin/DrugType.php/getDrugTypes')
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'DrugTypeService',
                methodName: 'getDrugTypes',
                message: 'Unable to get drug types',
                error: error
            });
        });
    }

    async getDrugType(drugTypeID: number) : Promise<DrugType>
    {
        return this.rest.send('admin/DrugType.php/getDrugType', { drugTypeID })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'DrugTypeService',
                methodName: 'getDrugType',
                message: 'Unable to get drug type',
                error: error
            })
            .add({ drugTypeID });
        });
    }

    //-----------------------------------------------------------------------

}
