import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';
import { KpsRest, UserService } from '@med/core';

import { Note } from '../class/Note';
import { AppointmentInfo } from '../class/AppointmentInfo';
import { NoteInfo } from '../class/NoteInfo';
import { AppointmentService } from './appointment';
import { ProviderService } from './provider';
import { PractitionerService } from './practitioner';
import { DrugService } from './drug';
import { PrescriptionService } from './drug-script';

/**
 * Access notes
 */
@Injectable({
  providedIn: 'root',
})
export class NoteService
{
    cache: BonesCache<number, NoteInfo, Note>;
    notePromise: Promise<void>;
    noteList: Note[] = [ ];

    constructor(
        private bcf: BonesCacheFactory,
        private rest: KpsRest,
        private mtus: UserService,
        private appointmentDB: AppointmentService,
        private providerDB: ProviderService,
        private practitionerDB: PractitionerService,
        private drugDB: DrugService,
        private scriptDB: PrescriptionService
    )
    {
        this.cache = this.bcf.create<number, NoteInfo, Note>(
        {
            pk: 'noteID',
            loadCache: () => this.rest.send('provider/Note.php/getNotes'),
            reloadOne: (id: number) => this.rest.send('provider/Note.php/getNote', { noteID: id }),
            converter: async (info: NoteInfo) : Promise<Note> =>
            {
                const note = new Note(info.dt, info.patientID, info.notes);

                note.noteID = info.noteID;
                note.info = info;
                note.patient = await this.mtus.getPatient(info.patientID);

                if (info.providerID)
                {
                    note.provider = await this.providerDB.getProvider(info.providerID);
                }

                if (info.practitionerID)
                {
                    note.practitioner = await this.practitionerDB.getPractitioner(info.practitionerID);
                    note.provider = await this.providerDB.getProvider(note.practitioner.providerID);
                }

                if (info.drugID)
                {
                    note.drug = await this.drugDB.getDrug(info.drugID);
                }

                if (info.scriptID)
                {
                    note.script = await this.scriptDB.getPrescription(info.scriptID);
                }

                return note;
            },
            sorter: (a: Note, b: Note) => b.dt.getTime() - a.dt.getTime()
        });
    }

    //-----------------------------------------------------------------------

    private async loadNotes() : Promise<void>
    {
        // if (!this.notePromise)
        {
            // Load notes
            this.notePromise = new Promise(async resolve =>
            {
                // Start with the actual notes
                // this.bones.copy(await this.cache.getList(), this.noteList);
                this.noteList = [ ... await this.cache.getList() ];

                // Mix in any appointments that have notes
                await this.appointmentDB.cache.getList()
                .then(async rows =>
                {
                    for (let i = 0; (i < rows.length); ++i)
                    {
                        const row: AppointmentInfo = rows[i];

                        if (row.notes)
                        {
                            const note = new Note(row.scheduled, row.patientID, row.notes);

                            note.appointment = await this.appointmentDB.getAppointment(row.appointmentID);
                            note.practitioner = await this.practitionerDB.getPractitioner(row.practitionerID);

                            if (note.practitioner)
                            {
                                note.provider = await this.providerDB.getProvider(note.practitioner.providerID);
                            }

                            this.noteList.push(note);
                        }
                    }
                });

                // Resort combined list
                this.noteList.sort((a, b) => b.dt.getTime() - a.dt.getTime());

                resolve();
            });
        }

        return this.notePromise;
    }

    //-----------------------------------------------------------------------

    async getAllNotes() : Promise<Note[]>
    {
        await this.loadNotes();
        return this.noteList;
    }

    // async getNotesForPatient(patientID: number) : Promise<Note[]>
    // {
    //     await this.loadNotes();

    //     return this.noteList.filter(r => r.patientID === patientID);
    // }

    // async getNotesForPractitioner(patientID: number, practitionerID: number) : Promise<Note[]>
    // {
    //     return (await this.getNotesForPatient(patientID))
    //     .filter(r => r.practitioner && r.practitioner.practitionerID === practitionerID);
    // }

    // async getNotesForProvider(patientID: number, providerID: number) : Promise<Note[]>
    // {
    //     return (await this.getNotesForPatient(patientID))
    //     .filter(r => r.provider && r.provider.providerID === providerID);
    // }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class NoteFilter
{
    constructor(public rows: Note[])
    {
    }

    recent(days: number) : NoteFilter
    {
        if (days)
        {
            const thePast = new Date();
            thePast.setDate(thePast.getDate() - days);
            const thePastTime = thePast.getTime();
            this.rows = this.rows.filter(r => r.dt.getTime() > thePastTime);
        }
        return this;
    }

    byPatient(patientID: number) : NoteFilter
    {
        this.rows = this.rows.filter(r => r.patientID === patientID);
        // console.log('notes.byPatient', patientID, this.rows);
        return this;
    }

    byProvider(providerID: number) : NoteFilter
    {
        this.rows = this.rows.filter(r => r.info.providerID === providerID);
        return this;
    }

    byPractitioner(practitionerID: number) : NoteFilter
    {
        this.rows = this.rows.filter(r => r.info.practitionerID === practitionerID);
        return this;
    }

    byDrug(drugID: number) : NoteFilter
    {
        this.rows = this.rows.filter(r => r.info.drugID === drugID);
        return this;
    }

    byKeyword(keyword: string) : NoteFilter
    {
        if (keyword)
        {
            const lckw = keyword.toLowerCase();

            this.rows = this.rows.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);

                return match;
            });
        }

        // console.log('notes.byKeyword', keyword, this.rows);
        return this;
    }
}
