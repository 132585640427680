/**
 * All public exports for module
 */

// Export the module itself
export { AppCoreModule } from './module';

// Export pages that are accessed externally, normally just the root page, may be better to use
// a component instead

// Services can be re-exported to import from the top level module instead of having to import from
// the nested component .ts file
export { AppUser } from './class/AppUser';
export { AppUserInfo } from './class/AppUserInfo';
export { PatientInfo } from './class/PatientInfo';

export { UserService } from './service/UserService';
export { AuthGuard } from './service/AuthGuard';
export { KpsRest } from './service/KpsRest';
export { KpsRestCacheFactory } from './service/KpsRestCacheFactory';
