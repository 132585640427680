import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService } from '@bones/core';
import { BonesForm } from '@bones/form';

import { SearchFilters } from '@med/provider/class/SearchFilters';
import { SearchFiltersService } from '@med/provider/service/search-filter';
import { SearchFiltersComponent } from '@med/provider/component/search-filters/search-filters';
import { DrugUseService, DrugUseFilter } from '@med/provider/service/drug-use';
import { DrugUse } from '@med/provider/class/DrugUse';
import { Drug } from '@med/provider/class/Drug';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';

interface UsageGroup
{
    drug: Drug;
    rows: DrugUse[];
}

@Component({
    selector: 'page-drug-use-list',
    templateUrl: 'drug-use-list.html'
})
export class DrugUseListPage implements OnInit, OnDestroy
{
    public rows: DrugUse[];
    public filtered: DrugUse[];
    public groups: UsageGroup[];
    public groupMap: Map<number, UsageGroup> = new Map();
    nowAndLaterCleanup: () => void;
    useControls =
    [
        'keyword', 'beginDate', 'endDate', 'patientID', 'drugID', 'drugTypeID', 'isActive'
    ];
    defaults: SearchFilters;
    @ViewChild('filters', {static: false}) filters: SearchFiltersComponent;

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        private launch: LaunchEditModalService,
        private sfs: SearchFiltersService,
        private usedb: DrugUseService
    )
    {
        // Setup filter defaults
        this.defaults = this.sfs.buildDefaults(this.route.snapshot.params);
    }

    async ngOnInit()
    {
        // Get cache rows
        this.nowAndLaterCleanup = this.usedb.cache.nowAndLater(
        rows =>
        {
            this.rows = rows;

            // Kickoff search if the filters are ready
            if (this.filters)
            {
                this.doSearch(this.filters.getValues());
            }
        },
        error => this.es.errorHandler(error));
    }

    ngOnDestroy()
    {
        this.nowAndLaterCleanup();
    }

    async doReady(form: BonesForm)
    {
        form.setValue('isActive', true);
        this.doSearch(form.getValues());
    }

    async doSearch(values: SearchFilters)
    {
        if (!this.rows)
        {
            return;
        }

        // console.log('SearchFilters', values);

        // Build filter
        const ap = new DrugUseFilter(this.rows);

        if (values.keyword)
        {
            ap.byKeyword(values.keyword);
        }

        if (values.patientID)
        {
            ap.byPatient(values.patientID);
        }

        if (values.beginDate)
        {
            ap.afterDate(values.beginDate);
        }

        if (values.endDate)
        {
            ap.beforeDate(values.endDate);
        }

        if (values.drugID)
        {
            ap.byDrug(values.drugID);
        }

        if (values.drugTypeID)
        {
            ap.byDrugType(values.drugTypeID);
        }

        this.filtered = ap.rows;

        // Group usage by drug
        const groupMap: Map<number, UsageGroup> = new Map();
        this.filtered.forEach(row =>
        {
            if (groupMap.has(row.drugID))
            {
                // Add historical usage
                if (!values.isActive)
                {
                    groupMap.get(row.drugID).rows.push(row);
                }
            }
            else
            {
                groupMap.set(row.drugID, { drug: row.drug, rows: [ row ] });
            }
        });

        // Eliminate groups where the most recent usage record is no longer active
        if (values.isActive)
        {
            groupMap.forEach((group, drugID) =>
            {
                if (group.rows[0].stopped)
                {
                    groupMap.delete(drugID);
                }
            });
        }

        // Extract groups from map and sort by drug name
        this.groups = Array.from(groupMap.values()).sort((a, b) => a.drug.name.localeCompare(b.drug.name));
    }

    add()
    {
        this.launch.addDrugUse(this.filters.getValues());
    }
}
