import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { DrugService } from './drug';
import { PractitionerService } from './practitioner';
import { RefillInfo } from '../class/RefillInfo';
import { Refill } from '../class/Refill';
import { PrescriptionService } from './drug-script';
import { DrugStoreService } from './drug-store';

/**
 * Prescription refill info
 */
@Injectable({
  providedIn: 'root',
})
export class RefillService
{
    cache: BonesCache<number, RefillInfo, Refill>;

    constructor(
        private rest: KpsRest,
        private mtus: UserService,
        private bcf: BonesCacheFactory,
        private drugDB: DrugService,
        private drugStoreDB: DrugStoreService,
        private scriptDB: PrescriptionService,
        private practitionerDB: PractitionerService
    )
    {
        this.cache = this.bcf.create<number, RefillInfo, Refill>(
        {
            pk: 'refillID',
            loadCache: () => this.rest.send('drug/Refill.php/getRefills'),
            reloadOne: (id: number) => this.rest.send('drug/Refill.php/getRefill', { refillID: id }),
            converter: async (info: RefillInfo) : Promise<Refill> =>
            {
                const refill = new Refill(info);

                refill.prescription = await this.scriptDB.getPrescription(refill.scriptID);
                refill.patient = await this.mtus.getPatient(refill.prescription.patientID);
                refill.drug = await this.drugDB.getDrug(refill.prescription.drugID);
                refill.store = await this.drugStoreDB.getDrugStoreInfo(refill.drugstoreID);

                if (refill.prescription.practitionerID)
                {
                    refill.practitioner = await this.practitionerDB.getPractitioner(refill.prescription.practitionerID);
                }

                return refill;
            },
            sorter: (a: Refill, b: Refill) =>
            {
                // Primary sort on drug
                let compare = a.drug.name.localeCompare(b.drug.name);

                // Secondary sort on descending date filled
                if (!compare)
                {
                    if (a.filled && !b.filled)
                    {
                        compare = -1;
                    }
                    else if (!a.filled && b.filled)
                    {
                        compare = 1;
                    }
                    else
                    {
                        compare = b.filledDate.getTime() - a.filledDate.getTime();
                    }
                }

                return compare;
            }
        });
    }

    /**
     * Get single row
     */
    async getPrescription(refillID: number) : Promise<Refill>
    {
        return this.cache.getEntry(refillID);
    }

    /**
     * Get the most recent refill of a drug for a patient
     */
    async getLastRefill(patientID: number, drugID: number) : Promise<Refill>
    {
        const results = new RefillFilter(await this.cache.getList())
        .byPatient(patientID)
        .byDrug(drugID)
        .rows;

        return results.length ? results[0] : undefined;
    }
}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter usage
 */
export class RefillFilter
{
    constructor(public rows: Refill[])
    {
    }

    beforeDate(beforeDate: string) : RefillFilter
    {
        const b4 = new Date(beforeDate);
        this.rows = this.rows.filter(r => r.filledDate.getTime() < b4.getTime());
        return this;
    }

    afterDate(afterDate: string) : RefillFilter
    {
        const b3 = new Date(afterDate);
        this.rows = this.rows.filter(r => r.filledDate.getTime() > b3.getTime());
        return this;
    }

    recent(days: number) : RefillFilter
    {
        if (days)
        {
            const thePast = new Date();
            thePast.setDate(thePast.getDate() - days);
            const thePastTime = thePast.getTime();
            this.rows = this.rows.filter(r => r.filledDate.getTime() > thePastTime);
        }
        return this;
    }

    byPatient(patientID: number) : RefillFilter
    {
        this.rows = this.rows.filter(r => r.patient.patientID === patientID);
        return this;
    }

    byDrug(drugID: number) : RefillFilter
    {
        this.rows = this.rows.filter(r => r.drug.drugID === drugID);
        return this;
    }

    byPractitioner(practitionerID: number) : RefillFilter
    {
        this.rows = this.rows.filter(r => r.practitioner?.practitionerID === practitionerID);
        return this;
    }

    byScript(scriptID: number) : RefillFilter
    {
        this.rows = this.rows.filter(r => r.scriptID === scriptID);
        return this;
    }

    byKeyword(keyword: string) : RefillFilter
    {
        if (keyword)
        {
            const lckw = keyword.toLowerCase();

            this.rows = this.rows.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.quantity && ('' + r.quantity).toLowerCase().indexOf(lckw) >= 0);

                match = match || (r.prescription.productName && r.prescription.productName.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.prescription.notes && r.prescription.notes.toLowerCase().indexOf(lckw) >= 0);

                match = match || (r.drug.brandName && r.drug.brandName.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.drug.genericName && r.drug.genericName.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.drug.notes && r.drug.notes.toLowerCase().indexOf(lckw) >= 0);

                match = match || (r.store.name && r.store.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.store.notes && r.store.notes.toLowerCase().indexOf(lckw) >= 0);

                return match;
            });
        }

        return this;
    }
}
