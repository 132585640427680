import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { Appointment } from '../class/Appointment';
import { AppointmentInfo } from '../class/AppointmentInfo';
import { PractitionerService } from './practitioner';
import { ProviderService } from './provider';
import { ProviderLocationService } from './provider-location';

/**
 * Appointment info
 */
@Injectable({
  providedIn: 'root',
})
export class AppointmentService
{
    cache: BonesCache<number, AppointmentInfo, Appointment>;

    constructor(
        private rest: KpsRest,
        private mtus: UserService,
        private bcf: BonesCacheFactory,
        private providerDB: ProviderService,
        private locationDB: ProviderLocationService,
        private practitionerDB: PractitionerService
    )
    {
        this.cache = this.bcf.create<number, AppointmentInfo, Appointment>(
        {
            pk: 'appointmentID',
            loadCache: () => this.rest.send('provider/Appointment.php/getAppointments'),
            reloadOne: (id: number) => this.rest.send('provider/Appointment.php/getAppointment', { appointmentID: id }),
            converter: async (info: AppointmentInfo) : Promise<Appointment> =>
            {
                const appointment = new Appointment(info);

                appointment.patient = await this.mtus.getPatient(appointment.patientID);
                appointment.provider = await this.providerDB.getProvider(appointment.providerID);

                if (info.practitionerID)
                {
                    appointment.practitioner = await this.practitionerDB.getPractitioner(info.practitionerID);
                }

                if (info.locationID)
                {
                    appointment.location = await this.locationDB.getLocation(info.locationID);
                }

                return appointment;
            },
            sorter: (a: Appointment, b: Appointment) =>
            {
                if (a.isFuture && !b.isFuture)
                {
                    return -1;
                }
                else if (b.isFuture && !a.isFuture)
                {
                    return 1;
                }
                else if (a.isFuture)
                {
                    return a.scheduledDate.getTime() - b.scheduledDate.getTime();
                }
                else
                {
                    return b.scheduledDate.getTime() - a.scheduledDate.getTime();
                }
            }
        });
    }

    /**
     * Get single appointment
     */
    async getAppointment(appointmentID: number) : Promise<Appointment>
    {
        return this.cache.getEntry(appointmentID);
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter appointments
 */
export class AppointmentFilter
{
    constructor(public rows: Appointment[])
    {
    }

    byPatient(patientID: number) : AppointmentFilter
    {
        this.rows = this.rows.filter(r => r.patientID === patientID);
        return this;
    }

    byProvider(providerID: number) : AppointmentFilter
    {
        this.rows = this.rows.filter(r => r.providerID === providerID);
        return this;
    }

    byPractitioner(practitionerID: number) : AppointmentFilter
    {
        this.rows = this.rows.filter(r => r.practitionerID === practitionerID);
        return this;
    }

    upcoming() : AppointmentFilter
    {
        this.rows = this.rows.filter(r => r.isFuture);
        return this;
    }

    past() : AppointmentFilter
    {
        this.rows = this.rows.filter(r => !r.isFuture);
        return this;
    }

    relevant() : AppointmentFilter
    {
        const thePast = new Date();
        // thePast.setFullYear(thePast.getFullYear() - 2);
        thePast.setMonth(thePast.getMonth() - 15);
        const thePastTime = thePast.getTime();
        this.rows = this.rows.filter(r => r.scheduledDate.getTime() > thePastTime);
        return this;
    }

    recent(days: number) : AppointmentFilter
    {
        if (days)
        {
            const thePast = new Date();
            thePast.setDate(thePast.getDate() - days);
            const thePastTime = thePast.getTime();
            this.rows = this.rows.filter(r => r.scheduledDate.getTime() > thePastTime);
        }
        return this;
    }

    byKeyword(keyword: string) : AppointmentFilter
    {
        if (keyword)
        {
            const lckw = keyword.toLowerCase();

            this.rows = this.rows.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.purpose && r.purpose.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.location && r.location.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.provider && r.provider.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.practitioner && r.practitioner.name.toLowerCase().indexOf(lckw) >= 0);

                return match;
            });
        }

        return this;
    }
}
