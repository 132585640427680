import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { AddScriptDefaults } from '@med/provider/service/launch-edit-modal';
import { PrescriptionInfo } from '@med/provider/class/PrescriptionInfo';
import { PractitionerService } from '@med/provider/service/practitioner';
import { DrugService } from '@med/provider/service/drug';
import { PrescriptionService } from '@med/provider/service/drug-script';

@Component({
    selector: 'script-edit',
    templateUrl: 'script-edit.html'
})
export class ScriptEditPage extends BonesEditForm<PrescriptionInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private mtus: UserService,
        private scriptDB: PrescriptionService,
        private drugDB: DrugService,
        private practitionerDB: PractitionerService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'scriptID',
                    hidden: true
                },
                {
                    name: 'patientID',
                    title: 'Patient',
                    picker: this.mtus.getPatientPicker(),
                    validator: Validators.required
                },
                {
                    name: 'drugID',
                    title: 'Drug',
                    // validator: Validators.required,
                    picker: () => this.drugDB.cache.getPickerMap('name'),
                },
                {
                    name: 'productName',
                    title: 'Product Name',
                    validator: [ Validators.required, Validators.maxLength(64) ],
                    help: 'The name of the specific product by the supplier (e.g. the name CVS or Amazon calls this drug and dosage)'
                },
                {
                    name: 'practitionerID',
                    title: 'Practitioner',
                    picker: () => this.practitionerDB.cache.getPickerMap('name'),
                    // picker:
                    // {
                    //     populator: () => this.practitionerDB.getPractitionerPicker(this.bonesForm.getValue('providerID')),
                    //     nocache: true
                    // }
                },
                {
                    name: 'dosage',
                    title: 'Dosage',
                    validator: Validators.maxLength(32),
                    help: 'Current amount, e.g. 10mg'
                },
                {
                    name: 'frequency',
                    title: 'Frequency',
                    validator: Validators.maxLength(32),
                    help: 'How much and how often, e.g. 1ea after breakfast and 2ea at bedtime'
                },
                {
                    name: 'issued',
                    title: 'Issued',
                    date: 'yyyy-MM-dd',
                    initialValue: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
                    help: 'What date was this prescription issued (but not necessarily filled)?'
                },
                {
                    name: 'url',
                    title: 'URL',
                    validator: Validators.maxLength(256),
                    help: 'A link to the product on the website where this product can be ordered'
                },
                {
                    name: 'file',
                    title: 'Prescription',
                    type: 'file',
                    help: 'An image of the presciption or presciption document upload.'
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 4
                }
            ],
            uploadFormat: 'form',
            saveUrl: '/drug/Prescriptions.php/updatePrescription',
            cache: this.scriptDB.cache
        });

        // Supply defaults
        if (this.isAdd)
        {
            const defaults: AddScriptDefaults = this.launchOptions.moData.defaults;
            this.bonesForm.setValue('patientID', defaults.patientID);
            this.bonesForm.setValue('drugID', defaults.drugID);
            this.bonesForm.setValue('practitionerID', defaults.practitionerID);
            this.bonesForm.setValue('dosage', defaults.dosage);
            this.bonesForm.setValue('frequency', defaults.frequency);
            this.bonesForm.setValue('productName', defaults.productName);
        }
    }
}
