import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { UserService, AppUser } from '@med/core';
import { LaunchEditModalService, AddDrugUseDefaults } from '@med/provider/service/launch-edit-modal';
import { DrugUse } from '@med/provider/class/DrugUse';
import { Drug } from '@med/provider/class/Drug';
import { RefillService } from '@med/provider/service/drug-refill';
import { Refill } from '@med/provider/class/Refill';

@Component({
    selector: 'drug-use-list-card',
    templateUrl: 'drug-use-list-card.html'
})
export class DrugUseListCardComponent implements OnInit
{
    private _drug: Drug;
    @Input() set drug(drug: Drug)
    {
        this._drug = drug;
        this.onSetDrug();
    }
    get drug() : Drug
    {
        return this._drug;
    }
    @Input() usage: DrugUse[];
    @Input() title: string;
    @Input() subtitle: string;

    user: AppUser;
    lastRefill: Refill;

    // Dropdown menu
    actions: BonesMenuCardAction[] =
    [
        {
            title: 'Add new usage',
            icon: 'add-circle',
            action: () => this.add()
        }
    ];

    constructor(
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private refillDB: RefillService
    )
    {
        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {
    }

    async onSetDrug()
    {
        // console.log('onSetDrug', this.drug, this.user);

        // Default subtitle to the product name from the last refill
        // if (this.drug && this.user && !this.subtitle)
        // {
        //     this.lastRefill = await this.refillDB.getLastRefill(this.user.patient.patientID, this.drug.drugID);
        //     if (this.lastRefill)
        //     {
        //         this.subtitle = this.lastRefill.prescription.productName + ' (' + this.drug.type.name + ')';
        //     }
        //     else
        //     {
        //         this.subtitle = this.drug.type.name;
        //     }

        //     console.log('this.subtitle', this.subtitle);
        // }
    }

    add()
    {
        const addDefaults: AddDrugUseDefaults = { };

        if (this.drug)
        {
            addDefaults.drugID = this.drug.drugID;

        }

        if (this.drug && this.usage.length > 0)
        {
            const recentUsage = this.usage[0];

            addDefaults.patientID = recentUsage.patientID;
            addDefaults.dosage = recentUsage.dosage;
            addDefaults.frequency = recentUsage.frequency;
        }
        else
        {
            addDefaults.patientID = this.user.patient.patientID;
        }

        this.launch.addDrugUse(addDefaults);
    }

}
