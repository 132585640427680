import { Injectable } from '@angular/core';

/**
 * A collection of miscellaneous and sometimes useful utilities.
 */
@Injectable({
  providedIn: 'root'
})
export class BonesCoreService
{
    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * In-place copy source object into destination object replacing all properties
     *
     * Replacement for angular.copy
     * 
     * @param source Source object.
     * @param destination Destination object that will be emptied and the filled with source properties.
     */
    copyInPlace(source: any, destination: any)
    {
        // Clear destination object
        Object.keys(destination).forEach(key => { delete destination[key]; });

        // Copy populated properties
        Object.keys(source).forEach(key => { destination[key] = source[key]; });
    }

    //-----------------------------------------------------------------------

}
