import { NumberUsageInfo } from './NumberUsageInfo';
import { NumberGroupInfo } from './NumberGroupInfo';
import { NumberKeyInfo } from './NumberKeyInfo';
import { PatientInfo } from '@med/core';

export class NumberUsage implements NumberUsageInfo
{
    usageID: number;

    keyID: number;
    patientID: number;

    patient: PatientInfo;
    group: NumberGroupInfo;
    key: NumberKeyInfo;

    constructor(row: NumberUsageInfo)
    {
        Object.assign(this, row);
    }

    /**
     * Does this usage belong to a group of test results derived from a lab test?
     */
    get isLabResult() : boolean
    {
        return (!!this.group.isLabResult);
    }

}
