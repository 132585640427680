import { Component, OnInit, OnDestroy } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { AppUser, UserService } from '@med/core';

import { AppointmentService, AppointmentFilter } from '@med/provider/service/appointment';
import { Practitioner } from '@med/provider/class/Practitioner';
import { Provider } from '@med/provider/class/Provider';

@Component({
    selector: 'page-my-doctors',
    templateUrl: 'my-doctors.html'
})
export class MyDoctorsPage implements OnInit, OnDestroy
{
    user: AppUser;
    currentPractitioner: Practitioner[];
    pastPractitioner: Practitioner[];
    currentProvider: Provider[];
    pastProvider: Provider[];
    private nal: (() => void)[] = [ ];

    constructor(
        private es: BonesErrorService,
        private mtus: UserService,
        private appointmentDB: AppointmentService
    )
    {
        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {

        // Load and refresh appointments as needed
        this.nal.push(this.appointmentDB.cache.nowAndLater(
        rows =>
        {
            const thePast = new Date();
            thePast.setMonth(thePast.getMonth() - 15);
            const thePastTime = thePast.getTime();

            const currentPractitionerSet = new Set<Practitioner>();
            const pastPractitionerSet = new Set<Practitioner>();
            const currentProviderSet = new Set<Provider>();
            const pastProviderSet = new Set<Provider>();

            new AppointmentFilter(rows)
            .byPatient(this.user.patient.patientID)
            .rows
            .forEach(appointment =>
            {
                if (appointment.practitioner)
                {
                    // if (!set.has(appointment.practitioner))
                    if (appointment.scheduledDate.getTime() > thePastTime)
                    {
                        currentPractitionerSet.add(appointment.practitioner);
                    }
                    else
                    {
                        pastPractitionerSet.add(appointment.practitioner);
                    }
                }
                else if (appointment.provider)
                {
                    if (appointment.scheduledDate.getTime() > thePastTime)
                    {
                        currentProviderSet.add(appointment.provider);
                    }
                    else
                    {
                        pastProviderSet.add(appointment.provider);
                    }
                }
            });

            // Remove entries from past that are still current
            pastPractitionerSet.forEach(p => currentPractitionerSet.has(p) ? pastPractitionerSet.delete(p) : false);
            pastProviderSet.forEach(p => currentProviderSet.has(p) ? pastProviderSet.delete(p) : false);

            // Convert sets to sorted arrays
            this.currentPractitioner = Array.from(currentPractitionerSet).sort((a, b) => a.name.localeCompare(b.name));
            this.pastPractitioner = Array.from(pastPractitionerSet).sort((a, b) => a.name.localeCompare(b.name));
            this.currentProvider = Array.from(currentProviderSet).sort((a, b) => a.name.localeCompare(b.name));
            this.pastProvider = Array.from(pastProviderSet).sort((a, b) => a.name.localeCompare(b.name));
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

}
