import { Component, Input, OnInit } from '@angular/core';

import { Appointment } from '@med/provider/class/Appointment';

@Component({
    selector: 'med-appointment-list',
    templateUrl: 'med-appointment-list.html'
})
export class MedAppointmentListComponent implements OnInit
{
    @Input() appointments: Appointment[];
    @Input() title: string;
    now: number;

    constructor(
    )
    {
        this.now = new Date().getTime();
    }

    async ngOnInit()
    {
    }

    days(appointment: Appointment)
    {
        const diff = Math.round((appointment.scheduledDate.getTime() - this.now) / (1000 * 3600 * 24));
        return diff > 0 && diff < 10 ? ' (' + diff + ' days from now)'
            : diff < 0 && diff > -10 ? ' (' + -diff + ' days ago)'
            : diff === 0 ? ' (today)'
            : '';
    }
}
