import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest } from '@med/core';

import { ProviderService } from '@med/provider/service/provider';
import { ProviderLocationService } from '@med/provider/service/provider-location';
import { ProviderLocationInfo } from '@med/provider/class/ProviderLocationInfo';

@Component({
    selector: 'location-edit',
    templateUrl: 'location-edit.html'
})
export class LocationEditPage extends BonesEditForm<ProviderLocationInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private providerDB: ProviderService,
        private locationDB: ProviderLocationService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'locationID',
                    hidden: true
                },
                {
                    name: 'providerID',
                    title: 'Provider',
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    initialValue: this.launchOptions.moData?.providerID,
                    validator: Validators.required
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(64) ]
                },
                {
                    name: 'address',
                    title: 'Address',
                    validator: Validators.maxLength(64)
                },
                {
                    name: 'phone',
                    title: 'Phone',
                    validator: Validators.maxLength(12)
                },
                {
                    name: 'url',
                    title: 'URL',
                    validator: Validators.maxLength(128)
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 4
                }
            ],
            saveUrl: '/provider/Provider.php/updateProviderLocation',
            cache: this.locationDB.cache
        });
    }
}
