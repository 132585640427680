import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { AppUser, UserService } from '@med/core';
import { Provider } from '@med/provider/class/Provider';
import { Appointment } from '@med/provider/class/Appointment';
import { AppointmentService, AppointmentFilter } from '@med/provider/service/appointment';
import { ProviderService } from '@med/provider/service/provider';
import { PractitionerService } from '@med/provider/service/practitioner';
import { Practitioner } from '@med/provider/class/Practitioner';
import { LaunchEditModalService, AddAttachmentDefaults } from '@med/provider/service/launch-edit-modal';
import { NoteService, NoteFilter } from '@med/provider/service/note';
import { AttachmentService, AttachmentFilter } from '@med/provider/service/attachment';
import { Attachment } from '@med/provider/class/Attachment';
import { AttachmentTypeService } from '@med/provider/service/attachmentType';
import { ProviderLocationService } from '@med/provider/service/provider-location';
import { ProviderLocationInfo } from '@med/provider/class/ProviderLocationInfo';
import { Note } from '@med/provider/class/Note';

@Component({
    selector: 'page-provider-detail',
    templateUrl: 'provider-detail.html'
})
export class ProviderDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    providerID: number;
    provider: Provider;
    practitioners: Practitioner[];
    locations: ProviderLocationInfo[];
    upcomingAppointments: Appointment[];
    pastAppointments: Appointment[];
    private nal: (() => void)[] = [ ];
    notes: Note[];
    attachments: Attachment[];
    recentTestResults: Attachment[];
    oldTestResults: Attachment[];
    testResultAddDefaults: AddAttachmentDefaults;
    providerMenu: BonesMenuCardAction[];
    locationMenu: BonesMenuCardAction[];
    practitionersMenu: BonesMenuCardAction[];
    appointmentsMenu: BonesMenuCardAction[];

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private es: BonesErrorService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private providerDB: ProviderService,
        private locationDB: ProviderLocationService,
        private practitionerDB: PractitionerService,
        private appointmentDB: AppointmentService,
        private noteDB: NoteService,
        private attachmentDB: AttachmentService,
        private attachmentTypeDB: AttachmentTypeService
    )
    {
        // Access providerID passed in URL
        this.providerID = +this.route.snapshot.params.providerID;
        this.user = this.mtus.getUser();

        // Card menus
        this.providerMenu =
        [
            this.launch.getMenuItemEdit(() => this.edit())
        ];

        this.locationMenu =
        [
            this.launch.getMenuItemAdd(() => this.launch.addLocation(this.providerID))
        ];

        this.practitionersMenu =
        [
            this.launch.getMenuItemAdd(() => this.launch.addPractitioner({ providerID: this.providerID }))
        ];

        this.appointmentsMenu =
        [
            this.launch.getMenuItemAdd(() => this.launch.addAppointment({ providerID: this.providerID })),
            // this.launch.getMenuItemViewAllProviderAppointments(this.providerID)
        ];
    }

    async ngOnInit()
    {
        // Load provider details
        this.providerDB.getProvider(this.providerID)
        .then(async row => this.provider = row)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh locations as needed
        this.nal.push(this.locationDB.cache.nowAndLater(
        rows => this.locations = rows.filter(p => p.providerID === this.providerID),
        error => this.es.errorHandler(error)));

        // Load and refresh practitioners as needed
        this.nal.push(this.practitionerDB.cache.nowAndLater(
        rows => this.practitioners = rows.filter(p => p.providerID === this.providerID),
        error => this.es.errorHandler(error)));

        // Load and refresh appointments as needed
        this.nal.push(this.appointmentDB.cache.nowAndLater(
        rows =>
        {
            const allAappointments = new AppointmentFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byProvider(this.providerID)
            .rows;

            this.upcomingAppointments = new AppointmentFilter(allAappointments)
            .upcoming()
            .rows;

            this.pastAppointments = new AppointmentFilter(allAappointments)
            .past()
            .rows;
        },
        error => this.es.errorHandler(error)));

        // Load and refresh attachments as needed
        this.nal.push(this.attachmentDB.cache.nowAndLater(
        rows =>
        {
            const allAttachments = new AttachmentFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byProvider(this.providerID)
            .rows;

            this.attachments = new AttachmentFilter(allAttachments)
            .notTestResults()
            .rows;

            const allTestResults = new AttachmentFilter(allAttachments)
            .testResults()
            .rows;

            this.recentTestResults = new AttachmentFilter(allTestResults)
            .recent()
            .rows;

            this.oldTestResults = new AttachmentFilter(allTestResults)
            .old()
            .rows;
        },
        error => this.es.errorHandler(error)));

        // Defaults for adding test result
        const trt = await this.attachmentTypeDB.getTestResultsType();
        this.testResultAddDefaults =
        {
            patientID: this.user.patient.patientID,
            typeID: trt.typeID,
            providerID: this.providerID
        };

        // Load and refresh notes as needed
        this.nal.push(this.noteDB.cache.nowAndLater(
        rows =>
        {
            this.notes = new NoteFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byProvider(this.providerID)
            .rows;
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    async edit()
    {
        const results = await this.launch.editProvider(this.provider);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }
}
