import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

import { BonesFormItemPicker } from '../../class/BonesFormItemPicker';
import { BonesFormItemPickerChoice } from '../../class/BonesFormItemPickerChoice';
import { BonesFormItemPickerPageOptions } from '../../class/BonesFormItemPickerPageOptions';

/**
 * Modal to pick from a list of values
 */
@Component({
    templateUrl: 'form-item-picker-modal.html',
    styleUrls: [ 'form-item-picker-modal.scss' ]
})
export class BonesFormItemPickerPage
{
    /**
     * Search term for search bar.
     */
    searchTerm: string;

    /**
     * Options passed to picker page.
     */
    options: BonesFormItemPickerPageOptions;

    /**
     * Values filered by search bar.
     */
    filteredValues: BonesFormItemPickerChoice[];

    /**
     * Current item value from original form.
     */
    currentItem: BonesFormItemPickerChoice[] = [ ];

    /**
     * The picker being used.
     */
    picker: BonesFormItemPicker;

    /**
     * Are picker values currently being loaded from an external source?
     */
    loading = true;

    /**
     * Has the list of values been truncated to the maximum?
     */
    truncatedValues: boolean;

    private allValues: BonesFormItemPickerChoice[];

    /**
     * @ignore
     */
    constructor(
        private navParams: NavParams,
        private modal: ModalController,
    )
    {
        // Get picker options
        this.options = this.navParams.get('options');
        this.picker = this.options.item.picker;

        // Initialize values
        this.initValues();
    }

    private async initValues()
    {
        const values = await this.picker.getValues();
        this.loading = false;

        // Build PickerValue objects for the input values
        if (values instanceof Map)
        {
            this.allValues = [ ];
            values.forEach((v, k) => this.allValues.push({ key: k, value: v }));
        }
        else if (values instanceof Array)
        {
            this.allValues = values.map(v => ({ key: v, value: String(v) }));
        }
        else
        {
            console.warn('BonesFormItemPickerPage.initValues: picker does not have any selectable values');
            this.allValues = [ ];
        }

        // Assign currently selected item to be the current value in the form
        const formValue = this.options.form.value[this.options.item.name];
        if (formValue instanceof Array)
        {
            // Select all current items for multi select
            formValue.forEach(fv => this.add2CurrentItem(fv));
        }
        else
        {
            // Single select has just the one current value (if any)
            this.add2CurrentItem(formValue);
        }

        // Use search to initially assign filtered items
        this.search();
    }

    private add2CurrentItem(formValue: string)
    {
        const ci = this.allValues.find(v => v.key === formValue);
        if (ci)
        {
            this.currentItem.push(ci);
        }
    }

    /**
     * Filter items with search term
     */
    search()
    {
        if (this.searchTerm)
        {
            const searchTerm = this.searchTerm.toLowerCase();

            this.filteredValues = new Array<BonesFormItemPickerChoice>();
            this.allValues.forEach(choice =>
            {
                // Keyword search
                if (this.formatItem(choice).toLowerCase().indexOf(searchTerm) === -1)
                {
                    return;
                }

                this.filteredValues.push(choice);
            });
        }
        else
        {
            this.filteredValues = this.allValues;
        }

        // Truncate long lists
        this.truncatedValues = this.filteredValues.length > this.picker.maxValuesToDisplay;
        if (this.truncatedValues)
        {
            this.filteredValues = this.filteredValues.slice(0, this.picker.maxValuesToDisplay);
        }
    }

    /**
     * Format item for display in the list
     */
    formatItem(choice: BonesFormItemPickerChoice) : string
    {
        if (this.picker.formatItem)
        {
            return this.options.item.picker.formatItem(choice);
        }
        else
        {
            return choice.value;
        }
    }

    /**
     * Pick item
     */
    pick(choice: BonesFormItemPickerChoice)
    {
        // Clicking a selected item will unselect it
        if (this.chosen(choice))
        {
            this.currentItem.splice(this.currentItem.indexOf(choice), 1);
        }
        else
        {
            // Zero out current selection for single selects
            if (!this.picker.multi)
            {
                this.currentItem.length = 0;
            }

            // Add selected item to current selections
            this.currentItem.push(choice);
        }

        if (!this.picker.multi && this.currentItem.length > 0)
        {
            this.done();
        }
    }

    /**
     * Selection complete, return value(s) and dismiss modal
     */
    done()
    {
        // Send back list of values for multi selects
        if (this.picker.multi)
        {
            this.modal.dismiss(this.currentItem.map(ci => ci.key));
        }
        // Send back null if no items were selected
        else if (this.currentItem.length === 0)
        {
            this.modal.dismiss(null);
        }
        // Send back first (should be only) item for single select
        else
        {
            this.modal.dismiss(this.currentItem[0].key);
        }
    }

    /**
     * Has this choice been chosen?
     * @param choice the choice to evaluate
     */
    chosen(choice: BonesFormItemPickerChoice) : boolean
    {
        return this.currentItem.indexOf(choice) >= 0;
    }

    /**
     * Clear current selection
     */
    clear()
    {
        this.currentItem.length = 0;
        if (!this.picker.multi)
        {
            this.done();
        }
    }

    /**
     * Cancel lookup screen
     */
    cancel()
    {
        this.modal.dismiss();
    }

}
