import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest } from '@med/core';

import { PractitionerService } from '@med/provider/service/practitioner';
import { ProviderService } from '@med/provider/service/provider';
import { PractitionerInfo } from '@med/provider/class/PractitionerInfo';

@Component({
    selector: 'practitioner-edit',
    templateUrl: 'practitioner-edit.html'
})
export class PractitionerEditPage extends BonesEditForm<PractitionerInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private providerDB: ProviderService,
        private practitionerDB: PractitionerService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'practitionerID',
                    hidden: true
                },
                {
                    name: 'providerID',
                    title: 'Provider',
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    validator: Validators.required
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(64) ]
                },
                {
                    name: 'speciality',
                    title: 'Speciality',
                    validator: Validators.maxLength(64)
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 10
                }
            ],
            saveUrl: '/provider/Practitioner.php/updatePractitioner',
            cache: this.practitionerDB.cache
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue('providerID', this.launchOptions.moData.providerID);
        }
    }
}
