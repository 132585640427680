import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'med-page-footer',
    templateUrl: 'med-page-footer.html',
})
export class MedPageFooterComponent implements OnInit
{
    constructor(
    )
    {
    }

    async ngOnInit()
    {
    }

}
