import { Component } from '@angular/core';

import { UserService } from '@med/core/service/UserService';
import { AppUser } from '../../class/AppUser';

@Component({
  templateUrl: 'test-tools-user.html'
})
export class TestToolsUserPage
{
    user: AppUser;

    constructor(
        private mtus: UserService
    )
    {
        this.user = this.mtus.getUser();
    }

}
