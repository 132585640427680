import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BonesErrorService } from '@bones/core';

import { UserService, AppUser, PatientInfo } from '@med/core';
import { AppointmentService, Appointment, AppointmentFilter } from '@med/provider';
import { RefillService, RefillFilter } from '@med/provider/service/drug-refill';
import { Refill } from '@med/provider/class/Refill';
import { DrugUseService, DrugUseFilter } from '@med/provider/service/drug-use';
import { DrugUse } from '@med/provider/class/DrugUse';
import { AddDrugRefillDefaults, LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { NumberValueUpdate } from '@med/provider/class/NumberValueUpdate';
import { Practitioner } from '@med/provider/class/Practitioner';
import { Provider } from '@med/provider/class/Provider';
import { NoteService, NoteFilter } from '@med/provider/service/note';
import { AttachmentService, AttachmentFilter } from '@med/provider/service/attachment';
import { PrescriptionService, PrescriptionFilter } from '@med/provider/service/drug-script';
import { VaccinationService, VaccinationFilter } from '@med/provider/service/drug-vaccination';
import { Attachment } from '@med/provider/class/Attachment';
import { Prescription } from '@med/provider/class/Prescription';
import { Vaccination } from '@med/provider/class/Vaccination';
import { Note } from '@med/provider/class/Note';

interface ExpiringRefill
{
    daysSince: number;
    daysRemaining: number;
    refill: Refill;
}
class RefillByStore
{
    // store: string;
    // refills: ExpiringRefill[];

    constructor(public store: string, public refills: ExpiringRefill[])
    {}
}

@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html'
})
export class HomePage implements OnInit, OnDestroy
{
    user: AppUser;
    nal: (() => void)[] = [ ];
    recentDays = 30;

    private allAppointments: Appointment[];
    appointments: Appointment[];
    private allRefills: Refill[];
    refillsByStore: RefillByStore[];
    private allUsage: DrugUse[];
    myDoctors: (Provider | Practitioner)[];

    allNotes: Note[];
    allAttachments: Attachment[];
    allScripts: Prescription[];
    allVaccinations: Vaccination[];

    recentNotes: Note[];
    recentAttachments: Attachment[];
    recentTestResults: Attachment[];
    recentScripts: Prescription[];
    recentVaccinations: Vaccination[];

    recentAppointments: Appointment[];
    recentRefills: Refill[];
    recentUsage: DrugUse[];

    constructor(
        private router: Router,
        private es: BonesErrorService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private appdb: AppointmentService,
        private usedb: DrugUseService,

        private notedb: NoteService,
        private attachmentdb: AttachmentService,
        private scriptdb: PrescriptionService,
        private vaccinationDB: VaccinationService,

        private refilldb: RefillService
    )
    {
    }

    //-----------------------------------------------------------------------

    async ngOnInit()
    {
        // Get information about the user
        this.user = this.mtus.getUser();
        this.selectPatient(this.user.patient);

        // Subscribe to appointment cache
        this.nal.push(this.appdb.cache.nowAndLater(
        rows =>
        {
            this.allAppointments = rows;
            this.filterAppointments();
            this.filterRecent();
        },
        error => this.es.errorHandler(error)));

        // Subscribe to refill cache
        this.nal.push(this.refilldb.cache.nowAndLater(
        rows =>
        {
            this.allRefills = rows;
            this.filterRefills();
            this.filterRecent();
        },
        error => this.es.errorHandler(error)));

        // Subscribe to usage cache
        this.nal.push(this.usedb.cache.nowAndLater(
        rows =>
        {
            this.allUsage = rows;
            this.filterRefills();
            this.filterRecent();
        },
        error => this.es.errorHandler(error)));

        // Subscribe to note cache
        this.nal.push(this.notedb.cache.nowAndLater(
        rows =>
        {
            this.allNotes = rows;
            this.filterRecent();
        },
        error => this.es.errorHandler(error)));

        // Subscribe to attachment cache
        this.nal.push(this.attachmentdb.cache.nowAndLater(
        rows =>
        {
            this.allAttachments = rows;
            this.filterRecent();
        },
        error => this.es.errorHandler(error)));

        // Subscribe to script cache
        this.nal.push(this.scriptdb.cache.nowAndLater(
        rows =>
        {
            this.allScripts = rows;
            this.filterRecent();
        },
        error => this.es.errorHandler(error)));

        // Subscribe to vaccination cache
        this.nal.push(this.vaccinationDB.cache.nowAndLater(
        rows =>
        {
            this.allVaccinations = rows;
            this.filterRecent();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(nal => nal());
    }

    //-----------------------------------------------------------------------

    selectPatient(patient: PatientInfo)
    {
        this.user.patient = patient;

        this.filterAppointments();
        this.filterRefills();
        this.filterRecent();
    }

    //-----------------------------------------------------------------------

    private filterAppointments()
    {
        if (this.allAppointments)
        {
            // Upcoming Appointments
            this.appointments = new AppointmentFilter(this.allAppointments)
            .byPatient(this.user.patient.patientID)
            .upcoming()
            .rows;

            // Get list of current doctors based upon recent appointments
            const drset = new Set<Provider | Practitioner>();

            new AppointmentFilter(this.allAppointments)
            .byPatient(this.user.patient.patientID)
            .relevant()
            .rows
            .forEach(appointment =>
            {
                drset.add(appointment.practitioner ? appointment.practitioner : appointment.provider);
            });

            this.myDoctors = Array.from(drset).sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    isPractitioner(dr: Provider | Practitioner) : boolean
    {
        return dr instanceof Practitioner;
    }

    //-----------------------------------------------------------------------

    private filterRefills()
    {
        if (this.allRefills && this.allUsage)
        {
            // Find the last refill for each drug by keeping only the first match in a date descending sorted list
            const lastRefillByDrug = new Map<number, Refill>();
            this.allRefills
            .filter(refill => refill.patient.patientID === this.user.patient.patientID)
            .forEach(refill =>
            {
                if (!lastRefillByDrug.has(refill.drug.drugID))
                {
                    lastRefillByDrug.set(refill.drug.drugID, refill);
                }
            });

            // Remove drugs no longer used
            lastRefillByDrug.forEach(refill =>
            {
                // Find most recent usage record for this patient and drug
                const lastUsage = this.allUsage.find(usage => usage.patientID === this.user.patient.patientID
                    && usage.drugID === refill.drug.drugID);

                // The latest usage record shows this drug has been stopped
                if (lastUsage && lastUsage.stopped)
                {
                    lastRefillByDrug.delete(lastUsage.drugID);
                }
            });

            // Check the days remaining for each of the refills
            const expiringRefills = [ ];
            const today = new Date().getTime() / 1000;
            lastRefillByDrug.forEach(refill =>
            {
                // We have to know when the last refill was filled and how many days supply it contained
                if (refill.filledDate && refill.days)
                {
                    const daysSince = Math.round((today - refill.filledDate.getTime() / 1000) / 60 / 60 / 24);
                    const daysRemaining = refill.days - daysSince;
                    if (daysRemaining < 14)
                    {
                        expiringRefills.push({ daysSince, daysRemaining, refill });
                    }
                }
            });

            // Split refills by store used for most recent refill
            const map = new Map<string, RefillByStore>();
            expiringRefills.forEach(refill =>
            {
                const store = refill.refill.store.name;
                if (map.has(store))
                {
                    map.get(store).refills.push(refill);
                }
                else
                {
                    map.set(store, new RefillByStore(store, [ refill ]));
                }
            });

            // Extract refill data from map and sort by store
            this.refillsByStore = Array.from(map.values()).sort((a, b) => a.store.localeCompare(b.store));
        }
    }

    //-----------------------------------------------------------------------

    async refill(row: ExpiringRefill)
    {
        const defaults: AddDrugRefillDefaults =
        {
            scriptID: row.refill.scriptID,
        };

        defaults.drugstoreID = row.refill.drugstoreID;
        defaults.days = row.refill.days;
        defaults.quantity = row.refill.quantity;
        defaults.cost = row.refill.cost;
        defaults.refills = row.refill.refills;

        this.launch.addDrugRefill(row.refill.prescription, defaults);
    }

    //-----------------------------------------------------------------------

    enterNumbers()
    {
        this.launch.editManualNumberValuesThenRoute();
    }

    //-----------------------------------------------------------------------

    filterRecent()
    {
        if (this.allAppointments)
        {
            this.recentAppointments = new AppointmentFilter(this.allAppointments)
            .byPatient(this.user.patient.patientID)
            .past()
            .recent(this.recentDays)
            .rows;
        }

        if (this.allUsage)
        {
            this.recentUsage = new DrugUseFilter(this.allUsage)
            .byPatient(this.user.patient.patientID)
            .recent(this.recentDays)
            .rows;
        }

        if (this.allScripts)
        {
            this.recentScripts = new PrescriptionFilter(this.allScripts)
            .byPatient(this.user.patient.patientID)
            .recent(this.recentDays)
            .rows;
        }

        if (this.allRefills)
        {
            this.recentRefills = new RefillFilter(this.allRefills)
            .byPatient(this.user.patient.patientID)
            .recent(this.recentDays)
            .rows;
        }

        if (this.allAttachments)
        {
            this.recentAttachments = new AttachmentFilter(this.allAttachments)
            .byPatient(this.user.patient.patientID)
            .notTestResults()
            .recent(this.recentDays)
            .rows;

            this.recentTestResults = new AttachmentFilter(this.allAttachments)
            .byPatient(this.user.patient.patientID)
            .testResults()
            .recent(this.recentDays)
            .rows;
        }

        if (this.allNotes)
        {
            this.recentNotes = new NoteFilter(this.allNotes)
            .byPatient(this.user.patient.patientID)
            .recent(this.recentDays)
            .rows;
        }

        if (this.allVaccinations)
        {
            this.recentVaccinations = new VaccinationFilter(this.allVaccinations)
            .byPatient(this.user.patient.patientID)
            .recent(this.recentDays)
            .rows;
        }
    }

    //-----------------------------------------------------------------------

}
