import { Component } from '@angular/core';

@Component({
    selector: 'page-not-authorized',
    templateUrl: 'not-authorized.html'
})
export class NotAuthorizedPage
{
    constructor(
    )
    {
    }

}
