import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest } from '@med/core';

import { NumberGroupInfo } from '../class/NumberGroupInfo';

/**
 * numbers - groups
 */
@Injectable({
  providedIn: 'root',
})
export class NumberGroupService
{
    cache: BonesCache<number, NumberGroupInfo, NumberGroupInfo>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory
    )
    {
        this.cache = this.bcf.create<number, NumberGroupInfo, NumberGroupInfo>(
        {
            pk: 'groupID',
            loadCache: () => this.rest.send('numbers/Group.php/getGroups'),
            reloadOne: (id: number) => this.rest.send('numbers/Group.php/getGroup', { groupID: id }),
            converter: async r => r,
            sorter: (a: NumberGroupInfo, b: NumberGroupInfo) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single row
     */
    async getGroup(groupID: number) : Promise<NumberGroupInfo>
    {
        return this.cache.getEntry(groupID);
    }

    //-----------------------------------------------------------------------

}
