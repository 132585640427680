import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { Attachment } from '@med/provider/class/Attachment';
import { LaunchEditModalService, AddAttachmentDefaults } from '@med/provider/service/launch-edit-modal';

type LayoutType = 'attachment' | 'tr';

@Component({
    selector: 'attachment-list-card',
    templateUrl: 'attachment-list-card.html'
})
export class AttachmentListCardComponent implements OnInit
{
    @Input() attachments: Attachment[];
    @Input() title = 'Attachments';
    @Input() layout: LayoutType = 'attachment';
    @Input() addDefaults: AddAttachmentDefaults = { };

    // Dropdown menu
    actions: BonesMenuCardAction[] =
    [
        {
            title: 'Add',
            icon: 'add-circle',
            action: () => this.launch.addAttachment(this.addDefaults)
        }
    ];

    constructor(
        public launch: LaunchEditModalService
    )
    {
    }

    async ngOnInit()
    {
    }

    menu()
    {

    }

}
