import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Validators, UntypedFormControl } from '@angular/forms';

import { BonesErrorService } from '@bones/core';
import { UserService, AppUser } from '@med/core';

import { NumberUsageService } from '@med/provider/service/number-usage';
import { NumberValueService } from '@med/provider/service/number-value';
import { GroupedUsage } from '@med/provider/class/GroupedUsage';
import { NumberValueUpdate } from '@med/provider/class/NumberValueUpdate';
import { formatDate } from '@angular/common';
import { NumberValueInfo } from '@med/provider/class/NumberValueInfo';

@Component({
    selector: 'numbers-edit',
    templateUrl: 'numbers-edit.html'
})
export class NumbersEditPage implements OnInit
{
    @Input() attachmentID: number;
    @Input() patientID: number;
    @Input() dt: string;
    @Input() row: NumberValueInfo;
    user: AppUser;
    labResultsWanted: boolean;
    groupedUsage: GroupedUsage[];
    testValueMap: Map<number, number>;
    controls: Map<number, UntypedFormControl>;
    dateValue: string;
    timeValue: string;

    constructor(
        protected modalCtrl: ModalController,
        private es: BonesErrorService,
        private mtus: UserService,
        private usedb: NumberUsageService,
        private valuedb: NumberValueService
    )
    {
        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {
        this.labResultsWanted = !!this.attachmentID;

        // Load usage and values to create form
        try
        {
            // Get groups
            this.groupedUsage = (await this.usedb.getFilteredUsage())
            .byPatient(this.user.patient.patientID)
            .byLabResults(this.labResultsWanted)
            .group();

            // Get values
            if (this.labResultsWanted)
            {
                await this.valuedb.getValueMapForAttachment(this.attachmentID)
                .then(map => this.testValueMap = map)
                .catch(error => this.es.errorHandler(error));
            }
            else if (this.row)
            {
                await this.valuedb.getValueMapForDate(this.row.dt)
                .then(map => this.testValueMap = map)
                .catch(error => this.es.errorHandler(error));

                [ this.dateValue, this.timeValue ] = this.row.dt.split(' ');
            }
            else
            {
                this.dateValue = formatDate(new Date(), 'y-MM-dd', 'en');
                this.timeValue = formatDate(new Date(), 'HH:mm', 'en');
                this.testValueMap = new Map<number, number>();
            }

            // Create a map for form controls keyed on usage.keyID
            this.controls = new Map<number, UntypedFormControl>();

            // Create and populate form controls
            this.groupedUsage.forEach(g => g.usage.forEach(usage =>
            {
                const control = new UntypedFormControl();
                control.setValidators(Validators.pattern('[0-9\.]*'));
                control.setValue(this.testValueMap.get(usage.keyID));
                this.controls.set(usage.keyID, control);
            }));
        }
        catch (error)
        {
            this.es.errorHandler(error);
        }
    }

    cancel()
    {
        this.modalCtrl.dismiss();
    }

    save()
    {
        const packet: NumberValueUpdate =
        {
            dt: this.dt,
            patientID: this.patientID,
            values: { }
        };

        // Populate packet with form values
        this.controls.forEach((formControl: UntypedFormControl, keyID: number) => packet.values[keyID] = formControl.value);

        // Save changes to back end
        let p1: Promise<void>;
        if (this.labResultsWanted)
        {
            packet.attachmentID = this.attachmentID;
            p1 = this.valuedb.updateValuesForAttachment(packet);
        }
        else
        {
            packet.dt = this.dateValue + ' ' + this.timeValue;
            p1 = this.valuedb.updateManualValues(packet);
        }

        // Check back end status
        p1.then(() =>
        {
            this.modalCtrl.dismiss(packet);
        })
        .catch(error => this.es.errorHandler(error));
    }

}
