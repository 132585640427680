import { AppUserInfo } from './AppUserInfo';
import { PatientInfo } from './PatientInfo';

export class AppUser
{
    /**
     * Has user been authenticated
     */
    authenticated = false;

    /**
     * User info
     */
    info: AppUserInfo;

    /**
     * Current patient
     */
    patient: PatientInfo;

    /**
     * All accessible patients
     */
    patients: PatientInfo[];

    constructor()
    {
    }

    /**
     * Does user have access to a task?
     */
    hasAccess(task: string) : boolean
    {
        return (this.info && this.info.tasks && this.info.tasks.indexOf(task) >= 0);
    }
}
