import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { KpsRest } from '@med/core';

import { Drugstore } from '../class/Drugstore';

/**
 * Access db
 */
@Injectable()
export class DrugstoreService
{

    constructor(
        private rest: KpsRest,
    )
    {
    }

    async getDrugstores() : Promise<Drugstore[]>
    {
        return this.rest.send('admin/Drugstore.php/getDrugstores')
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'DrugstoreService',
                methodName: 'getDrugstores',
                message: 'Unable to get drugstores',
                error: error
            });
        });
    }

    async getDrugstore(drugStoreID: number) : Promise<Drugstore>
    {
        return this.rest.send('admin/Drugstore.php/getDrugstore', { drugStoreID })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'DrugstoreService',
                methodName: 'getDrugstore',
                message: 'Unable to get drugstore',
                error: error
            })
            .add({ drugStoreID });
        });
    }

    //-----------------------------------------------------------------------

}
