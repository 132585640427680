import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { VaccinationService } from '@med/provider/service/drug-vaccination';
import { DrugService } from '@med/provider/service/drug';
import { ProviderService } from '@med/provider/service/provider';
import { ProviderLocationService } from '@med/provider/service/provider-location';
import { VaccinationInfo } from '@med/provider/class/VaccinationInfo';

@Component({
    selector: 'vaccination-edit',
    templateUrl: 'vaccination-edit.html'
})
export class VaccinationEditPage extends BonesEditForm<VaccinationInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private mtus: UserService,
        private vaccinationDB: VaccinationService,
        private drugDB: DrugService,
        private providerDB: ProviderService,
        private locationDB: ProviderLocationService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'vaccinationID',
                    hidden: true
                },
                {
                    name: 'patientID',
                    title: 'Patient',
                    picker: this.mtus.getPatientPicker(),
                    validator: Validators.required
                },
                {
                    name: 'drugID',
                    title: 'Drug',
                    picker: () => this.drugDB.cache.getPickerMap('name', (d) => d.isVaccination),
                    validator: Validators.required,
                },
                {
                    name: 'providerID',
                    title: 'Provider',
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.bonesForm.setValue('locationID', undefined);
                    },
                    validator: Validators.required
                },
                {
                    name: 'locationID',
                    title: 'Location',
                    picker:
                    {
                        populator: () => this.locationDB.getLocationPicker(this.bonesForm.getValue('providerID')),
                        nocache: true
                    }
                },
                {
                    name: 'vaccinated',
                    title: 'Vaccinated',
                    date: 'yyyy-MM-dd',
                    validator: Validators.required
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 4
                }
            ],
            saveUrl: '/drug/Vaccination.php/updateVaccination',
            cache: this.vaccinationDB.cache
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue('patientID', this.launchOptions.moData.defaults.patientID);
            this.bonesForm.setValue('drugID', this.launchOptions.moData.defaults.drugID);
            this.bonesForm.setValue('providerID', this.launchOptions.moData.defaults.providerID);
            this.bonesForm.setValue('locationID', this.launchOptions.moData.defaults.locationID);
        }
    }
}
