import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest } from '@med/core';

import { DrugTypeInfo } from '../class/DrugTypeInfo';

/**
 * Drug type info
 */
@Injectable({
  providedIn: 'root',
})
export class DrugTypeService
{
    cache: BonesCache<number, DrugTypeInfo, DrugTypeInfo>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory
    )
    {
        this.cache = this.bcf.create<number, DrugTypeInfo, DrugTypeInfo>(
        {
            pk: 'drugTypeID',
            loadCache: () => this.rest.send('drug/DrugType.php/getDrugTypes'),
            reloadOne: (id: number) => this.rest.send('drug/DrugType.php/getDrugType', { typeID: id }),
            converter: async r => r,
            sorter: (a: DrugTypeInfo, b: DrugTypeInfo) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single row
     */
    async getDrugTypeInfo(typeID: number) : Promise<DrugTypeInfo>
    {
        return this.cache.getEntry(typeID);
    }

    //-----------------------------------------------------------------------

}
