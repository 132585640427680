import { Component, Input, OnInit } from '@angular/core';

// import { BonesMenuCardAction } from '@bones/core';

import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { Note } from '@med/provider/class/Note';

@Component({
    selector: 'note-cards',
    templateUrl: 'note-cards.html'
})
export class NoteCardsComponent implements OnInit
{
    @Input() notes: Note[];
    // @Input() layout: 'note' | 'tr' = 'note';

    constructor(
        public launch: LaunchEditModalService
    )
    {
    }

    async ngOnInit()
    {
    }

    // subtitle(note: Note)
    // {
    //     if (note.provider && note.practitioner)
    //     {
    //         return note.practitioner.name + ', ' + note.provider.name;
    //     }
    //     else if (note.practitioner)
    //     {
    //         return note.practitioner.name;
    //     }
    //     else if (note.provider)
    //     {
    //         return note.provider.name;
    //     }
    //     else
    //     {
    //         return undefined;
    //     }
    // }

}
