import { PatientInfo } from '@med/core';
import { Drug } from './Drug';
import { Practitioner } from './Practitioner';
import { RefillInfo } from './RefillInfo';
import { Prescription } from './Prescription';
import { DrugStoreInfo } from './DrugStoreInfo';

export class Refill implements RefillInfo
{
    refillID: number;

    scriptID: number;
    drugstoreID: number;
    filled: string;
    quantity: string;
    days: number;
    cost: number;
    refills: number;
    notes: string;

    contentType: string;

    filledDate: Date;

    prescription: Prescription;
    drug: Drug;
    store: DrugStoreInfo;
    patient: PatientInfo;
    practitioner: Practitioner;

    constructor(row: RefillInfo)
    {
        Object.assign(this, row);

        this.filledDate = new Date(this.filled);
    }

}
