import { Component, OnInit, OnDestroy } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { Provider } from '@med/provider/class/Provider';
import { ProviderService } from '@med/provider/service/provider';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';

@Component({
    selector: 'page-provider-list',
    templateUrl: 'provider-list.html'
})
export class ProviderListPage implements OnInit, OnDestroy
{
    public providers: Provider[];
    private nal1: () => void;

    constructor(
        private es: BonesErrorService,
        private launch: LaunchEditModalService,
        private providerDB: ProviderService
    )
    {
    }

    ngOnInit()
    {
        // Load and refresh providers as needed
        this.nal1 = this.providerDB.cache.nowAndLater(
        rows => this.providers = rows,
        error => this.es.errorHandler(error));
    }

    ngOnDestroy()
    {
        this.nal1();
    }

    add()
    {
        this.launch.addProvider();
    }

}
