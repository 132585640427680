import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { AttachmentService } from '@med/provider/service/attachment';
import { PractitionerService } from '@med/provider/service/practitioner';
import { ProviderService } from '@med/provider/service/provider';
import { AttachmentInfo } from '@med/provider/class/AttachmentInfo';
import { AttachmentTypeService } from '@med/provider/service/attachmentType';
import { PrescriptionService } from '@med/provider/service/drug-script';
import { DrugService } from '@med/provider/service/drug';

@Component({
    selector: 'attachment-edit',
    templateUrl: 'attachment-edit.html'
})
export class AttachmentEditPage extends BonesEditForm<AttachmentInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private mtus: UserService,
        private attachmentDB: AttachmentService,
        private attachmentTypeDB: AttachmentTypeService,
        private providerDB: ProviderService,
        private practitionerDB: PractitionerService,
        private drugDB: DrugService,
        private scriptDB: PrescriptionService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'attachmentID',
                    hidden: true
                },
                {
                    name: 'patientID',
                    title: 'Patient',
                    picker: this.mtus.getPatientPicker(),
                    validator: Validators.required
                },
                {
                    name: 'typeID',
                    title: 'Type',
                    picker: () => this.attachmentTypeDB.cache.getPickerMap('name'),
                    validator: Validators.required,
                    // onChange: async (value) =>
                    // {
                    //     // Is this attachment a test result?
                    //     const isTestResult = value ?
                    //         (await this.attachmentTypeDB.cache.getEntry(+value)).mnemonic === 'tr'
                    //         : false;

                    //     // testProviderID is only set when attachment type is test results
                    //     const testProviderID = this.bonesForm.getItem('testProviderID');
                    //     testProviderID.hidden = !isTestResult;

                    //     // Clear value when hidden
                    //     if (testProviderID.hidden)
                    //     {
                    //         testProviderID.setValue(undefined);
                    //     }
                    // }
                },
                {
                    name: 'providerID',
                    title: 'Provider',
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.bonesForm.setValue('practitionerID', undefined);
                    }
                },
                {
                    name: 'practitionerID',
                    title: 'Practitioner',
                    picker:
                    {
                        populator: () => this.practitionerDB.getPractitionerPicker(this.bonesForm.getValue('providerID')),
                        nocache: true
                    }
                },
                {
                    name: 'testProviderID',
                    title: 'Test Provider',
                    // hidden: true,
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                },
                {
                    name: 'drugID',
                    title: 'Drug',
                    picker: () => this.drugDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.bonesForm.setValue('scriptID', undefined);
                    }
                },
                {
                    name: 'scriptID',
                    title: 'Prescription',
                    picker:
                    {
                        populator: () => this.scriptDB.getPrescriptionPicker(this.bonesForm.getValue('drugID')),
                        nocache: true
                    }
                },
                {
                    name: 'dt',
                    title: 'Date',
                    date: 'yyyy-MM-dd',
                    initialValue: new Date(),
                    validator: Validators.required
                },
                {
                    name: 'title',
                    title: 'Title',
                    validator: Validators.maxLength(64)
                },
                {
                    name: 'file',
                    title: 'Filename',
                    type: 'file'
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 4
                }
            ],
            uploadFormat: 'form',
            saveUrl: '/provider/Attachment.php/updateAttachment',
            cache: this.attachmentDB.cache
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue('patientID', this.launchOptions.moData.patientID);
            this.bonesForm.setValue('typeID', this.launchOptions.moData.typeID);
            this.bonesForm.setValue('providerID', this.launchOptions.moData.providerID);
            this.bonesForm.setValue('practitionerID', this.launchOptions.moData.practitionerID);
            this.bonesForm.setValue('testProviderID', this.launchOptions.moData.testProviderID);
            this.bonesForm.setValue('drugID', this.launchOptions.moData.drugID);
            this.bonesForm.setValue('scriptID', this.launchOptions.moData.scriptID);
            this.bonesForm.getItem('file').control.setValidators(Validators.required);
        }
    }
}
