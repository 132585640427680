import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { AuthGuard } from '@med/core';

import { ProviderListPage } from './pages/provider/provider-list/provider-list';
import { ProviderDetailPage } from './pages/provider/provider-detail/provider-detail';
import { PractitionerDetailPage } from './pages/practitioner/practitioner-detail/practitioner-detail';
import { NotesListPage } from './pages/notes/notes-list/notes-list';
import { AppointmentListPage } from './pages/appointment/appointment-list/appointment-list';
import { AppointmentDetailPage } from './pages/appointment/appointment-detail/appointment-detail';
import { NotesDetailPage } from './pages/notes/notes-detail/notes-detail';
import { AttachmentListPage } from './pages/attachment/attachment-list/attachment-list';
import { AttachmentDetailPage } from './pages/attachment/attachment-detail/attachment-detail';
import { DrugUseListPage } from './pages/drug/drug-use-list/drug-use-list';
import { DrugDetailPage } from './pages/drug/drug-detail/drug-detail';
import { ScriptDetailPage } from './pages/drug/script-detail/script-detail';
import { DrugListPage } from './pages/drug/drug-list/drug-list';
import { TestResultsDetailPage } from './pages/attachment/test-results-detail/test-results-detail';
import { NumbersMaintPage } from './pages/numbers/numbers-maint/numbers-maint';
import { NumbersReportPage } from './pages/numbers/numbers-report/numbers-report';
import { MyDoctorsPage } from './pages/practitioner/my-doctors/my-doctors';
import { MyDrugsPage } from './pages/drug/my-drugs/my-drugs';
import { NumbersAddDeepLinkPage } from './pages/numbers/numbers-add-deep-link/numbers-add-deep-link';
import { MyVaccinationsPage } from './pages/drug/my-vaccinations/my-vaccinations';

/**
 * Routes for pages.
 */
const routes: Route[] =
[
    {
        path: 'provider',
        canActivate: [ AuthGuard ],
        children:
        [
            { path: 'my-doctors', component: MyDoctorsPage },
            { path: 'my-drugs', component: MyDrugsPage },
            { path: 'my-vaccinations', component: MyVaccinationsPage },
            { path: 'provider-list', component: ProviderListPage },
            { path: 'provider-detail/:providerID', component: ProviderDetailPage },
            { path: 'practitioner-detail/:practitionerID', component: PractitionerDetailPage },
            { path: 'appointment-list', component: AppointmentListPage },
            { path: 'appointment-list/:providerID', component: AppointmentListPage },
            { path: 'appointment-list/:providerID/:practitionerID', component: AppointmentListPage },
            { path: 'appointment-detail/:appointmentID', component: AppointmentDetailPage },
            { path: 'attachment-list', component: AttachmentListPage },
            { path: 'attachment-list/:providerID', component: AttachmentListPage },
            { path: 'drug-attachment-list/:drugID', component: AttachmentListPage },
            { path: 'script-attachment-list/:scriptID', component: AttachmentListPage },
            { path: 'test-results-list', component: AttachmentListPage, data: { type: 'tr' } },
            { path: 'test-results-list/:providerID', component: AttachmentListPage, data: { type: 'tr' } },
            { path: 'attachment-detail/:attachmentID', component: AttachmentDetailPage },
            { path: 'test-results-detail/:attachmentID', component: TestResultsDetailPage },
            { path: 'notes-list', component: NotesListPage },
            { path: 'provider-notes/:providerID', component: NotesListPage }, //, data: { type: 'provider' } },
            { path: 'practitioner-notes/:providerID/:practitionerID', component: NotesListPage }, //, data: { type: 'practitioner' } },
            { path: 'drug-notes/:drugID', component: NotesListPage },
            { path: 'script-notes/:scriptID', component: NotesListPage },
            { path: 'notes-detail/:noteID', component: NotesDetailPage },
            { path: 'drug-list', component: DrugListPage },
            { path: 'drug-use-list', component: DrugUseListPage },
            { path: 'drug-use-list/:drugID', component: DrugUseListPage },
            { path: 'drug-detail/:drugID', component: DrugDetailPage },
            { path: 'script-detail/:scriptID', component: ScriptDetailPage },
            { path: 'numbers-report', component: NumbersReportPage },
            { path: 'numbers-report/:keyID', component: NumbersReportPage },
            // { path: 'numbers-add', component: NumbersReportPage, data: { add: true } },
            { path: 'numbers-maint', component:  NumbersMaintPage },
            { path: 'numbers-add-deep-link', component:  NumbersAddDeepLinkPage },
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forChild(routes),
    ],
    exports:
    [
        RouterModule
    ]
})
export class AppRoutingModule
{
}
