import { ValidatorFn, AsyncValidatorFn, UntypedFormControl } from '@angular/forms';
import { BonesFormItemType } from './BonesFormItemType';
import { BonesFormItemOptions } from './BonesFormItemOptions';
import { BonesFormItemToggle } from './BonesFormItemToggle';
import { BonesFormItemPicker } from './BonesFormItemPicker';
import { BonesFormItemDate } from './BonesFormItemDate';
import { BonesFormItemTextarea } from './BonesFormItemTextarea';
import { BonesForm } from './BonesForm';
import { BonesFormItemElapsedTime } from './BonesFormItemElapsedTime';

/**
 * Definition for a form item for use with <bones-form-item> component.
 */
export class BonesFormItem
{
    /**
     * Form containing this item.
     */
    form: BonesForm;

    /**
     * Angular form control for this item.
     */
    control: UntypedFormControl;

    /**
     * Name of form field.
     * 
     * This name must match the form control name in the FormGroup.
     * 
     * For BonesFormEdit use database column name, which must match the database schema, including
     * uppercase column names for Oracle databases.
     */
    name: string;

    /**
     * User friendly title for form field.
     */
    title?: string;

    /**
     * Angular form validator.
     * 
     * This option is used with BonesFormEdit where it creates the FormGroup.
     * If you are creating your own FormGroup, then specify the validators there instead of here.
     */
    validator?: ValidatorFn | ValidatorFn[];

    /**
     * Angular form async validator.
     * 
     * This option is used with BonesFormEdit where it creates the FormGroup.
     * If you are creating your own FormGroup, then specify the validators there instead of here.
     */
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[];

    /**
     * Should item be hidden from the form?
     */
    hidden?: boolean;

    /**
     * Should item be readonly?
     */
    readonly?: boolean;

    /**
     * Help text
     */
    help?: string;

    /**
     * Use a toggle for a binary column.
     */
    toggle?: BonesFormItemToggle;

    /**
     * Item content should be selected from a list of values.
     */
    picker?: BonesFormItemPicker;

    /**
     * Use date input.
     */
    date?: BonesFormItemDate;

    /**
     * Use elapsed time input.
     */
    elapsedTime?: BonesFormItemElapsedTime;

    /**
     * Use textarea for paragraph input.
     */
    textarea?: BonesFormItemTextarea;

    /**
     * Type of item. Default to 'text' for normal text entry.
     */
    type?: BonesFormItemType;

    /**
     * Custom error messages for different validators (e.g. minlength, maxlength, etc).
     */
    errorMessages?: { [validator: string]: string };

    /**
     * Initial value to use to populate form.
     */
    initialValue?: any;

    /**
     * Callback function to be called when the form value changes.
     */
    onChange?: (value: string, item?: BonesFormItem) => void;

    /**
     * Should the title be hidden?
     */
    hideTitle?: boolean;

    /**
     * Create a form item.
     * 
     * @param config either a string for a simple form item or a full blown BonesFormItemOptions
     * object.
     */
    constructor(form: BonesForm, config: BonesFormItemOptions | string)
    {
        this.form = form;

        // Item can be specified as just a string or an option object
        if (typeof config === 'string')
        {
            this.name = config;
            this.title = this.name;
            this.type = 'text';
            this.hidden = false;
            this.readonly = false;
            this.hideTitle = false;
        }
        else
        {
            this.form = form;
            this.name = config.name;
            this.title = config.title || config.name;
            this.type = config.type || 'text';
            this.hidden = config.hidden || false;
            this.readonly = config.readonly || false;
            this.hideTitle = config.hideTitle || false;
            this.help = config.help;
            this.validator = config.validator;
            this.asyncValidator = config.asyncValidator;
            this.errorMessages = config.errorMessages || { };
            this.initialValue = config.initialValue;
            this.onChange = config.onChange;

            // Configure toggle
            if (config.toggle)
            {
                this.type = 'toggle';
                this.toggle = new BonesFormItemToggle(config.toggle);
            }
            else if (this.type === 'toggle')
            {
                this.toggle = new BonesFormItemToggle({ on: true, off: false });
            }

            // Configure picker
            if (config.picker)
            {
                this.type = 'picker';
                this.picker = new BonesFormItemPicker(config.picker);
            }

            // Configure date input
            if (config.date)
            {
                this.type = 'date';
                this.date = new BonesFormItemDate(config.date);
            }
            else if (this.type === 'date')
            {
                this.date = new BonesFormItemDate(undefined as string);
            }

            // Configure date input
            if (config.elapsedTime)
            {
                this.type = 'elapsedTime';
                this.elapsedTime = new BonesFormItemElapsedTime(config.elapsedTime);
            }
            else if (this.type === 'elapsedTime')
            {
                this.elapsedTime = new BonesFormItemElapsedTime(undefined as string);
            }

            // Configure textarea
            if (config.textarea)
            {
                this.type = 'textarea';
                this.textarea = new BonesFormItemTextarea(config.textarea);
            }
            else if (this.type === 'textarea')
            {
                this.textarea = new BonesFormItemTextarea(undefined as number);
            }
        }

        // Create form control
        // this.control = new FormControl(this.initialValue, this.validator, this.asyncValidator);
        this.control = new UntypedFormControl();
        this.control.setValue(this.initialValue);
        if (this.validator)
        {
            this.control.setValidators(this.validator);
        }
        if (this.asyncValidator)
        {
            this.control.setAsyncValidators(this.asyncValidator);
        }

        // console.log('BonesFormItem.constructor', this.name, this.control.value);
    }

    /**
     * Convert array of item options into array of items.
     * 
     * @param options Array of BonesFormItemOptions objects
     * @returns Array of BonesFormItem objects
     * @deprecated
     */
    public static createItems(form: BonesForm, options: (BonesFormItemOptions | string)[]) : BonesFormItem[]
    {
        const items: BonesFormItem[] = [ ];

        // Convert column options to full blown item object array
        options.forEach(option =>
        {
            items.push(new BonesFormItem(form, option));
        });

        return items;
    }

    /**
     * Get value currently populated in form
     * @returns form value
     */
    public getValue() : string
    {
        return this.control.value;
    }

    /**
     * Set value currently populated in form
     * @param value value to place in form
     */
    public setValue(value: any) : void
    {
        // console.log('BonesFormItem.setValue', this.name, 'from', this.control.value, 'to', value);
        this.control.setValue(value);
    }
}
