import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
// import { Device } from '@ionic-native/device/ngx';
// import { AppVersion } from '@ionic-native/app-version/ngx';

import { BonesVersion } from './model/bones-version';
import { BonesTestToolsPiglets } from './model/bones-test-tools-piglets';

import { BonesStopClickDirective } from './directive/bones-stop-click';

import { BonesSideMenuComponent } from './component/bones-side-menu/bones-side-menu';
import { BonesMenuCardComponent } from './component/bones-menu-card/bones-menu-card';
import { BonesEmailLinkComponent } from './component/bones-email-link/bones-email-link';
import { BonesWebphoneLinkComponent } from './component/bones-webphone-link/bones-webphone-link';
import { BonesTtPageHeaderComponent } from './component/bones-tt-page-header/bones-tt-page-header';
import { BonesTtModalHeaderComponent } from './component/bones-tt-modal-header/bones-tt-modal-header';
import { BonesTtErrorCardComponent } from './component/bones-tt-error-card/bones-tt-error-card';

import { BonesTestToolsPage } from './pages/test-tools/test-tools';
import { TestToolsTestCasesPage } from './pages/test-tools-test-cases/test-tools-test-cases';
import { BonesTestToolsAppInfoPage } from './pages/test-tools-appinfo/test-tools-appinfo';
import { BonesTestToolsCookiesPage } from './pages/test-tools-cookies/test-tools-cookies';
import { BonesTestToolsDevicePage } from './pages/test-tools-device/test-tools-device';
import { BonesTestToolsErrorLogPage } from './pages/test-tools-error-log/test-tools-error-log';
import { BonesTestToolsErrorDetailPage } from './pages/test-tools-error-detail/test-tools-error-detail';
import { BonesTestToolsLocationPage } from './pages/test-tools-location/test-tools-location';
import { BonesTestToolsPlatformPage } from './pages/test-tools-platform/test-tools-platform';
import { BonesTestToolsVersionsPage } from './pages/test-tools-versions/test-tools-versions';
import { BonesCardMenuComponent } from './component/bones-card-menu/bones-card-menu';
import { BonesElapsedTimePipe } from './pipe/bones-elapsed-time';
import { BonesTimeSincePipe } from './pipe/bones-time-since';
import { BonesPropertyListComponent } from './component/bones-property-list/bones-property-list';
import { BonesHttpResponseCodeComponent } from './component/bones-http-response-code/bones-http-response-code';
import { BonesAppLauncherIconComponent } from './component/bones-app-launcher-icon/bones-app-launcher-icon';
import { BonesAppLauncherPaneComponent } from './component/bones-app-launcher-pane/bones-app-launcher-pane';
import { BonesPickerPage } from './pages/bones-picker-modal/bones-picker-modal';
import { BonesTestToolsPickerPage } from './pages/test-tools-picker/test-tools-picker';
import { BonesCardToolbarComponent } from './component/bones-card-toolbar/bones-card-toolbar';
import { BonesTestToolsSortPage } from './pages/test-tools-sort/test-tools-sort';

/**
 * Routes for core test tools.
 */
const routes: Routes =
[
    {
        path: 'bones',
        children:
        [
            { path: 'test-tools', component: BonesTestToolsPage },
            { path: 'test-tools/group/:groupName', component: BonesTestToolsPage },
            { path: 'test-tools-cases/:groupName', component: TestToolsTestCasesPage },
            { path: 'test-tools/core/appinfo', component: BonesTestToolsAppInfoPage },
            { path: 'test-tools/core/cookies', component: BonesTestToolsCookiesPage },
            { path: 'test-tools/core/device', component: BonesTestToolsDevicePage },
            { path: 'test-tools/core/errors', component: BonesTestToolsErrorLogPage },
            { path: 'test-tools/core/error/detail/:id', component: BonesTestToolsErrorDetailPage },
            { path: 'test-tools/core/error-detail/:id', component: BonesTestToolsErrorDetailPage },
            { path: 'test-tools/core/location', component: BonesTestToolsLocationPage },
            { path: 'test-tools/core/picker', component: BonesTestToolsPickerPage },
            { path: 'test-tools/core/platform', component: BonesTestToolsPlatformPage },
            { path: 'test-tools/core/sort', component: BonesTestToolsSortPage },
            { path: 'test-tools/core/versions', component: BonesTestToolsVersionsPage }
        ]
    }
];

/**
 * Bones core functionality required by all apps and modules.
 */
@NgModule({
    declarations: [
        BonesStopClickDirective,
        BonesElapsedTimePipe,
        BonesTimeSincePipe,
        BonesSideMenuComponent,
        BonesMenuCardComponent,
        BonesCardToolbarComponent,
        BonesCardMenuComponent,
        BonesEmailLinkComponent,
        BonesWebphoneLinkComponent,
        BonesTtPageHeaderComponent,
        BonesTtModalHeaderComponent,
        BonesTtErrorCardComponent,
        BonesPropertyListComponent,
        BonesHttpResponseCodeComponent,
        BonesAppLauncherPaneComponent,
        BonesAppLauncherIconComponent,
        BonesPickerPage,
        BonesTestToolsPage,
        TestToolsTestCasesPage,
        BonesTestToolsAppInfoPage,
        BonesTestToolsCookiesPage,
        BonesTestToolsDevicePage,
        BonesTestToolsErrorLogPage,
        BonesTestToolsErrorDetailPage,
        BonesTestToolsLocationPage,
        BonesTestToolsPickerPage,
        BonesTestToolsPlatformPage,
        BonesTestToolsSortPage,
        BonesTestToolsVersionsPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    // Use for services that are referenced only within this module
    // Global providers need to be annotated with: providedIn: 'root'
    providers: [
    // Device,
    // AppVersion
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: [
        BonesStopClickDirective,
        BonesElapsedTimePipe,
        BonesTimeSincePipe,
        BonesSideMenuComponent,
        BonesMenuCardComponent,
        BonesCardMenuComponent,
        BonesCardToolbarComponent,
        BonesEmailLinkComponent,
        BonesWebphoneLinkComponent,
        BonesTtPageHeaderComponent,
        BonesTtModalHeaderComponent,
        BonesTtErrorCardComponent,
        BonesPropertyListComponent,
        BonesHttpResponseCodeComponent,
        BonesAppLauncherPaneComponent,
        BonesAppLauncherIconComponent
    ]
})
export class BonesCoreModule
{
    static initialize()
    {
        BonesVersion.map.set('core', '1.3.2023-02-02');

        BonesTestToolsPiglets.tools.push(
        {
            group: 'About',
            title: 'Versions',
            icon: 'leaf',
            path: 'bones/test-tools/core/versions'
        },
        {
            group: 'About',
            title: 'Device',
            icon: 'phone-portrait',
            path: 'bones/test-tools/core/device'
        },
        {
            group: 'About',
            title: 'Platform',
            icon: 'laptop',
            path: 'bones/test-tools/core/platform'
        },
        {
            group: 'About',
            title: 'Application Info',
            icon: 'code',
            path: 'bones/test-tools/core/appinfo'
        },
        {
            group: 'About',
            title: 'Cookies',
            icon: 'football',
            path: 'bones/test-tools/core/cookies'
        },
        {
            group: 'About',
            title: 'Location',
            icon: 'locate',
            path: 'bones/test-tools/core/location'
        },
        {
            group: 'Core',
            title: 'Picker',
            icon: 'list',
            path: 'bones/test-tools/core/picker'
        },
        {
            group: 'Core',
            title: 'Sort',
            icon: 'funnel',
            path: 'bones/test-tools/core/sort'
        },
        {
            group: 'Logs',
            title: 'Error Log',
            icon: 'bug',
            path: 'bones/test-tools/core/errors'
        });
    }
}
BonesCoreModule.initialize();
