import { PatientInfo } from '@med/core';
import { Drug } from './Drug';
import { VaccinationInfo } from './VaccinationInfo';
import { Provider } from './Provider';
import { ProviderLocationInfo } from './ProviderLocationInfo';

export class Vaccination implements VaccinationInfo
{
    vaccinationID: number;

    patientID: number;
    drugID: number;
    providerID: number;
    locationID: number;
    vaccinated: string;
    notes: string;

    vaccinatedDate: Date;

    patient: PatientInfo;
    drug: Drug;
    provider: Provider;
    location: ProviderLocationInfo;

    constructor(row: VaccinationInfo)
    {
        Object.assign(this, row);

        this.vaccinatedDate = new Date(this.vaccinated);
    }

}
