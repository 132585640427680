import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { AppUser, UserService } from '@med/core';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { NumberUsageService, UsageFilter } from '@med/provider/service/number-usage';
import { NumberUsage } from '@med/provider/class/NumberUsage';
import { GroupedUsage } from '@med/provider/class/GroupedUsage';
import { NumberValueService } from '@med/provider/service/number-value';
import { NumberValueInfo } from '@med/provider/class/NumberValueInfo';
import { ChartDataSeries } from '@med/provider/class/ngx-charts/ChartDataSeries';
import { ChartDataSeri } from '@med/provider/class/ngx-charts/ChartDataSeri';
import { chartColorScheme } from '@med/provider/class/ngx-charts/ChartColorScheme';

class DataPoint
{
    info: NumberValueInfo;
    dt: Date;
    dtt: number;

    constructor(info: NumberValueInfo)
    {
        this.info = info;

        this.dt = new Date(info.dt.replace(' ', 'T'));
        this.dtt = this.dt.getTime();
    }
}

@Component({
    selector: 'page-numbers-report',
    templateUrl: 'numbers-report.html'
})
export class NumbersReportPage implements OnInit, OnDestroy
{
    user: AppUser;
    // usage: NumberUsage[];
    groupedUsage: GroupedUsage[];
    chosenUsage: NumberUsage;
    allValues: DataPoint[];
    points: DataPoint[];
    rvalues: DataPoint[];
    chart: ChartDataSeries[];
    cardMenu: BonesMenuCardAction[];
    private nal: (() => void)[] = [ ];
    lowValue: DataPoint;
    highValue: DataPoint;
    firstDateAvailable: Date;
    lastDateAvailable: Date;
    lowDate: Date;
    highDate: Date;
    scheme = chartColorScheme;
    showChartRangeSlider = false;
    chartRangeMin = 10;
    chartStepSize: number;
    ampmEligable: boolean;
    useAmPm = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private alert: AlertController,
        private es: BonesErrorService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private usedb: NumberUsageService,
        private valuedb: NumberValueService
    )
    {
        // this.keyID = +this.route.snapshot.params.keyID;

        this.user = this.mtus.getUser();

        this.cardMenu =
        [
            {
                title: 'Add',
                icon: 'pencil',
                action: () => this.edit()
            },
            {
                title: 'Subscribe',
                icon: 'add-circle',
                action: () => this.launch.subscribeNumbers()
            },
        ];
    }

    async ngOnInit()
    {
        // Load and refresh usage as needed
        this.nal.push(this.usedb.cache.nowAndLater(
        async rows =>
        {
            this.groupedUsage = new UsageFilter(rows)
            .byPatient(this.user.patient.patientID)
            .group();

            // Drill down to specific key if one passed in on url
            if (this.route.snapshot.params.keyID)
            {
                const keyID = +this.route.snapshot.params.keyID;
                this.groupedUsage.forEach(gu =>
                {
                    const usage = gu.usage.find(u => u.keyID === keyID);
                    if (usage)
                    {
                        this.detail(usage);
                    }
                });
            }

            // if (this.route.snapshot.data?.add)
            // {
            //     this.edit();
            // }
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    detail(usage: NumberUsage)
    {
        this.chosenUsage = usage;
        this.chart = undefined;
        this.allValues = undefined;
        // this.ampmEligable = usage.keyID < 4;
        this.ampmEligable = !usage.isLabResult;
        // this.useAmPm = this.useAmPm && this.ampmEligable;

        this.valuedb.getValues(this.user.patient.patientID, usage.keyID)
        .then(values =>
        {
            // console.log('values', values);
            if (values.length === 0)
            {
                this.allValues = [ ];
                this.rvalues = [ ];
            }
            else
            {
                this.allValues = values.map(v => new DataPoint(v));

                this.firstDateAvailable = this.allValues[0].dt;
                this.lastDateAvailable = this.allValues[this.allValues.length - 1].dt;
                this.chartStepSize = (this.lastDateAvailable.getTime() - this.firstDateAvailable.getTime()) / this.allValues.length;

                // Range slider to adjust chart detail
                this.showChartRangeSlider = (this.allValues.length > this.chartRangeMin);
                if (this.showChartRangeSlider)
                {
                    // Default to mid-point
                    this.lowDate = this.allValues[Math.floor(this.allValues.length / 2)].dt;
                    this.highDate = this.lastDateAvailable;
                }
                else
                {
                    this.lowDate = this.firstDateAvailable;
                    this.highDate = this.lastDateAvailable;
                }

                // Create chart data points
                this.mungData();
            }
        })
        .catch(error => this.es.errorHandler(error));
    }

    mungData()
    {
        // Filter to match date range
        this.points = this.allValues.filter(v => v.dtt >= this.lowDate.getTime() && v.dtt <= this.highDate.getTime());

        // Create reverse sorted list for the grid
        this.rvalues = [ ...this.points ].sort((a, b) => b.dtt - a.dtt);

        // Find min/max values
        this.lowValue = this.points.reduce((prev, curr) => prev.info.value < curr.info.value ? prev : curr);
        this.highValue = this.points.reduce((prev, curr) => prev.info.value > curr.info.value ? prev : curr);

        // Create lines for the chart
        const charts: ChartDataSeries[] = [ ];

        // Create average line when there are enough points to be worth averaging
        if (this.points.length > 3)
        {
            const avgLine = new ChartDataSeries('Average');
            charts.push(avgLine);
            let avg = this.points[0].info.value;

            this.points.forEach(row =>
            {
                avg = avg * .8 + row.info.value * .2;
                avgLine.series.push({ name: row.info.dt, value: avg, extra: row });
            });
        }

        // Create separate lines for am vs pm
        if (this.useAmPm && this.ampmEligable)
        {
            const amLine = new ChartDataSeries('AM');
            const pmLine = new ChartDataSeries('PM');
            charts.push(amLine, pmLine);

            this.points.forEach(row =>
            {
                const hh = +row.info.dt.substr(11, 2);
                ((hh < 13) ? amLine : pmLine).series.push({ name: row.info.dt, value: row.info.value, extra: row });
            });
        }
        // Create single value line
        else
        {
            const valueLine = new ChartDataSeries('Results');
            charts.push(valueLine);
            this.points.forEach(row => valueLine.series.push({ name: row.info.dt, value: row.info.value, extra: row }));
        }

        // Assign the variable used by ng-charts last to trigger redraw only when all data is ready
        this.chart = [ ...charts ];
    }

    /**
     * The date range slider has been changed
     */
    onDateRangeChanged(values: { lower: number; upper: number; })
    {
        // console.log('chartRangeChangedDate', values);
        this.lowDate = this.allValues[this.closest(values.lower)].dt;
        this.highDate = this.allValues[this.closest(values.upper)].dt;

        // Re-create chart data points
        this.mungData();
    }

    /**
     * Get closest index to time value
     */
    private closest(to: number) : number
    {
        // Take all the values
        return this.allValues
        // Extract index and diff between data point date and ion-range value
        .map((v, i) => ({ idx: i, diff: Math.abs(to - v.dtt) }))
        // Sort by smallest difference
        .sort((a, b) => a.diff - b.diff)
        // Get first match
        [0]
        // Return index
        .idx;
    }

    /**
     * Point on chart has been clicked
     */
    async onChartClick(cd: ChartDataSeri)
    {
        const row: DataPoint = cd.extra;

        if (row.info.attachmentID)
        {
            // this.launch.viewAttachment(row.attachmentID);
            this.router.navigate([ '/provider/test-results-detail', row.info.attachmentID ]);
        }
        // else
        // {
        //     (await this.alert.create({ message: 'This reading is not linked to a lab test' })).present();
        // }
    }

    async edit(row?: NumberValueInfo)
    {
        const response = await this.launch.editManualNumberValues(row);

        // Values were edited
        if (response.data)
        {
            if (this.chosenUsage)
            {
                this.detail(this.chosenUsage);
            }
        }
    }

}
