import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { UserService, AppUser } from '@med/core';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { Drug } from '@med/provider/class/Drug';
import { RefillService } from '@med/provider/service/drug-refill';
import { Refill } from '@med/provider/class/Refill';

@Component({
    selector: 'drug-list-card',
    templateUrl: 'drug-list-card.html'
})
export class DrugListCardComponent implements OnInit
{
    @Input() drugs: Drug[];
    @Input() title: string;
    @Input() subtitle: string;

    user: AppUser;
    refillMap: Map<number, Refill> = new Map<number, Refill>();

    // Dropdown menu
    actions: BonesMenuCardAction[] =
    [
        {
            title: 'Add',
            icon: 'add-circle',
            action: () => this.add()
        }
    ];

    constructor(
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private refillDB: RefillService
    )
    {
    }

    async ngOnInit()
    {
        this.user = this.mtus.getUser();
    }

    // Get product name from the last refill
    // async lastRefill(drug: Drug)
    // {
    //     if (!this.refillMap.has(drug.drugID))
    //     {
    //         this.refillMap.set(drug.drugID, await this.refillDB.getLastRefill(this.user.patient.patientID, drug.drugID));
    //     }

    //     return this.refillMap.get(drug.drugID)?.prescription.productName;
    // }

    add()
    {
        this.launch.addDrug();
    }

}
