import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { BonesCoreModule } from '@bones/core';
import { BonesFormModule } from '@bones/form';
import { BonesNetworkModule } from '@bones/network';
import { BonesEditModule } from '@bones/edit';

import { AppCoreModule } from '@med/core';
import { ProviderModule } from '@med/provider';
import { AdminModule } from '@med/admin';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HomePage } from './home/home.page';
import { SearchPage } from './search/search';

@NgModule({
    declarations: [
        AppComponent,
        HomePage,
        SearchPage
    ],
    imports: [
        BrowserModule,
        FormsModule, ReactiveFormsModule,
        IonicModule.forRoot(),
        BonesCoreModule,
        BonesFormModule,
        BonesNetworkModule,
        BonesEditModule,
        AppRoutingModule,
        AppCoreModule,
        ProviderModule,
        AdminModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
