import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { AppUser, UserService } from '@med/core';

import { Practitioner } from '@med/provider/class/Practitioner';
import { Appointment } from '@med/provider/class/Appointment';
import { AppointmentService, AppointmentFilter } from '@med/provider/service/appointment';
import { PractitionerService } from '@med/provider/service/practitioner';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { AddScriptDefaults, AddAttachmentDefaults, AddAppointmentDefaults } from '@med/provider/service/launch-edit-modal';
import { AttachmentService, AttachmentFilter } from '@med/provider/service/attachment';
import { PrescriptionService, PrescriptionFilter } from '@med/provider/service/drug-script';
import { Prescription } from '@med/provider/class/Prescription';
import { AttachmentTypeService } from '@med/provider/service/attachmentType';
import { Attachment } from '@med/provider/class/Attachment';
import { NoteService, NoteFilter } from '@med/provider/service/note';
import { Note } from '@med/provider/class/Note';

@Component({
    selector: 'page-practitioner-detail',
    templateUrl: 'practitioner-detail.html'
})
export class PractitionerDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    practitionerID: number;
    practitioner: Practitioner;
    colleagues: Practitioner[];
    upcomingAppointments: Appointment[];
    pastAppointments: Appointment[];
    scripts: Prescription[];
    scriptDefaults: AddScriptDefaults;
    providerAttachments: Attachment[];
    providerTestResults: Attachment[];
    practitionerAttachments: Attachment[];
    practitionerRecentTestResults: Attachment[];
    practitionerPastTestResults: Attachment[];
    practitionerNotes: Note[];
    providerNotes: Note[];
    attachmentAddDefaults: AddAttachmentDefaults;
    testResultAddDefaults: AddAttachmentDefaults;
    appointmentAddDefaults: AddAppointmentDefaults;
    noteAddDefaults: AddAppointmentDefaults;
    practitionerMenu: BonesMenuCardAction[];
    appointmentsMenu: BonesMenuCardAction[];
    private nal: (() => void)[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private es: BonesErrorService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private practitionerDB: PractitionerService,
        private appointmentDB: AppointmentService,
        private attachmentDB: AttachmentService,
        private attachmentTypeDB: AttachmentTypeService,
        private noteDB: NoteService,
        private scriptDB: PrescriptionService
    )
    {
        // Access providerID passed in URL
        this.practitionerID = +this.route.snapshot.params.practitionerID;
        this.user = this.mtus.getUser();

        // Card menus
        this.practitionerMenu =
        [
            this.launch.getMenuItemEdit(() => this.edit())
        ];

        this.appointmentsMenu =
        [
            this.launch.getMenuItemAdd(() => this.launch.addAppointment(this.appointmentAddDefaults)),
        ];
    }

    ngOnInit()
    {
        // Practitioner detail
        this.practitionerDB.getPractitioner(this.practitionerID)
        .then(async row =>
        {
            this.practitioner = row;

            // Load and refresh colleagues (practitioners) as needed
            this.nal.push(this.practitionerDB.cache.nowAndLater(
            rows => this.colleagues =
                rows.filter(p => p.providerID === this.practitioner.providerID && p.practitionerID !== this.practitionerID),
            error => this.es.errorHandler(error)));

            // Load and refresh attachments and test results as needed
            this.nal.push(this.attachmentDB.cache.nowAndLater(
            rows =>
            {
                const attachments = new AttachmentFilter(rows)
                .byPatient(this.user.patient.patientID)
                .byProvider(this.practitioner.providerID)
                .rows;

                this.practitionerAttachments = new AttachmentFilter(attachments)
                .byPractitioner(this.practitionerID)
                .notTestResults()
                .rows;

                this.practitionerRecentTestResults = new AttachmentFilter(attachments)
                .byPractitioner(this.practitionerID)
                .testResults()
                .recent()
                .rows;

                this.practitionerPastTestResults = new AttachmentFilter(attachments)
                .byPractitioner(this.practitionerID)
                .testResults()
                .old()
                .rows;

                this.providerAttachments = new AttachmentFilter(attachments)
                .notTestResults()
                .rows
                .filter(r => r.practitionerID !== this.practitionerID);

                this.providerTestResults = new AttachmentFilter(attachments)
                .testResults()
                .rows
                .filter(r => r.practitionerID !== this.practitionerID);
            },
            error => this.es.errorHandler(error)));

            // Load and refresh notes as needed
            this.nal.push(this.noteDB.cache.nowAndLater(
            rows =>
            {
                const allNotes = new NoteFilter(rows)
                .byPatient(this.user.patient.patientID)
                .byProvider(this.practitioner.providerID)
                .rows;

                this.practitionerNotes = new NoteFilter(allNotes)
                .byPractitioner(this.practitionerID)
                .rows;

                this.providerNotes = new NoteFilter(allNotes)
                .rows
                .filter(r => r.info.practitionerID !== this.practitionerID);
            },
            error => this.es.errorHandler(error)));

            // Defaults for adding test result
            const trt = await this.attachmentTypeDB.getTestResultsType();
            const doct = await this.attachmentTypeDB.getDocumentType();
            this.testResultAddDefaults =
            {
                patientID: this.user.patient.patientID,
                providerID: this.practitioner.providerID,
                practitionerID: this.practitionerID,
                typeID: trt.typeID
            };

            this.attachmentAddDefaults =
            {
                patientID: this.user.patient.patientID,
                providerID: this.practitioner.providerID,
                practitionerID: this.practitionerID,
                typeID: doct.typeID
            };

            this.appointmentAddDefaults =
            {
                patientID: this.user.patient.patientID,
                providerID: this.practitioner.providerID,
                practitionerID: this.practitionerID
            };

            this.noteAddDefaults =
            {
                patientID: this.user.patient.patientID,
                providerID: this.practitioner.providerID,
                practitionerID: this.practitionerID
            };

            // Defaults when adding new attachment
            this.scriptDefaults =
            {
                patientID: this.user.patient.patientID,
                practitionerID: this.practitionerID,
            };
        })
        .catch(error => this.es.errorHandler(error));

        // Load and refresh appointments as needed
        this.nal.push(this.appointmentDB.cache.nowAndLater(
        rows =>
        {
            const allAappointments = new AppointmentFilter(rows)
            .byPatient(this.user.patient.patientID)
            // .byProvider(this.providerID)
            .byPractitioner(this.practitionerID)
            .rows;

            this.upcomingAppointments = new AppointmentFilter(allAappointments)
            .upcoming()
            .rows;

            this.pastAppointments = new AppointmentFilter(allAappointments)
            .past()
            .rows;
        },
        error => this.es.errorHandler(error)));

        // Load and refresh scripts as needed
        this.nal.push(this.scriptDB.cache.nowAndLater(
        rows =>
        {
            this.scripts = new PrescriptionFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byPractitioner(this.practitionerID)
            .rows;

            // Defaults when adding new prescription
            this.scriptDefaults =
            {
                patientID: this.user.patient.patientID,
                practitionerID: this.practitionerID,
            };
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    async edit()
    {
        const results = await this.launch.editPractitioner(this.practitioner);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }
}
