import { PatientInfo } from '@med/core';
import { Drug } from './Drug';
import { PrescriptionInfo } from './PrescriptionInfo';
import { Practitioner } from './Practitioner';

export class Prescription implements PrescriptionInfo
{
    scriptID: number;

    patientID: number;
    drugID: number;
    practitionerID: number;
    issued: string;
    dosage: string;
    frequency: string;
    productName: string;
    url: string;
    notes: string;

    contentType: string;

    issuedDate: Date;

    patient: PatientInfo;
    drug: Drug;
    practitioner: Practitioner;

    constructor(row: PrescriptionInfo)
    {
        Object.assign(this, row);

        this.issuedDate = new Date(this.issued);
    }

}
