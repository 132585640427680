import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayEventDetail } from '@ionic/core';
import { ModalController } from '@ionic/angular';

import { BonesEditForm } from '@bones/edit';
import { BonesEditFormLaunchResults } from '@bones/edit/class/BonesEditFormLaunchResults';

import { UserService, AppUser, KpsRest } from '@med/core';
import { Appointment } from '../class/Appointment';
import { AppointmentEditPage } from '../pages/appointment/appointment-edit/appointment-edit';
import { Note } from '../class/Note';
import { NoteEditPage } from '../pages/notes/note-edit/note-edit';
import { PractitionerEditPage } from '../pages/practitioner/practitioner-edit/practitioner-edit';
import { Practitioner } from '../class/Practitioner';
import { ProviderEditPage } from '../pages/provider/provider-edit/provider-edit';
import { Provider } from '../class/Provider';
import { AttachmentEditPage } from '../pages/attachment/attachment-edit/attachment-edit';
import { Attachment } from '../class/Attachment';
import { ProviderLocationInfo } from '../class/ProviderLocationInfo';
import { LocationEditPage } from '../pages/provider/location-edit/location-edit';
import { DrugRefillEditPage } from '../pages/drug/refill-edit/refill-edit';
import { Prescription } from '../class/Prescription';
import { Refill } from '../class/Refill';
import { DrugUse } from '../class/DrugUse';
import { DrugUseEditPage } from '../pages/drug/drug-use-edit/drug-use-edit';
import { ScriptEditPage } from '../pages/drug/script-edit/script-edit';
import { Drug } from '../class/Drug';
import { DrugEditPage } from '../pages/drug/drug-edit/drug-edit';
import { NumbersSubscribePage } from '../pages/numbers/numbers-subscribe/numbers-subscribe';
import { NumbersEditPage } from '../pages/numbers/numbers-edit/numbers-edit';
import { NumberValueInfo } from '../class/NumberValueInfo';
import { AttachmentTypeService } from '@med/provider/service/attachmentType';
import { NumberValueUpdate } from '../class/NumberValueUpdate';
import { Vaccination } from '../class/Vaccination';
import { VaccinationEditPage } from '../pages/drug/vaccination-edit/vaccination-edit';

export interface AddPractitionerDefaults
{
    providerID?: number;
}

export interface AddAppointmentDefaults
{
    patientID?: number;
    providerID?: number;
    locationID?: number;
    practitionerID?: number;
}

export interface AddAttachmentDefaults
{
    patientID?: number;
    typeID?: number;
    providerID?: number;
    practitionerID?: number;
    testProviderID?: number;
    drugID?: number;
    scriptID?: number;
}

export interface AddDrugRefillDefaults
{
    scriptID?: number;
    drugstoreID?: number;
    days?: number;
    quantity?: string;
    cost?: number;
    refills?: number;
}

export interface AddDrugUseDefaults
{
    patientID?: number;
    drugID?: number;
    dosage?: string;
    frequency?: string;
}

export interface AddScriptDefaults
{
    patientID?: number;
    drugID?: number;
    practitionerID?: number;
    dosage?: string;
    frequency?: string;
    productName?: string;
}

export interface AddNoteDefaults
{
    patientID?: number;
    providerID?: number;
    practitionerID?: number;
    drugID?: number;
    scriptID?: number;
}

export interface AddVaccinationDefaults
{
    patientID?: number;
    drugID?: number;
    providerID?: number;
    locationID?: number;
}

/**
 * Appointment info
 */
@Injectable({
  providedIn: 'root',
})
export class LaunchEditModalService
{
    user: AppUser;

    constructor(
        private modalCtrl: ModalController,
        private rest: KpsRest,
        private router: Router,
        private mtus: UserService,
        private attachmentTypeDB: AttachmentTypeService
    )
    {
        this.user = this.mtus.getUser();
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editProvider(row: Provider) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: ProviderEditPage,
            pk: row.providerID
        });
    }

    /**
     * Add row
     */
    public async addProvider() : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: ProviderEditPage
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                this.router.navigate([ '/provider/provider-detail', results.payload.id ]);
            }

            return results;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editPractitioner(row: Practitioner) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: PractitionerEditPage,
            pk: row.practitionerID
        });
    }

    /**
     * Add new row
     */
    public async addPractitioner(defaults: AddPractitionerDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: PractitionerEditPage,
            moData:
            {
                providerID: defaults.providerID,
            }
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                this.router.navigate([ '/provider/practitioner-detail', results.payload.id ]);
            }

            return results;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editAppointment(row: Appointment) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: AppointmentEditPage,
            pk: row.appointmentID,
            cssClass: 'bones-edit-form-90-80',
        });
    }

    /**
     * Add new row
     */
    public async addAppointment(defaults: AddAppointmentDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: AppointmentEditPage,
            cssClass: 'bones-edit-form-90-80',
            moData:
            {
                patientID: defaults.patientID || this.user.patient.patientID,
                providerID: defaults.providerID,
                locationID: defaults.locationID,
                practitionerID: defaults.practitionerID
            }
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                this.router.navigate([ '/provider/appointment-detail', results.payload.id ]);
            }

            return results;
        });
    }

    /**
     * Copy existing row
     */
    public copyAppointment(row: Appointment) : Promise<BonesEditFormLaunchResults>
    {
        return this.addAppointment(
        {
            patientID: row.patient.patientID,
            providerID: row.provider.providerID,
            locationID: row.location ? row.location.locationID : undefined,
            practitionerID: row.practitionerID
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editAttachment(row: Attachment) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: AttachmentEditPage,
            pk: row.attachmentID,
            cssClass: 'bones-edit-form-90-80',
        });
    }

    /**
     * Add new row
     */
    public async addAttachment(defaults: AddAttachmentDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: AttachmentEditPage,
            cssClass: 'bones-edit-form-90-80',
            moData:
            {
                patientID: defaults.patientID || this.user.patient.patientID,
                typeID: defaults.typeID,
                providerID: defaults.providerID,
                practitionerID: defaults.practitionerID,
                testProviderID: defaults.testProviderID,
                drugID: defaults.drugID,
                scriptID: defaults.scriptID
            }
        })
        .then(async results =>
        {
            if (results.action !== 'cancel' && await this.attachmentTypeDB.isTestResult((defaults.typeID)))
            {
                this.router.navigate([ '/provider/test-results-detail', results.payload.id ]);
            }

            return results;
        });
    }

    /**
     * Open attachment in new tab
     */
    public viewAttachment(arg: Attachment | number) : void
    {
        const attachmentID = typeof arg === 'number' ? arg : arg.attachmentID;
        const url = this.rest.serverUrl + '/provider/Attachment.php/downloadAttachment';
        window.open(url + '?attachmentID=' + attachmentID, '_blank');
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editNote(row: Note) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: NoteEditPage,
            pk: row.noteID,
            cssClass: 'bones-edit-form-90-80',
        });
    }

    /**
     * Add new row
     */
    public async addNote(defaults: AddNoteDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: NoteEditPage,
            cssClass: 'bones-edit-form-90-80',
            moData:
            {
                patientID: defaults.patientID || this.user.patient.patientID,
                providerID: defaults.providerID,
                practitionerID: defaults.practitionerID,
                drugID: defaults.drugID,
                scriptID: defaults.scriptID
            }
        });
    }

    /**
     * Route to note detail
     */
    public noteDetail(note: Note) : void
    {
        if (note.info)
        {
            this.router.navigate([ '/provider/notes-detail', note.noteID ]);
        }
        else if (note.appointment)
        {
            this.router.navigate([ '/provider/appointment-detail', note.appointment.appointmentID ]);
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editLocation(row: ProviderLocationInfo) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: LocationEditPage,
            pk: row.locationID
        });
    }

    /**
     * Add new row
     */
    public async addLocation(providerID: number) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: LocationEditPage,
            moData:
            {
                providerID: providerID
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editScript(row: Prescription) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: ScriptEditPage,
            pk: row.scriptID,
            cssClass: 'bones-edit-form-90-80',
        });
    }

    /**
     * Add new row
     */
    public async addScript(defaults: AddScriptDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: ScriptEditPage,
            cssClass: 'bones-edit-form-90-80',
            moData:
            {
                defaults: defaults
            }
        });
    }

    /**
     * Open script attachment in new tab
     */
    public viewScript(row: Prescription) : void
    {
        const url = this.rest.serverUrl + '/drug/Prescriptions.php/downloadPrescription';
        window.open(url + '?scriptID=' + row.scriptID, '_blank');
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editDrugRefill(row: Refill) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugRefillEditPage,
            pk: row.refillID,
            moData: { prescription: row.prescription },
            cssClass: 'bones-edit-form-90-80',
        });
    }

    /**
     * Add new row
     */
    public async addDrugRefill(prescription: Prescription, defaults: AddDrugRefillDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugRefillEditPage,
            cssClass: 'bones-edit-form-90-80',
            moData:
            {
                prescription: prescription,
                defaults: defaults
            }
        });
    }

    /**
     * Open refill receipt in new tab
     */
    public viewRefillReceipt(row: Refill) : void
    {
        const url = this.rest.serverUrl + '/drug/Refill.php/downloadReceipt';
        window.open(url + '?refillID=' + row.refillID, '_blank');
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editDrug(row: Drug) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugEditPage,
            pk: row.drugID
        });
    }

    /**
     * Add new row
     */
    public async addDrug() : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugEditPage
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editDrugUse(row: DrugUse) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugUseEditPage,
            pk: row.useID,
            cssClass: 'bones-edit-form-90-80',
        });
    }

    /**
     * Add new row
     */
    public async addDrugUse(defaults: AddDrugUseDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugUseEditPage,
            cssClass: 'bones-edit-form-90-80',
            moData:
            {
                defaults: defaults
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public editVaccination(row: Vaccination) : Promise<BonesEditFormLaunchResults>
    {
        console.log('editVaccination', row);
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: VaccinationEditPage,
            pk: row.vaccinationID
        });
    }

    /**
     * Add new row
     */
    public async addVaccination(defaults?: AddVaccinationDefaults) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: VaccinationEditPage,
            moData:
            {
                defaults: { patientID: this.user.patient.patientID, ...defaults }
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit lab results numbers
     */
    public async editLabNumberValues(attachment: Attachment) : Promise<OverlayEventDetail<any>>
    {
        const modal = await this.modalCtrl.create(
        {
            component: NumbersEditPage,
            componentProps:
            {
                attachmentID: attachment.attachmentID,
                patientID: this.user.patient.patientID,
                dt: attachment.dt
            }
        });

        // Display modal
        modal.present();

        // Get response from modal
        return await modal.onDidDismiss();
    }

    /**
     * Manually entered numbers
     */
    public async editManualNumberValues(row?: NumberValueInfo) : Promise<OverlayEventDetail<any>>
    {
        const modal = await this.modalCtrl.create(
        {
            component: NumbersEditPage,
            componentProps:
            {
                patientID: this.user.patient.patientID,
                row: row
            }
        });

        // Display modal
        modal.present();

        // Get response from modal
        return await modal.onDidDismiss();
    }

    /**
     * Manually entered numbers, then route to numbers-report
     */
    public async editManualNumberValuesThenRoute(row?: NumberValueInfo) : Promise<OverlayEventDetail<any>>
    {
        const response = await this.editManualNumberValues(row);

        // Values were edited
        if (response.data)
        {
            // Find any keyID that had a value entered
            const packet: NumberValueUpdate = response.data;
            for (const keyID in packet.values)
            {
                // Value was entered
                if (packet.values[keyID])
                {
                    // Route to graph for this keyID
                    this.router.navigate([ '/provider/numbers-report', keyID ]);
                    return;
                }
            }
        }
        // Route to home if the edit modal was canceled
        else
        {
            this.router.navigate([ '/' ]);
        }

        return response;
    }

    /**
     * Subscribe to number tracking
     */
    public async subscribeNumbers() : Promise<OverlayEventDetail<any>>
    {
        const modal = await this.modalCtrl.create(
        {
            component: NumbersSubscribePage,
            componentProps:
            {
                patientID: this.user.patient.patientID,
            }
        });

        // Display modal
        modal.present();

        // Get response from modal
        return await modal.onDidDismiss();
    }

    //-----------------------------------------------------------------------

    public getMenuItemAdd(action: () => any)
    {
        return {
            title: 'Add',
            icon: 'add-circle',
            action: action
        };
    }

    public getMenuItemEdit(action: () => any)
    {
        return {
            title: 'Edit',
            icon: 'pencil',
            action: action
        };
    }

    public getMenuItemViewAll(action: () => any)
    {
        return {
            title: 'View All',
            icon: 'eye',
            action: action
        };
    }

    public getMenuItemViewAllProviderAppointments(providerID: number)
    {
        return {
            title: 'View All',
            icon: 'eye',
            action: () => this.router.navigate([ '/provider/appointment-list', providerID ])
        };
    }

}
