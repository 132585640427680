/**
 * Default colors for ngx-charts
 */

export interface ColorScheme
{
    domain: string[];
}

export const chartColorScheme: ColorScheme =
{
    // domain: [ '#000080', '#8a0303', '#800080', '#2dd36f', '#ffc409', '#eb445a', '#222428' ]
    // domain: [ '#000080', '#008000', '#800000', '#808000', '#800080', '#008080', '#808080' ]
    // domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
     domain: ['red', 'green', 'blue', 'pink', 'black']
};

export const chartColorSchemeDanger: ColorScheme =
{
    domain: [ '#eb445a' ]
};
