import { NumberKeyInfo } from './NumberKeyInfo';
import { NumberGroupInfo } from './NumberGroupInfo';

export class NumberGroup
{
    group: NumberGroupInfo;
    keys: NumberKeyInfo[] = [ ];

    constructor(group: NumberGroupInfo)
    {
        this.group = group;
    }
}
