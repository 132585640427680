import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '@bones/edit';

import { KpsRest } from '@med/core';
import { NumberKeyInfo } from '@med/provider/class/NumberKeyInfo';
import { NumberKeyService } from '@med/provider/service/number-key';
import { NumberGroupService } from '@med/provider/service/number-group';

@Component({
  templateUrl: 'key-edit.html'
})
export class KeyEditPage extends BonesEditForm<NumberKeyInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private db: NumberKeyService,
        private groupDB: NumberGroupService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'keyID',
                    hidden: true
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(64) ]
                },
                {
                    name: 'groupID',
                    title: 'Group',
                    picker: () => this.groupDB.cache.getPickerMap('name'),
                    validator: Validators.required
                },
                {
                    name: 'units',
                    title: 'Units',
                    validator: Validators.maxLength(16)
                },
                {
                    name: 'rangeLow',
                    title: 'Low Range',
                    validator: Validators.maxLength(16)
                },
                {
                    name: 'rangeHigh',
                    title: 'High Range',
                    validator: Validators.maxLength(16)
                },
                {
                    name: 'url',
                    title: 'URL',
                    validator: Validators.maxLength(256)
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    type: 'textarea'
                }
            ],
            cache: this.db.cache,
            saveUrl: 'numbers/Key.php/updateKey'
        });

        if (this.isAdd)
        {
            this.bonesForm.setValue('groupID', this.launchOptions.moData.groupID);
        }

    }

}
