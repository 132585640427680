import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';
import { KpsRest } from '@med/core';

import { Practitioner } from '../class/Practitioner';
import { PractitionerInfo } from '../class/PractitionerInfo';
import { ProviderService } from './provider';

/**
 * Practitioner info
 */
@Injectable({
  providedIn: 'root',
})
export class PractitionerService
{
    cache: BonesCache<number, PractitionerInfo, Practitioner>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory,
        private providerDB: ProviderService
    )
    {
        this.cache = this.bcf.create<number, PractitionerInfo, Practitioner>(
        {
            pk: 'practitionerID',
            loadCache: () => this.rest.send('provider/Practitioner.php/getPractitioners'),
            reloadOne: (id: number) => this.rest.send('provider/Practitioner.php/getPractitioner', { practitionerID: id }),
            converter: async (info: PractitionerInfo) : Promise<Practitioner> =>
            {
                const practitioner = new Practitioner(info);

                practitioner.provider = await this.providerDB.getProvider(practitioner.providerID);

                return practitioner;
            },
            sorter: (a: Practitioner, b: Practitioner) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single practitioner
     */
    async getPractitioner(practitionerID: number) : Promise<Practitioner>
    {
        return this.cache.getEntry(practitionerID);
    }

    // /**
    //  * Get all practitioners for a provider
    //  */
    // async getPractitionersForProvider(providerID: number) : Promise<Practitioner[]>
    // {
    //     return (await this.practitionerCache.getList()).filter(p => p.providerID === providerID);
    // }

    /**
     * Get picker of practitioners for provider
     */
    async getPractitionerPicker(providerID?: number) : Promise<Map<number, string>>
    {
        if (providerID)
        {
            return this.cache.getPickerMap('name', p => p.providerID === providerID);
        }
        else
        {
            return this.cache.getPickerMap('name');
        }
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class PractitionerFilter
{
    constructor(public rows: Practitioner[])
    {
    }

    byProvider(providerID: number) : PractitionerFilter
    {
        this.rows = this.rows.filter(r => r.provider.providerID === providerID);
        return this;
    }

    byKeyword(keyword: string) : PractitionerFilter
    {
        const lckw = keyword.toLowerCase();

        this.rows = this.rows.filter(r =>
        {
            let match = false;

            match = match || (r.name && r.name.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.speciality && r.speciality.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);

            match = match || (r.provider && r.provider.name.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.provider && r.provider.notes && r.provider.notes.toLowerCase().indexOf(lckw) >= 0);

            return match;
        });

        return this;
    }
}
