import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { AuthGuard } from '@med/core';

import { DrugTypeListPage } from './pages/drug-type-list/drug-type-list';
import { DrugstoreListPage } from './pages/drugstore-list/drugstore-list';

/**
 * Routes for pages.
 */
const routes: Route[] =
[
    {
        path: 'admin',
        canActivate: [ AuthGuard ],
        children:
        [
            { path: 'drugstore-list', component: DrugstoreListPage },
            { path: 'drug-type-list', component: DrugTypeListPage },
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forChild(routes),
    ],
    exports:
    [
        RouterModule
    ]
})
export class RoutingModule
{
}
