import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { KpsRest, UserService } from '@med/core';

import { NumberValueInfo } from '../class/NumberValueInfo';
import { NumberValueUpdate } from '../class/NumberValueUpdate';


/**
 * Access number value history
 */
@Injectable({
  providedIn: 'root',
})
export class NumberValueService
{

    constructor(
        private rest: KpsRest,
        private mtus: UserService,
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Get history for a given key and patient
     */
    async getValues(patientID: number, keyID: number) : Promise<NumberValueInfo[]>
    {
        // const user = this.mtus.getUser(); // user.patient.patientID

        return this.rest.send('numbers/Value.php/getValues', { patientID, keyID })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberValueService',
                methodName: 'getValues',
                message: 'unable to get values',
                error: error
            })
            .add({ keyID, patientID });
        });
    }

    /**
     * Get values for a given lab result
     */
    async getValuesForAttachment(attachmentID: number) : Promise<NumberValueInfo[]>
    {
        return this.rest.send('numbers/Value.php/getValuesForAttachment', { attachmentID })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberValueService',
                methodName: 'getValuesForAttachment',
                message: 'unable to get values',
                error: error
            })
            .add({ attachmentID });
        });
    }

    /**
     * Get values for manually entered values at a specific timestamp
     */
    async getValuesForDate(dt: string) : Promise<NumberValueInfo[]>
    {
        return this.rest.send('numbers/Value.php/getValuesForDate', { dt })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberValueService',
                methodName: 'getValuesForDate',
                message: 'unable to get values',
                error: error
            })
            .add({ dt });
        });
    }

    /**
     * Get value map (keyID => value) for a given lab result
     */
    async getValueMapForAttachment(attachmentID: number) : Promise<Map<number, number>>
    {
        return this.getValuesForAttachment(attachmentID)
        .then(rows =>
        {
            return new Map(rows.map(r => [ r.keyID, r.value ]));
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberValueService',
                methodName: 'getValueMapForAttachment',
                message: 'unable to get values',
                error: error
            })
            .add({ attachmentID });
        });
    }

    /**
     * Get value map (keyID => value) for manually entered values at a specific timestamp
     */
    async getValueMapForDate(dt: string) : Promise<Map<number, number>>
    {
        return this.getValuesForDate(dt)
        .then(rows =>
        {
            return new Map(rows.map(r => [ r.keyID, r.value ]));
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberValueService',
                methodName: 'getValueMapForDate',
                message: 'unable to get values',
                error: error
            })
            .add({ dt });
        });
    }

    /**
     * Update values
     */
    async updateValuesForAttachment(packet: NumberValueUpdate) : Promise<void>
    {
        return this.rest.send('numbers/Value.php/updateValuesForAttachment', packet)
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberValueService',
                methodName: 'updateValuesForAttachment',
                message: 'unable to update values',
                error: error
            })
            .add({ packet });
        });
    }

    /**
     * Update values
     */
    async updateManualValues(packet: NumberValueUpdate) : Promise<void>
    {
        return this.rest.send('numbers/Value.php/updateManualValues', packet)
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberValueService',
                methodName: 'updateManualValues',
                message: 'unable to update values',
                error: error
            })
            .add({ packet });
        });
    }

}
