import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService } from '@bones/core';

import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { AttachmentService } from '@med/provider/service/attachment';
import { Attachment } from '@med/provider/class/Attachment';

@Component({
    selector: 'page-attachment-detail',
    templateUrl: 'attachment-detail.html'
})
export class AttachmentDetailPage implements OnInit
{
    attachmentID: number;
    attachment: Attachment;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private es: BonesErrorService,
        private launch: LaunchEditModalService,
        private attachmentDB: AttachmentService
    )
    {
        this.attachmentID = +this.route.snapshot.params.attachmentID;
    }

    ngOnInit()
    {
        this.attachmentDB.getAttachment(this.attachmentID)
        .then(row => this.attachment = row)
        .catch(error => this.es.errorHandler(error));
    }

    async edit()
    {
        const results = await this.launch.editAttachment(this.attachment);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }

    async view()
    {
        this.launch.viewAttachment(this.attachment);
    }
}
