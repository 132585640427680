import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService } from '@bones/core';

import { Appointment } from '@med/provider/class/Appointment';
import { AppointmentService } from '@med/provider/service/appointment';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';

@Component({
    selector: 'page-appointment-detail',
    templateUrl: 'appointment-detail.html'
})
export class AppointmentDetailPage implements OnInit
{
    appointmentID: number;
    appointment: Appointment;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private es: BonesErrorService,
        private launch: LaunchEditModalService,
        private appointmentDB: AppointmentService
    )
    {
        this.appointmentID = +this.route.snapshot.params.appointmentID;
    }

    ngOnInit()
    {
        this.appointmentDB.getAppointment(this.appointmentID)
        .then(row => this.appointment = row)
        .catch(error => this.es.errorHandler(error));
    }

    async edit(row: Appointment)
    {
        const results = await this.launch.editAppointment(row);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }

    copy(row: Appointment)
    {
        this.launch.copyAppointment(row);
    }
}
