import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService } from '@bones/core';
import { BonesForm } from '@bones/form';
import { UserService, AppUser } from '@med/core';

import { Appointment } from '@med/provider/class/Appointment';
import { AppointmentService, AppointmentFilter } from '@med/provider/service/appointment';
import { SearchFilters } from '@med/provider/class/SearchFilters';
import { SearchFiltersService } from '@med/provider/service/search-filter';
import { SearchFiltersComponent } from '@med/provider/component/search-filters/search-filters';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';

@Component({
    selector: 'page-appointment-list',
    templateUrl: 'appointment-list.html'
})
export class AppointmentListPage implements OnInit, OnDestroy
{
    public rows: Appointment[];
    public upcoming: Appointment[];
    public past: Appointment[];
    user: AppUser;
    nowAndLaterCleanup: () => void;
    useControls = [ 'keyword', 'patientID', 'providerID', 'practitionerID', 'isActive' ]; // 'beginDate', 'endDate'
    defaults: SearchFilters;
    @ViewChild('filters', {static: false}) filters: SearchFiltersComponent;

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        private mtus: UserService,
        private sfs: SearchFiltersService,
        private launch: LaunchEditModalService,
        private appdb: AppointmentService
    )
    {
        // Setup filter defaults
        this.defaults = this.sfs.buildDefaults(this.route.snapshot.params);
    }

    ngOnInit()
    {
        this.user = this.mtus.getUser();

        this.nowAndLaterCleanup = this.appdb.cache.nowAndLater(
        rows =>
        {
            this.rows = rows;

            // Kickoff search if the filters are ready
            if (this.filters)
            {
                this.doSearch(this.filters.getValues());
            }
        },
        error => this.es.errorHandler(error));
    }

    ngOnDestroy()
    {
        this.nowAndLaterCleanup();
    }

    async doReady(form: BonesForm)
    {
        form.setValue('isActive', true);
        this.doSearch(form.getValues());
    }

    async doSearch(values: SearchFilters)
    {
        if (!this.rows)
        {
            return;
        }

        const ap = new AppointmentFilter(this.rows);

        if (values.keyword)
        {
            ap.byKeyword(values.keyword);
        }

        if (values.patientID)
        {
            ap.byPatient(values.patientID);
        }

        if (values.providerID)
        {
            ap.byProvider(values.providerID);
        }

        if (values.practitionerID)
        {
            ap.byPractitioner(values.practitionerID);
        }

        this.upcoming = ap.rows.filter(r => r.isFuture);
        this.past = (values.isActive) ? [ ] : ap.rows.filter(r => !r.isFuture);
    }

    add()
    {
        this.launch.addAppointment(this.filters.getValues());
    }
}
