import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { PractitionerService } from '@med/provider/service/practitioner';
import { ProviderService } from '@med/provider/service/provider';
import { NoteInfo } from '@med/provider/class/NoteInfo';
import { NoteService } from '@med/provider/service/note';
import { DrugService } from '@med/provider/service/drug';
import { PrescriptionService } from '@med/provider/service/drug-script';

@Component({
    selector: 'note-edit',
    templateUrl: 'note-edit.html'
})
export class NoteEditPage extends BonesEditForm<NoteInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private mtus: UserService,
        private noteDB: NoteService,
        private providerDB: ProviderService,
        private practitionerDB: PractitionerService,
        private drugDB: DrugService,
        private scriptDB: PrescriptionService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'noteID',
                    hidden: true
                },
                {
                    name: 'patientID',
                    title: 'Patient',
                    picker: this.mtus.getPatientPicker(),
                    validator: Validators.required
                },
                {
                    name: 'providerID',
                    title: 'Provider',
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.bonesForm.setValue('practitionerID', undefined);
                    }
                },
                {
                    name: 'practitionerID',
                    title: 'Practitioner',
                    picker:
                    {
                        populator: () => this.practitionerDB.getPractitionerPicker(this.bonesForm.getValue('providerID')),
                        nocache: true
                    }
                },
                {
                    name: 'drugID',
                    title: 'Drug',
                    picker: () => this.drugDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.bonesForm.setValue('scriptID', undefined);
                    }
                },
                {
                    name: 'scriptID',
                    title: 'Prescription',
                    picker:
                    {
                        populator: () => this.scriptDB.getPrescriptionPicker(this.bonesForm.getValue('drugID')),
                        nocache: true
                    }
                },
                {
                    name: 'dt',
                    title: 'Date',
                    date: 'yyyy-MM-dd HH:mm',
                    initialValue: new Date(),
                    validator: Validators.required
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    // hideTitle: true,
                    textarea: 8,
                    validator: Validators.required
                }
            ],
            saveUrl: '/provider/Note.php/updateNote',
            cache: this.noteDB.cache
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue('patientID', this.launchOptions.moData.patientID);
            this.bonesForm.setValue('providerID', this.launchOptions.moData.providerID);
            this.bonesForm.setValue('practitionerID', this.launchOptions.moData.practitionerID);
            this.bonesForm.setValue('drugID', this.launchOptions.moData.drugID);
            this.bonesForm.setValue('scriptID', this.launchOptions.moData.scriptID);
        }
    }
}
