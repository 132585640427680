import { NoteInfo } from './NoteInfo';
import { Practitioner } from './Practitioner';
import { Provider } from './Provider';
import { Appointment } from './Appointment';
import { PatientInfo } from '@med/core';
import { Prescription } from './Prescription';
import { Drug } from './Drug';

export class Note
{
    // Info for actual notes
    noteID: number;
    info: NoteInfo;

    // Common across all note types
    dt: Date;
    patientID: number;
    notes: string;
    patient: PatientInfo;

    // Additional optional info based upon note type
    practitioner: Practitioner;
    provider: Provider;
    appointment: Appointment;
    drug: Drug;
    script: Prescription;

    constructor(dt: string, patientID: number, notes: string)
    {
        this.dt = new Date(dt);
        this.patientID = patientID;
        this.notes = notes;
    }
}
