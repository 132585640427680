import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { BonesSideMenuAction } from '@bones/core';
import { BonesErrorService } from '@bones/core';
import { PostErrorService } from '@bones/network';

import { UserService, AppUser } from '@med/core';
import { KpsRest } from '@med/core';

/**
 * App component is the main entry point for the application.
 */
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent
{
    actions: BonesSideMenuAction[] = [ ];

    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        private splashScreen: SplashScreen,
        private es: BonesErrorService,
        private pes: PostErrorService,
        private mtus: UserService,
        private rest: KpsRest
    )
    {
        this.platform.ready().then(() =>
        {
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            this.init();
            this.initSideMenu();
        });
    }

    async init()
    {
        // Post errors to server
        // this.pes.url = '/user/logClientError';
        // this.es.subscribe(error => this.pes.log2db(error));

        this.rest.serverUrl = 'https://rest.med.monkey.kpstuff.com';
    }

    private initSideMenu()
    {
        // Subscribe to user changes so menu items can be adjusted based upon user access
        this.mtus.userChange.subscribe((user: AppUser) =>
        {
            // Build new array locally
            const actions: BonesSideMenuAction[] = [ ];

            // Actions available to all users
            this.pushy(true, actions, { title: 'Home', path: '/', action: 'root' });
            this.pushy(true, actions, { title: 'Search', path: '/search', action: 'root' });
            this.pushy(true, actions, { title: 'My Doctors', path: '/provider/my-doctors', action: 'root' });
            this.pushy(true, actions, { title: 'My Drugs & Devices', path: '/provider/my-drugs', action: 'root' });
            this.pushy(true, actions, { title: 'My Charts', path: '/provider/numbers-report', action: 'root' });
            this.pushy(true, actions, { title: 'My Vaccinations', path: '/provider/my-vaccinations', action: 'root' });

            // Advanced Search
            this.pushy(true, actions,
            {
                group: 'Advanced Search',
                title: 'Providers',
                path: '/provider/provider-list',
                action: 'root'
            });
            this.pushy(true, actions,
            {
                group: 'Advanced Search',
                title: 'Appointments',
                path: '/provider/appointment-list',
                action: 'root'
            });
            this.pushy(true, actions,
            {
                group: 'Advanced Search',
                title: 'Notes',
                path: '/provider/notes-list',
                action: 'root'
            });
            this.pushy(true, actions,
            {
                group: 'Advanced Search',
                title: 'Test Results',
                path: '/provider/test-results-list',
                action: 'root'
            });
            this.pushy(true, actions,
            {
                group: 'Advanced Search',
                title: 'Attachments',
                path: '/provider/attachment-list',
                action: 'root'
            });
            this.pushy(true, actions,
            {
                group: 'Advanced Search',
                title: 'Drugs',
                path: '/provider/drug-list',
                action: 'root'
            });
            this.pushy(true, actions,
            {
                group: 'Advanced Search',
                title: 'Drug Usage',
                path: '/provider/drug-use-list',
                action: 'root'
            });

            // Admin
            this.pushy(true, actions,
            {
                group: 'Admin',
                title: 'TR Groups and Keys',
                path: '/provider/numbers-maint',
                action: 'push'
            });
            this.pushy(true, actions,
            {
                group: 'Admin',
                title: 'Drugstore',
                path: '/admin/drugstore-list',
                action: 'push'
            });
            this.pushy(true, actions,
            {
                group: 'Admin',
                title: 'Drug Type',
                path: '/admin/drug-type-list',
                action: 'push'
            });

            // Testing
            // this.pushy(user.hasAccess('APP_TT'), actions,
            this.pushy(true, actions,
            {
                group: 'Testing',
                title: 'Test Tools',
                path: '/bones/test-tools',
                action: 'push'
            });

            // The assignment of the new actions array is what triggers updating the menu
            this.actions = actions;
        });
    }

    private pushy(doPush: boolean, actions: BonesSideMenuAction[], action: BonesSideMenuAction)
    {
        if (doPush)
        {
            actions.push(action);
        }
    }

}
