<ion-toolbar>
  <ion-card-title *ngIf="title">{{ title }}</ion-card-title>
  <ion-card-title *ngIf="!title"><ng-content></ng-content></ion-card-title>
  <ion-card-subtitle *ngIf="subtitle">{{ subtitle }}</ion-card-subtitle>
  <ion-button class="ion-no-margin" slot="end" size="small" fill="clear" color="secondary"
    (click)="toggleMenu()"
    *ngIf="actions?.length > 0">
    <ion-icon name="menu" size="large"></ion-icon>
  </ion-button>
</ion-toolbar>
<div class="dropdown ion-margin-end" [hidden]="!menuVisible" #mm (click)="hideMenu()" *ngIf="actions">
  <ion-item lines="none" button="true" (click)="do(action)" *ngFor="let action of actions">
    <ion-label>{{ action.title }}</ion-label>
    <ion-icon slot="start" [name]="action.icon" color="primary" *ngIf="action.icon"></ion-icon>
  </ion-item>
</div>