import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory, BonesError } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { NumberUsageInfo } from '../class/NumberUsageInfo';
import { NumberUsage } from '../class/NumberUsage';
import { NumberGroupService } from './number-group';
import { NumberKeyService } from './number-key';
import { GroupedUsage } from '../class/GroupedUsage';
import { NumberGroup } from '../class/NumberGroup';

/**
 * Numbers - subscription info
 */
@Injectable({
  providedIn: 'root',
})
export class NumberUsageService
{
    cache: BonesCache<number, NumberUsageInfo, NumberUsage>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory,
        private mtus: UserService,
        private groupDB: NumberGroupService,
        private keyDB: NumberKeyService
    )
    {
        this.cache = this.bcf.create<number, NumberUsageInfo, NumberUsage>(
        {
            pk: 'usageID',
            loadCache: () => this.rest.send('numbers/Usage.php/getUsage'),
            reloadOne: (id: number) => this.rest.send('numbers/Usage.php/getUsage1', { usageID: id }),
            converter: async (info: NumberUsageInfo) : Promise<NumberUsage> =>
            {
                const usage = new NumberUsage(info);

                usage.patient = await this.mtus.getPatient(usage.patientID);
                usage.key = await this.keyDB.getKey(usage.keyID);
                usage.group = await this.groupDB.getGroup(usage.key.groupID);

                return usage;
            },
            sorter: (a: NumberUsage, b: NumberUsage) =>
            {
                // Primary sort on group
                let compare = a.group.name.localeCompare(b.group.name);

                // Secondary sort on sort order or name
                if (!compare)
                {
                    if (a.key.sortOrder && !b.key.sortOrder)
                    {
                        compare = -1;
                    }
                    else if (!a.key.sortOrder && b.key.sortOrder)
                    {
                        compare = 1;
                    }
                    else if (a.key.sortOrder && b.key.sortOrder)
                    {
                        compare = a.key.sortOrder - b.key.sortOrder;
                    }
                    else
                    {
                        compare = a.key.name.localeCompare(b.key.name);
                    }
                }

                return compare;
            }
        });
    }

    /**
     * Get single row
     */
    async getUsage(usageID: number) : Promise<NumberUsage>
    {
        return this.cache.getEntry(usageID);
    }

    /**
     * Get filterable usage
     */
    async getFilteredUsage() : Promise<UsageFilter>
    {
        return new UsageFilter(await this.cache.getList());
    }

    //-----------------------------------------------------------------------

    /**
     * Get all groups with keys
     */
    async getAllGroups() : Promise<NumberGroup[]>
    {
        const groups: NumberGroup[] = [ ];
        const map = new Map<number, NumberGroup>();

        // Create NumberGroup[]
        (await this.groupDB.cache.getList()).forEach(group =>
        {
            const numberGroup = new NumberGroup(group);
            groups.push(numberGroup);
            map.set(group.groupID, numberGroup);
        });

        // Assign keys to groups
        (await this.keyDB.cache.getList()).forEach(key => map.get(key.groupID).keys.push(key));

        return groups;
    }

    //-----------------------------------------------------------------------

    /**
     * Update subscription
     */
    async updateSubscription(packet) : Promise<number[]>
    {
        return this.rest.send('numbers/Usage.php/updateSubscription', packet)
        .then(async (payload: number[]) =>
        {
            // Delete old entries from cache
            (await this.cache.getList())
            .filter(u => u.patientID === packet.patientID)
            .forEach(u => this.cache.deleted(u.usageID));

            // Add new entries to cache
            payload.forEach(usageID => this.cache.updated(usageID));

            return payload;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'NumberUsageService',
                methodName: 'updateSubscription',
                message: 'unable to update subscription',
                error: error
            })
            .add({ packet });
        });
    }

    //-----------------------------------------------------------------------

    /**
     * A group has been deleted which may affect the cache via cascading deletes
     */
    async groupWasDeleted(groupID: number)
    {
        // Find any cached usage for the deleted group and delete the usage from the cache
        (await this.getFilteredUsage())
        .byGroup(groupID)
        .rows
        .forEach(u => this.cache.deleted(u.usageID));
    }

    /**
     * A key has been deleted which may affect the cache via cascading deletes
     */
    async keyWasDeleted(keyID: number)
    {
        // Find any cached usage for the deleted key and delete the usage from the cache
        (await this.getFilteredUsage())
        .byKey(keyID)
        .rows
        .forEach(u => this.cache.deleted(u.usageID));
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class UsageFilter
{
    constructor(public rows: NumberUsage[])
    {
    }

    byPatient(patientID: number) : UsageFilter
    {
        this.rows = this.rows.filter(r => r.patientID === patientID);
        return this;
    }

    byGroup(groupID: number) : UsageFilter
    {
        this.rows = this.rows.filter(r => r.group.groupID === groupID);
        return this;
    }

    byKey(keyID: number) : UsageFilter
    {
        this.rows = this.rows.filter(r => r.keyID === keyID);
        return this;
    }

    byLabResults(isLabResult: boolean) : UsageFilter
    {
        this.rows = this.rows.filter(r => r.isLabResult === isLabResult);
        return this;
    }

    byKeyword(keyword: string) : UsageFilter
    {
        const lckw = keyword.toLowerCase();

        this.rows = this.rows.filter(r =>
        {
            let match = false;

            match = match || (r.group.name && r.group.name.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.group.notes && r.group.notes.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.key.name && r.key.name.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.key.notes && r.key.notes.toLowerCase().indexOf(lckw) >= 0);

            return match;
        });

        return this;
    }

    group() : GroupedUsage[]
    {
        let groupID: number;
        let gu: GroupedUsage;
        const guss: GroupedUsage[] = [ ];

        this.rows.forEach(usage =>
        {
            if (usage.group.groupID !== groupID)
            {
                groupID = usage.group.groupID;
                gu = new GroupedUsage(usage.group);
                guss.push(gu);
            }

            gu.usage.push(usage);
        });

        return guss;
    }

}
