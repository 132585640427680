import { Component, Input, OnInit } from '@angular/core';

import { UserService, AppUser } from '@med/core';
import { Refill } from '@med/provider/class/Refill';

@Component({
    selector: 'refill-list-card',
    templateUrl: 'refill-list-card.html'
})
export class RefillListCardComponent implements OnInit
{
    @Input() refills: Refill[];
    @Input() title: string;

    user: AppUser;

    constructor(
        private mtus: UserService
    )
    {
    }

    async ngOnInit()
    {
        this.user = this.mtus.getUser();
    }

}
