import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormControl } from '@angular/forms';

import { BonesErrorService } from '@bones/core';
import { UserService, AppUser } from '@med/core';

import { NumberUsageService } from '@med/provider/service/number-usage';
import { GroupedUsage } from '@med/provider/class/GroupedUsage';
import { NumberGroup } from '@med/provider/class/NumberGroup';

@Component({
    selector: 'numbers-subscribe',
    templateUrl: 'numbers-subscribe.html'
})
export class NumbersSubscribePage implements OnInit
{
    @Input() patientID: number;
    user: AppUser;
    numberGroups: NumberGroup[];
    groupedUsage: GroupedUsage[];
    controls: Map<number, UntypedFormControl>;

    constructor(
        protected modalCtrl: ModalController,
        private es: BonesErrorService,
        private mtus: UserService,
        private usedb: NumberUsageService
    )
    {
        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {
        // Load usage and values to create form
        try
        {
            // Get all groups
            this.numberGroups = await this.usedb.getAllGroups();

            // Create a map for form controls keyed on keyID
            this.controls = new Map<number, UntypedFormControl>();

            // Create form controls
            this.numberGroups.forEach(ng => ng.keys.forEach(key =>
            {
                const control = new UntypedFormControl();
                this.controls.set(key.keyID, control);
            }));

            // Set toggles for existing subscriptions
            (await this.usedb.getFilteredUsage())
            .byPatient(this.user.patient.patientID)
            .rows
            .forEach(usage =>
            {
                this.controls.get(usage.keyID).setValue(true);
            });
        }
        catch (error)
        {
            this.es.errorHandler(error);
        }
    }

    cancel()
    {
        this.modalCtrl.dismiss(false);
    }

    save()
    {
        // Create packet to send to server
        const packet =
        {
            patientID: this.patientID,
            keys: [ ]
        };

        // Populate packet with selected keys
        this.controls.forEach((formControl: UntypedFormControl, keyID: number) =>
        {
            if (formControl.value)
            {
                packet.keys.push(keyID);
            }
        });

        // Save changes to back end
        this.usedb.updateSubscription(packet)
        .then(() =>
        {
            this.modalCtrl.dismiss(true);
        })
        .catch(error => this.es.errorHandler(error));
    }

}
