import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest } from '@med/core';

import { DrugTypeService } from './drug-type';
import { DrugInfo } from '../class/DrugInfo';
import { Drug } from '../class/Drug';

/**
 * Drug usage info
 */
@Injectable({
  providedIn: 'root',
})
export class DrugService
{
    cache: BonesCache<number, DrugInfo, Drug>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory,
        private drugTypeDB: DrugTypeService
    )
    {
        this.cache = this.bcf.create<number, DrugInfo, Drug>(
        {
            pk: 'drugID',
            loadCache: () => this.rest.send('drug/Drug.php/getDrugs'),
            reloadOne: (id: number) => this.rest.send('drug/Drug.php/getDrug', { drugID: id }),
            converter: async (info: DrugInfo) : Promise<Drug> =>
            {
                const drug = new Drug(info);

                drug.type = await this.drugTypeDB.getDrugTypeInfo(drug.drugTypeID);

                return drug;
            },
            sorter: (a: Drug, b: Drug) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single row
     */
    async getDrug(drugID: number) : Promise<Drug>
    {
        return this.cache.getEntry(drugID);
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class DrugFilter
{
    constructor(public rows: Drug[])
    {
    }

    byDrug(drugID: number) : DrugFilter
    {
        this.rows = this.rows.filter(r => r.drugID === drugID);
        return this;
    }

    byDrugType(drugTypeID: number) : DrugFilter
    {
        this.rows = this.rows.filter(r => r.drugTypeID === drugTypeID);
        return this;
    }

    byKeyword(keyword: string) : DrugFilter
    {
        const lckw = keyword.toLowerCase();

        this.rows = this.rows.filter(r =>
        {
            let match = false;

            match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.brandName && r.brandName.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.genericName && r.genericName.toLowerCase().indexOf(lckw) >= 0);

            return match;
        });

        return this;
    }
}
