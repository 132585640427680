import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { BonesForm } from '@bones/form';

import { SearchFilters } from '../../class/SearchFilters';
import { ProviderService } from '@med/provider/service/provider';
import { PractitionerService } from '@med/provider/service/practitioner';
import { UserService } from '@med/core';
import { AttachmentTypeService } from '@med/provider/service/attachmentType';
import { DrugService } from '@med/provider/service/drug';
import { DrugTypeService } from '@med/provider/service/drug-type';
import { PrescriptionService } from '@med/provider/service/drug-script';

@Component({
    selector: 'search-filters',
    templateUrl: 'search-filters.html'
})
export class SearchFiltersComponent implements OnInit
{
    @Input() useControls: string[];
    @Input() defaults: SearchFilters = { };
    @Output() controlChange = new EventEmitter<SearchFilters>();
    @Output() ready = new EventEmitter<BonesForm>();
    form: BonesForm;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private mtus: UserService,
        private drugDB: DrugService,
        private drugTypeDB: DrugTypeService,
        private scriptDB: PrescriptionService,
        private providerDB: ProviderService,
        private practitionerDB: PractitionerService,
        private attachmentTypeDB: AttachmentTypeService
    )
    {
    }

    async ngOnInit()
    {
        // Filters
        this.form = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'beginDate',
                    title: 'Begin Date',
                    hidden: true,
                    type: 'date',
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'endDate',
                    title: 'End Date',
                    hidden: true,
                    type: 'date',
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'keyword',
                    title: 'Keyword',
                    hidden: true,
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'patientID',
                    title: 'Patient',
                    hidden: true,
                    picker: this.mtus.getPatientPicker(),
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'typeID',
                    title: 'Attachment Type',
                    hidden: true,
                    picker: () => this.attachmentTypeDB.cache.getPickerMap('name'),
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'providerID',
                    title: 'Provider',
                    hidden: true,
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.form.setValue('practitionerID', undefined);
                        this.onControlChange();
                    }
                },
                {
                    name: 'practitionerID',
                    title: 'Practitioner',
                    hidden: true,
                    picker:
                    {
                        populator: () => this.practitionerDB.getPractitionerPicker(this.form.getValue('providerID')),
                        nocache: true
                    },
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'testProviderID',
                    title: 'Test Provider',
                    hidden: true,
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'drugID',
                    title: 'Drug',
                    hidden: true,
                    picker: () => this.drugDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.form.setValue('scriptID', undefined);
                        this.onControlChange();
                    }
                },
                {
                    name: 'drugTypeID',
                    title: 'Drug Type',
                    hidden: true,
                    picker: () => this.drugTypeDB.cache.getPickerMap('name'),
                    onChange: () =>
                    {
                        this.form.setValue('drugID', undefined);
                        this.onControlChange();
                    }
                },
                {
                    name: 'scriptID',
                    title: 'Script',
                    hidden: true,
                    picker:
                    {
                        populator: () => this.scriptDB.getPrescriptionPicker(this.form.getValue('drugID')),
                        nocache: true
                    },
                    onChange: () => this.onControlChange()
                },
                {
                    name: 'isActive',
                    title: 'Active Only',
                    hidden: true,
                    toggle: { on: true, off: false },
                    onChange: () => this.onControlChange()
                }
            ]
        });

        // Show controls used by this report
        this.showControls(this.useControls);

        // Clear controls and set defualts
        this.reset();

        // Send ready event
        this.ready.emit(this.form);
    }

    // Show controls used by this report
    showControls(useControls: string[])
    {
        useControls.forEach(c => this.form.getItem(c).hidden = false);
    }

    setDefaults()
    {
        if (this.defaults.beginDate)
        {
            this.form.setValue('beginDate', this.defaults.beginDate);
        }

        if (this.defaults.endDate)
        {
            this.form.setValue('endDate', this.defaults.endDate);
        }

        if (this.defaults.patientID)
        {
            this.form.setValue('patientID', this.defaults.patientID);
        }

        if (this.defaults.drugID)
        {
            this.form.setValue('drugID', this.defaults.drugID);
        }

        if (this.defaults.scriptID)
        {
            this.form.setValue('scriptID', this.defaults.scriptID);
        }

        if (this.defaults.providerID)
        {
            this.form.setValue('providerID', this.defaults.providerID);
        }

        if (this.defaults.practitionerID)
        {
            this.form.setValue('practitionerID', this.defaults.practitionerID);
        }

        if (this.defaults.testProviderID)
        {
            this.form.setValue('testProviderID', this.defaults.testProviderID);
        }

        if (this.defaults.typeID)
        {
            this.form.setValue('typeID', this.defaults.typeID);
        }

        if (this.defaults.drugTypeID)
        {
            this.form.setValue('drugTypeID', this.defaults.drugTypeID);
        }

        if (this.defaults.isActive)
        {
            this.form.setValue('isActive', this.defaults.isActive);
        }
    }

    reset()
    {
        this.form.resetValues();
        this.setDefaults();
        this.onControlChange();
    }

    onControlChange()
    {
        this.controlChange.emit(this.form.getValues());
    }

    getValues() : any
    {
        return this.form.getValues();
    }

}
