import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { BonesCoreModule, BonesTestToolsPiglets } from '@bones/core';
import { BonesNetworkModule } from '@bones/network';

import { AppRoutingModule } from './routing.module';

import { MedPageHeaderComponent } from './component/med-page-header/med-page-header';
import { MedPageFooterComponent } from './component/med-page-footer/med-page-footer';
import { MedModalHeaderComponent } from './component/med-modal-header/med-modal-header';

import { LoginPage } from './pages/login/login';
import { NotAuthorizedPage } from './pages/not-authorized/not-authorized';
import { TestToolsUserPage } from './pages/test-tools-user/test-tools-user';

@NgModule({
    declarations: [
        LoginPage,
        NotAuthorizedPage,
        TestToolsUserPage,
        MedPageHeaderComponent,
        MedPageFooterComponent,
        MedModalHeaderComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule, ReactiveFormsModule,
        IonicModule,
        BonesCoreModule,
        BonesNetworkModule,
        AppRoutingModule
    ],
    // Use for services that are referenced only within this module
    providers: [],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: [
        MedPageHeaderComponent,
        MedPageFooterComponent,
        MedModalHeaderComponent
    ]
})
export class AppCoreModule
{
    static initialize()
    {
        BonesTestToolsPiglets.tools.push(
        {
            title: 'User',
            icon: 'person',
            path: 'test-tools/app/user'
        });
    }
}
AppCoreModule.initialize();
