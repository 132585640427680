import { Component, OnInit } from '@angular/core';

import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';

// http://localhost:8100/#provider/numbers-add-deep-link
@Component({})
export class NumbersAddDeepLinkPage implements OnInit
{
    constructor(
        private launch: LaunchEditModalService
    )
    {
    }

    async ngOnInit()
    {
        this.launch.editManualNumberValuesThenRoute();
    }

}
