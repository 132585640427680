import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService, BonesNavService } from '@bones/core';

import { Note } from '@med/provider/class/Note';
import { NoteService } from '@med/provider/service/note';
import { SearchFilters } from '@med/provider/class/SearchFilters';
import { SearchFiltersComponent } from '@med/provider/component/search-filters/search-filters';
import { BonesForm } from '@bones/form';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { SearchFiltersService } from '@med/provider/service/search-filter';

@Component({
    selector: 'page-notes-list',
    templateUrl: 'notes-list.html'
})
export class NotesListPage implements OnInit, OnDestroy
{
    rows: Note[] = [ ];
    filtered: Note[];
    useControls = [ 'beginDate', 'endDate', 'keyword', 'patientID', 'providerID', 'practitionerID', 'drugID', 'scriptID' ];
    defaults: SearchFilters;
    @ViewChild('filters', {static: false}) filters: SearchFiltersComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private es: BonesErrorService,
        private bnav: BonesNavService,
        private sfs: SearchFiltersService,
        private launch: LaunchEditModalService,
        private db: NoteService
    )
    {
        // Refresh data when navigating back to this page from the detail page
        this.bnav.onWatchBack(this, () => this.getData());

        // Setup filter defaults
        this.defaults = this.sfs.buildDefaults(this.route.snapshot.params);
    }

    async ngOnInit()
    {
        this.getData();
    }

    ngOnDestroy()
    {
        // Unsubscribe to navigation events
        this.bnav.stopWatchBack(this);
    }

    async doReady(form: BonesForm)
    {
        // console.log('doReady', form, this.filters);
    }

    async getData()
    {
        // Get all note information
        await this.db.getAllNotes()
        .then(rows => this.rows = rows)
        .catch(error => this.es.errorHandler(error));

        // Filter notes per current settings
        this.doSearch(this.filters.getValues());
    }

    async doSearch(values: SearchFilters)
    {
        let tobe = this.rows;

        if (values.keyword)
        {
            const lckw = values.keyword.toLowerCase();
            tobe = tobe.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.provider && r.provider.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.practitioner && r.practitioner.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.drug && r.drug.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.script && r.script.productName.toLowerCase().indexOf(lckw) >= 0);

                return match;
            });
        }

        if (values.patientID)
        {
            tobe = tobe.filter(r => r.patientID === values.patientID);
        }

        if (values.providerID)
        {
            tobe = tobe.filter(r => r.provider?.providerID === values.providerID);
        }

        if (values.practitionerID)
        {
            tobe = tobe.filter(r => r.practitioner?.practitionerID === values.practitionerID);
        }

        if (values.drugID)
        {
            tobe = tobe.filter(r => r.drug?.drugID === values.drugID);
        }

        if (values.scriptID)
        {
            tobe = tobe.filter(r => r.script?.scriptID === values.scriptID);
        }

        this.filtered = tobe;
    }

    async onClick(note: Note)
    {
        if (note.info)
        {
            this.router.navigate([ '/provider/notes-detail', note.noteID ]);
        }
        else if (note.appointment)
        {
            this.router.navigate([ '/provider/appointment-detail', note.appointment.appointmentID ]);
        }
    }

    async addNote()
    {
        const results = await this.launch.addNote(this.filters.getValues());

        if (results.action !== 'cancel')
        {
            this.getData();
        }
    }
}
