import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';
import { KpsRest } from '@med/core';

import { ProviderLocationInfo } from '../class/ProviderLocationInfo';

/**
 * Provider info
 */
@Injectable({
  providedIn: 'root',
})
export class ProviderLocationService
{
    cache: BonesCache<number, ProviderLocationInfo, ProviderLocationInfo>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory
    )
    {
        this.cache = this.bcf.create<number, ProviderLocationInfo, ProviderLocationInfo>(
        {
            pk: 'locationID',
            loadCache: () => this.rest.send('provider/Provider.php/getProviderLocations'),
            reloadOne: (id: number) => this.rest.send('provider/Provider.php/getProviderLocation', { locationID: id }),
            converter: async (info: ProviderLocationInfo) : Promise<ProviderLocationInfo> => info,
            sorter: (a: ProviderLocationInfo, b: ProviderLocationInfo) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get location
     */
    async getLocation(locationID: number) : Promise<ProviderLocationInfo>
    {
        return this.cache.getEntry(locationID);
    }

    /**
     * Get locations for a given provider
     */
    async getLocationsForProvider(providerID: number) : Promise<ProviderLocationInfo[]>
    {
        return (await this.cache.getList()).filter(p => p.providerID === providerID);
    }

    /**
     * Get picker for provider's location
     */
    async getLocationPicker(providerID: number) : Promise<Map<number, string>>
    {
        return this.cache.getPickerMap('name', p => p.providerID === providerID);
    }

}
