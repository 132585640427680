/**
 * Search a list of objects for keyword values
 */
export class BonesSearch<T>
{
    private words: string[];

    /**
     * Construct searcher
     * 
     * @param rows rows to search
     * @param phrase one or more space separated words; row must match all individual words
     */
    public constructor(private rows: T[], phrase: string)
    {
        // Split phrase into words
        this.words = phrase.toLowerCase().trim().split(/\s+/);
        // console.log('words', this.words);
    }

    /**
     * Execute search
     * 
     * @param getValues function to return a list of string values to be searched from the given object
     */
    public execute(getValues: (row: T) => string[])
    {
        // Filter rows to find matches
        return this.rows.filter(row =>
        {
            // Check each word
            for (let i = 0; (i < this.words.length); ++ i)
            {
                const word = this.words[i];

                // If any words is not found then the whole row fails
                if (!this.matchAny(word, getValues(row)))
                {
                    // console.log('did not find', word, 'in', row);
                    return false;
                }
            }

            // All words were found within the row
            // console.log('All words found', row);
            return true;
        });
    }

    /**
     * Match a word within a list of string values
     */
    private matchAny(word: string, values: string[]) : boolean
    {
        for (let i = 0; (i < values.length); ++ i)
        {
            const value = values[i];

            if (value && value.toLowerCase().indexOf(word) >= 0)
            {
                // console.log('found', word, 'in', value);
                return true;
            }
        }

        return false;
    }

}
