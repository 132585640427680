import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';

import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { AttachmentService } from '@med/provider/service/attachment';
import { Attachment } from '@med/provider/class/Attachment';
import { NumberUsageService, UsageFilter } from '@med/provider/service/number-usage';
import { UserService, AppUser } from '@med/core';
import { GroupedUsage } from '@med/provider/class/GroupedUsage';
import { NumberValueService } from '@med/provider/service/number-value';

@Component({
    selector: 'page-test-results-detail',
    templateUrl: 'test-results-detail.html'
})
export class TestResultsDetailPage implements OnInit, OnDestroy
{
    attachmentID: number;
    attachment: Attachment;
    user: AppUser;
    attMenu: BonesMenuCardAction[];
    testMenu: BonesMenuCardAction[];
    groupedUsage: GroupedUsage[];
    testValueMap: Map<number, number>;
    private nal: (() => void)[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private es: BonesErrorService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private attachmentDB: AttachmentService,
        private usedb: NumberUsageService,
        private valuedb: NumberValueService
    )
    {
        this.attachmentID = +this.route.snapshot.params.attachmentID;

        this.user = this.mtus.getUser();

        this.attMenu =
        [
            { title: 'Edit', icon: 'pencil', action: () => this.editAttachment() }
        ];

        this.testMenu =
        [
            { title: 'Edit', icon: 'pencil', action: () => this.editNumbers() },
            { title: 'Subscribe', icon: 'add-circle', action: () => this.editSubscription() }
        ];
    }

    ngOnInit()
    {
        this.init();
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    async init()
    {
        this.attachmentDB.getAttachment(this.attachmentID)
        .then(row =>
        {
            this.attachment = row;

            if (this.attachment.contentType)
            {
                this.attMenu.push({ title: 'View', icon: 'eye', action: () => this.launch.viewAttachment(this.attachment) });
                this.attMenu.push({ title: 'File Detail', icon: 'information-circle',
                    action: () => this.router.navigate([ '/provider/attachment-detail', this.attachment.attachmentID ]) });
            }
        })
        .catch(error => this.es.errorHandler(error));

        // Load and refresh usage as needed
        this.nal.push(this.usedb.cache.nowAndLater(
        rows =>
        {
            this.groupedUsage = new UsageFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byLabResults(true)
            .group();
        },
        error => this.es.errorHandler(error)));

        this.loadValues();
    }

    // Load values
    async loadValues()
    {
        this.valuedb.getValueMapForAttachment(this.attachmentID)
        .then(map => this.testValueMap = map)
        .catch(error => this.es.errorHandler(error));
    }

    async editAttachment()
    {
        const results = await this.launch.editAttachment(this.attachment);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }

    async editNumbers()
    {
        const response = await this.launch.editLabNumberValues(this.attachment);

        // Values were edited
        if (response.data)
        {
            this.loadValues();
        }
    }

    async editSubscription()
    {
        this.launch.subscribeNumbers();
    }

}
