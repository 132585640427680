import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { AppUser, UserService } from '@med/core';
import { LaunchEditModalService, AddDrugRefillDefaults } from '@med/provider/service/launch-edit-modal';
import { DrugService } from '@med/provider/service/drug';
import { Drug } from '@med/provider/class/Drug';
import { PrescriptionService } from '@med/provider/service/drug-script';
import { Prescription } from '@med/provider/class/Prescription';
import { Refill } from '@med/provider/class/Refill';
import { RefillService, RefillFilter } from '@med/provider/service/drug-refill';
import { DrugUseService, DrugUseFilter } from '@med/provider/service/drug-use';
import { DrugUse } from '@med/provider/class/DrugUse';

@Component({
    selector: 'page-script-detail',
    templateUrl: 'script-detail.html'
})
export class ScriptDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    scriptID: number;
    script: Prescription;
    drug: Drug;
    refills: Refill[];
    private nal: (() => void)[] = [ ];
    refillMenu: BonesMenuCardAction[];
    prescriptionMenu: BonesMenuCardAction[];
    usage: DrugUse[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private es: BonesErrorService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private drugDB: DrugService,
        private usedb: DrugUseService,
        private scriptDB: PrescriptionService,
        private refillDB: RefillService
    )
    {
        // Access providerID passed in URL
        this.scriptID = +this.route.snapshot.params.scriptID;
        this.user = this.mtus.getUser();

        // Card menus
        this.refillMenu =
        [
            this.launch.getMenuItemAdd(() => this.addRefill())
        ];

        this.prescriptionMenu =
        [
            {
                title: 'Edit',
                icon: 'pencil',
                action: () => this.edit()
            },
            {
                title: 'Notes',
                icon: 'musical-notes',
                action: () => this.router.navigate([ '/provider/script-notes', this.scriptID ])
            },
            {
                title: 'Usage',
                icon: 'list',
                action: () => this.router.navigate([ '/provider/drug-use-list', this.script.drugID ])
            },
            {
                title: 'Attachments',
                icon: 'attach',
                action: () => this.router.navigate([ '/provider/script-attachment-list', this.scriptID ])
            }
        ];
    }

    async ngOnInit()
    {
        // Load provider details
        this.scriptDB.getPrescription(this.scriptID)
        .then(async script =>
        {
            this.script = script;

            if (this.script.drugID)
            {
                // Get drug detail
                this.drugDB.getDrug(this.script.drugID)
                .then(drug =>  this.drug = drug)
                .catch(error => this.es.errorHandler(error));

                // Get usage rows
                this.nal.push(this.usedb.cache.nowAndLater(
                rows =>
                {
                    this.usage = new DrugUseFilter(rows)
                    .byPatient(this.user.patient.patientID)
                    .byDrug(this.script.drugID)
                    .rows;
                },
                error => this.es.errorHandler(error)));
            }
        })
        .catch(error => this.es.errorHandler(error));

        // Load and refresh refills as needed
        this.nal.push(this.refillDB.cache.nowAndLater(
        rows =>
        {
            this.refills = new RefillFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byScript(this.scriptID)
            // .byDrug(this.drugID)
            .rows;
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    async edit()
    {
        const results = await this.launch.editScript(this.script);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }

    addRefill()
    {
        const defaults: AddDrugRefillDefaults =
        {
            scriptID: this.scriptID,
        };

        if (this.refills.length > 0)
        {
            const lastRefill = this.refills[this.refills.length - 1];

            defaults.drugstoreID = lastRefill.drugstoreID;
            defaults.days = lastRefill.days;
            defaults.quantity = lastRefill.quantity;
            defaults.cost = lastRefill.cost;
            defaults.refills = lastRefill.refills;
        }


        this.launch.addDrugRefill(this.script, defaults);
    }
}
