import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { BonesCoreModule } from '@bones/core';
import { BonesFormModule } from '@bones/form';
import { BonesEditModule } from '@bones/edit';
import { BonesNetworkModule } from '@bones/network';
import { AppCoreModule } from '@med/core';

import { AppRoutingModule } from './routing.module';

import { MedAppointmentListComponent } from './component/med-appointment-list/med-appointment-list';
import { AppointmentListPage } from './pages/appointment/appointment-list/appointment-list';
import { AppointmentDetailPage } from './pages/appointment/appointment-detail/appointment-detail';
import { AppointmentEditPage } from './pages/appointment/appointment-edit/appointment-edit';
import { ProviderListPage } from './pages/provider/provider-list/provider-list';
import { ProviderDetailPage } from './pages/provider/provider-detail/provider-detail';
import { PractitionerDetailPage } from './pages/practitioner/practitioner-detail/practitioner-detail';
import { NumbersAddDeepLinkPage } from './pages/numbers/numbers-add-deep-link/numbers-add-deep-link';
import { NotesListPage } from './pages/notes/notes-list/notes-list';
import { NoteEditPage } from './pages/notes/note-edit/note-edit';
import { NotesDetailPage } from './pages/notes/notes-detail/notes-detail';
import { PractitionerEditPage } from './pages/practitioner/practitioner-edit/practitioner-edit';
import { ProviderEditPage } from './pages/provider/provider-edit/provider-edit';
import { SearchFiltersComponent } from './component/search-filters/search-filters';
import { AttachmentListPage } from './pages/attachment/attachment-list/attachment-list';
import { AttachmentEditPage } from './pages/attachment/attachment-edit/attachment-edit';
import { AttachmentDetailPage } from './pages/attachment/attachment-detail/attachment-detail';
import { AttachmentListCardComponent } from './component/attachment-list-card/attachment-list-card';
import { LocationEditPage } from './pages/provider/location-edit/location-edit';
import { DrugUseListPage } from './pages/drug/drug-use-list/drug-use-list';
import { DrugDetailPage } from './pages/drug/drug-detail/drug-detail';
import { ScriptDetailPage } from './pages/drug/script-detail/script-detail';
import { DrugRefillEditPage } from './pages/drug/refill-edit/refill-edit';
import { DrugUseListCardComponent } from './component/drug-use-list-card/drug-use-list-card';
import { DrugUseEditPage } from './pages/drug/drug-use-edit/drug-use-edit';
import { ScriptEditPage } from './pages/drug/script-edit/script-edit';
import { NoteListCardComponent } from './component/note-list-card/note-list-card';
import { DrugListPage } from './pages/drug/drug-list/drug-list';
import { DrugListCardComponent } from './component/drug-list-card/drug-list-card';
import { DrugEditPage } from './pages/drug/drug-edit/drug-edit';
import { TestResultsDetailPage } from './pages/attachment/test-results-detail/test-results-detail';
import { NumbersEditPage } from './pages/numbers/numbers-edit/numbers-edit';
import { NumbersSubscribePage } from './pages/numbers/numbers-subscribe/numbers-subscribe';
import { NumbersMaintPage } from './pages/numbers/numbers-maint/numbers-maint';
import { GroupEditPage } from './pages/numbers/numbers-maint/group-edit/group-edit';
import { KeyEditPage } from './pages/numbers/numbers-maint/key-edit/key-edit';
import { NumbersReportPage } from './pages/numbers/numbers-report/numbers-report';
import { ScriptListCardComponent } from './component/script-list-card/script-list-card';
import { NoteCardsComponent } from './component/note-cards/note-cards';
import { MyDoctorsPage } from './pages/practitioner/my-doctors/my-doctors';
import { MyDrugsPage } from './pages/drug/my-drugs/my-drugs';
import { VaccinationListCardComponent } from './component/vaccination-list-card/vaccination-list-card';
import { MyVaccinationsPage } from './pages/drug/my-vaccinations/my-vaccinations';
import { VaccinationEditPage } from './pages/drug/vaccination-edit/vaccination-edit';
import { RefillListCardComponent } from './component/refill-list-card/refill-list-card';

@NgModule({
    declarations: [
        SearchFiltersComponent,
        MedAppointmentListComponent,
        AttachmentListCardComponent,
        NoteListCardComponent,
        NoteCardsComponent,
        DrugListCardComponent,
        DrugUseListCardComponent,
        RefillListCardComponent,
        ScriptListCardComponent,
        VaccinationListCardComponent,
        NumbersAddDeepLinkPage,
        NotesListPage,
        NotesDetailPage,
        NoteEditPage,
        AppointmentListPage,
        AppointmentDetailPage,
        AppointmentEditPage,
        AttachmentListPage,
        AttachmentDetailPage,
        AttachmentEditPage,
        TestResultsDetailPage,
        NumbersEditPage,
        NumbersSubscribePage,
        NumbersMaintPage,
        GroupEditPage,
        KeyEditPage,
        NumbersReportPage,
        MyDoctorsPage,
        ProviderListPage,
        ProviderDetailPage,
        ProviderEditPage,
        LocationEditPage,
        PractitionerDetailPage,
        PractitionerEditPage,
        MyDrugsPage,
        MyVaccinationsPage,
        VaccinationEditPage,
        DrugListPage,
        DrugEditPage,
        DrugUseListPage,
        DrugUseEditPage,
        DrugDetailPage,
        ScriptDetailPage,
        ScriptEditPage,
        DrugRefillEditPage
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule, ReactiveFormsModule,
        IonicModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        BonesCoreModule,
        BonesFormModule,
        BonesEditModule,
        BonesNetworkModule,
        AppCoreModule,
        AppRoutingModule
    ],
    // Use for services that are referenced only within this module
    providers: [],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: [
        MedAppointmentListComponent,
        AttachmentListCardComponent,
        NoteListCardComponent,
        NoteCardsComponent,
        DrugListCardComponent,
        DrugUseListCardComponent,
        RefillListCardComponent,
        ScriptListCardComponent,
        VaccinationListCardComponent
    ]
})
export class ProviderModule
{
}
