import { Injectable } from '@angular/core';

import { BonesCoreService } from '@bones/core';
import { BonesServiceCache, BonesServiceCacheOptions } from '@bones/network';

import { KpsRest } from './KpsRest';

/**
 * Create a cache using injected services
 */
@Injectable({
  providedIn: 'root',
})
export class KpsRestCacheFactory
{
    /**
     * @ignore
     */
    constructor(
        private bones: BonesCoreService,
        private rest: KpsRest
    )
    {
    }

    /**
     * Create a new cache
     *
     * @param options Cache creation options
     */
    public createCache<K, T>(options: BonesServiceCacheOptions) : BonesServiceCache<K, T>
    {
        return new BonesServiceCache<K, T>(
        {
            bones: this.bones,
            rest: this.rest,
        },
        options);
    }
}
