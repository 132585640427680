import { Injectable, EventEmitter } from '@angular/core';

import { BonesCoreService } from './bones-core.service';
import { BonesError } from '../model/bones-error';

/**
 * Options for creating cache
 */
export interface BonesCacheOptions<K, RestType, CacheType>
{
    /**
     * Name of primary key property in CacheType objects.
     */
    pk: string;

    /**
     * Function to initially load the cache.
     */
    loadCache: () => Promise<RestType[]>;

    /**
     * Function to reload one cache entry.
     */
    reloadOne: (pk: K) => Promise<RestType>;

    /**
     * Function to convert a RestType object into a CacheType object.
     */
    converter: (row: RestType) => Promise<CacheType>;

    /**
     * Function to sort array as passed to array sort() method.
     */
    sorter?: (a: CacheType, b: CacheType) => number;

    /**
     * Get the primary key from the backend web service payload after
     * adding a new row via an BonesEditForm that list linked to the cache.
     * Defaults to using payload.id.
     */
    getPkFromBgePayload?: (payload: any) => K;
}

/**
 * Services required when creating a cache
 */
export interface BonesCacheServices
{
    /**
     * BonesCoreService
     */
    bones: BonesCoreService;
}

/**
 * Cache smart objects created from combining cached information from other services.
 */
export class BonesCache<K, RestType, CacheType>
{
    private promise: Promise<void>;
    private list: CacheType[] = [ ];
    private map = new Map<K, CacheType>();
    private restMap = new Map<K, RestType>();
    private cacheChange = new EventEmitter<CacheType[]>();
    public isLoaded = false;

    /**
     * @ignore
     */
    constructor(private services: BonesCacheServices, public options: BonesCacheOptions<K, RestType, CacheType>)
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Get single entry
     * @param pk primary key of entry to return
     * @returns cache entry matching primary key or undefined if the primary key does not exist in the cache.
     */
    public async getEntry(pk: K) : Promise<CacheType>
    {
        await this.load();
        return this.map.get(pk);
    }

    /**
     * Get original pre-conversion rest entry
     * @param pk primary key of entry to return
     * @returns original pre-conversion rest entry matching primary key or undefined if the primary key does not exist in the cache.
     */
    public async getRestEntry(pk: K) : Promise<RestType>
    {
        await this.load();
        return this.restMap.get(pk);
    }

    /**
     * Get all cache entries.
     * @returns cache entries
     */
    public async getList() : Promise<CacheType[]>
    {
        await this.load();
        return this.list;
    }

    /**
     * Get Map of cache entries.
     * @returns Map of cache entries
     */
    public async getMap() : Promise<Map<K, CacheType>>
    {
        await this.load();
        return this.map;
    }

    /**
     * Get map of entry keys to entry property as used by a form picker.
     * 
     * @param propertyName name of property to use to build map.
     * @param filter optional filter to apply to overall cache
     * @returns Entry map
     */
    public async getPickerMap(propertyName: string, filter?: (entry: CacheType) => boolean) : Promise<Map<K, string>>
    {
        // Make sure cache is loaded
        await this.load();

        // Filter the list if required
        const filtered = filter ? this.list.filter(filter) : this.list;

        // Build picker
        const pickerMap = new Map<K, string>();
        filtered.forEach(row => pickerMap.set(row[this.options.pk], row[propertyName]));

        return pickerMap;
    }

    //-----------------------------------------------------------------------

    // /**
    //  * Get event emitter used to subscribe to cache entries
    //  */
    // getEE() : EventEmitter<CacheType[]>
    // {
    //     // if (this.promise)
    //     // Trigger a load if the cache has not already been loaded
    //     this.load();

    //     // Return the emitter so that the caller can subscribe and unsubscribe
    //     return this.cacheChange;
    // }

    /**
     * Get cache entries now and then reinvoke callback whenever cache changes.
     *
     * @param onSuccess function to call when cache data is ready or updated.
     * @param onError function to call when error occurrs loading or updating cache.
     *
     * @returns cleanup function that must be called by calling method's ngOnDestroy or equivalent.
     */
    nowAndLater(onSuccess: (rows: CacheType[]) => void, onError?: (error: BonesError) => void) : () => void
    {
        // Subscribe to cache changes
        const subscription = this.cacheChange.subscribe(
        (rows: CacheType[]) =>
        {
            onSuccess(rows);
        },
        (error: BonesError) =>
        {
            if (onError)
            {
                onError(error);
            }
        });

        if (this.promise)
        {
            // Go ahead and reinvoke callback since the cache was already populated
            onSuccess(this.list);
        }
        else
        {
            // Trigger a load since the cache has not already been loaded
            this.load();
        }

        // Return cleanup function
        return () => subscription.unsubscribe();
    }

    //-----------------------------------------------------------------------

    /**
     * Load cache by fetching dumb objects to convert to smart objects
     */
    public async load() : Promise<void>
    {
        if (!this.promise)
        {
            // Load notes
            this.promise = new Promise(async (resolve, reject) =>
            {
                // this.options.fetch()
                this.options.loadCache()
                .then(async rows =>
                {
                    for (let i = 0; (i < rows.length); ++i)
                    {
                        const dumb = rows[i];
                        const smart = await this.options.converter(dumb);

                        this.list.push(smart);
                        this.restMap.set(dumb[this.options.pk], dumb);
                        this.map.set(smart[this.options.pk], smart);
                    }

                    // Sort list
                    if (this.options.sorter)
                    {
                        this.list.sort(this.options.sorter);
                    }

                    // this.list.forEach(a => console.log(a));
                    // console.log('bc.load', this.list, this.map, this.restMap);

                    // Broadcast event that the cache has changed
                    this.cacheChange.emit(this.list);

                    this.isLoaded = true;
                    resolve();
                })
                .catch(error =>
                {
                    error = new BonesError(
                    {
                        className: 'BonesCache',
                        methodName: 'load',
                        message: 'cache creation failed',
                        error: error
                    })
                    .add(this.options);

                    reject(error);
                    this.cacheChange.error(error);
                });
            });
        }

        return this.promise;
    }

    //-----------------------------------------------------------------------

    /**
     * Notify the cache when an entry has been updated in the db and needs to be refreshed in the cache.
     * @param pk primary key of entry to reload.
     * @returns updated cache entry.
     */
    async updated(pk: K) : Promise<CacheType>
    {
        // Fetch new row
        return this.options.reloadOne(pk)
        .then(async row =>
        {
            const newEntry = await this.options.converter(row);
            let returnValue: CacheType;

            if (this.map.has(pk))
            {
                // Update original object with new contents
                const ori = this.map.get(pk);
                this.services.bones.copyInPlace(newEntry, ori);
                this.services.bones.copyInPlace(row, this.restMap.get(pk));

                returnValue = ori;
                // console.log('bc.updated: copyInPlace', pk, newEntry, ori, this.list, this.map, this.restMap);
            }
            else
            {
                // Add new row to list and map
                this.list.push(newEntry);
                this.map.set(pk, newEntry);
                this.restMap.set(pk, row);

                returnValue = newEntry;
                // console.log('bc.updated: new row', pk, newEntry, this.list, this.map, this.restMap);
            }

            // Resort list
            if (this.options.sorter)
            {
                this.list.sort(this.options.sorter);
            }

            // Broadcast event that the cache has changed
            this.cacheChange.emit(this.list);

            return returnValue;
        })
        .catch(error =>
        {
            error = new BonesError(
            {
                className: 'BonesCache',
                methodName: 'updated',
                message: 'Unable to refresh cache entry',
                error: error
            })
            .add(this.options);

            this.cacheChange.error(error);
            throw error;
        });
    }

    /**
     * Notify the cache when an entry has been deleted.
     * @param pk primary key of entry to remove.
     */
    deleted(pk: K) : void
    {
        this.list.splice(this.list.findIndex(a => a[this.options.pk] === pk), 1);
        this.map.delete(pk);

        // console.log('bc.deleted', pk, this.map, this.restMap);

        // Broadcast event that the cache has changed
        this.cacheChange.emit(this.list);
    }

    //-----------------------------------------------------------------------

}

/**
 * Factory to create BonesCache.
 */
@Injectable({
  providedIn: 'root',
})
export class BonesCacheFactory
{
    /**
     * @ignore
     */
    constructor(
        private bones: BonesCoreService,
        // private rest: BonesRestInterface
    )
    {
    }

    /**
     * Create a new cache for a given primary key type, rest type, and cache type.
     * 
     * K is the type for the primary key. Normally number but sometimes string.
     * 
     * The RestType describes the layout of a row retrieved from a rest service.
     * This is usually an interface since these objects are received and not constructed.
     * 
     * The CacheType is the type of object stored in the cache.
     * This can be the same type as the RestType for simple caches, or it can be a full blown class based object
     * that is constructed by the converter configuration option.
     * 
     * @param options cache configurations options.
     */
    create<K, RestType, CacheType>(options: BonesCacheOptions<K, RestType, CacheType>) : BonesCache<K, RestType, CacheType>
    {
        return new BonesCache<K, RestType, CacheType>(
        {
            bones: this.bones,
            // rest: this.rest
        },
        options);
    }
}
