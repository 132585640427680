import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest } from '@med/core';

import { AttachmentTypeInfo } from '../class/AttachmentTypeInfo';

/**
 * Attachment info
 */
@Injectable({
  providedIn: 'root',
})
export class AttachmentTypeService
{
    cache: BonesCache<number, AttachmentTypeInfo, AttachmentTypeInfo>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory
    )
    {
        this.cache = this.bcf.create<number, AttachmentTypeInfo, AttachmentTypeInfo>(
        {
            pk: 'typeID',
            loadCache: () => this.rest.send('provider/AttachmentType.php/getAttachmentTypes'),
            reloadOne: (id: number) => this.rest.send('provider/AttachmentType.php/getAttachmentType', { typeID: id }),
            converter: async r => r,
            sorter: (a: AttachmentTypeInfo, b: AttachmentTypeInfo) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single attachment type
     */
    async getAttachmentTypeInfo(typeID: number) : Promise<AttachmentTypeInfo>
    {
        return this.cache.getEntry(typeID);
    }

    //-----------------------------------------------------------------------

    async getMnemonic(typeID: number) : Promise<string>
    {
        return (await this.cache.getEntry(typeID)).mnemonic;
    }

    async isTestResult(typeID: number) : Promise<boolean>
    {
        return (await this.getMnemonic(typeID)) === 'tr';
    }

    async getTestResultsType() : Promise<AttachmentTypeInfo>
    {
        return (await this.cache.getList()).find(t => t.mnemonic === 'tr');
    }

    async getDocumentType() : Promise<AttachmentTypeInfo>
    {
        return (await this.cache.getList()).find(t => t.mnemonic === 'doc');
    }

}
