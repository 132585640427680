import { NumberGroupInfo } from "./NumberGroupInfo";
import { NumberUsage } from './NumberUsage';

export class GroupedUsage
{
    usage: NumberUsage[] = [ ];

    constructor(public group: NumberGroupInfo)
    {
    }
}
