import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService } from '@bones/core';
import { AppUser, UserService } from '@med/core';

import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { Note } from '@med/provider/class/Note';
import { NoteService } from '@med/provider/service/note';

@Component({
    selector: 'page-notes-detail',
    templateUrl: 'notes-detail.html'
})
export class NotesDetailPage implements OnInit
{
    user: AppUser;
    noteID: number;
    note: Note;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private es: BonesErrorService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private noteDB: NoteService,
    )
    {
        // Access providerID passed in URL
        this.noteID = +this.route.snapshot.params.noteID;
        this.user = this.mtus.getUser();
    }

    ngOnInit()
    {
        this.noteDB.cache.getEntry(this.noteID)
        .then(row =>
        {
            this.note = row;
        })
        .catch(error => this.es.errorHandler(error));

    }

    async edit()
    {
        const results = await this.launch.editNote(this.note);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }

}
