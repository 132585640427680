import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';

import { BonesCoreModule, BonesVersion, BonesTestToolsPiglets } from '@bones/core';
import { BonesFormModule } from '@bones/form';

import { BonesTestToolsNetworkLogPage } from './pages/test-tools-network-log/test-tools-network-log';
import { BonesTestToolsNetworkLogDetailPage } from './pages/test-tools-network-log-detail/test-tools-network-log-detail';

import { BonesTtEdsGatewayConfigCardComponent } from './components/bones-tt-edsgw-config-card/bones-tt-edsgw-config-card';
import { TestToolsEdsgwConfigPage } from './pages/test-tools-edsgw-config/test-tools-edsgw-config';
// import { BonesTtEdsOauthConfigCardComponent } from '@bones/network/eds/component/bones-tt-oauth-config-card/bones-tt-oauth-config-card';
// import { TestToolsOauthConfigPage } from "@bones/network/eds/pages/test-tools-oauth-config/test-tools-oauth-config";
// import { BonesTtEdsCpnsConfigCardComponent } from '@bones/network/eds/component/bones-tt-cpns-config-card/bones-tt-cpns-config-card';
// import { TestToolsCpnsConfigPage } from "@bones/network/eds/pages/test-tools-cpns-config/test-tools-cpns-config";

import { BonesTestToolsEdsGatewayPage } from './pages/test-tools-eds-gateway/test-tools-eds-gateway';
// import { TestToolsEdsOauthPage } from "@bones/network/eds/pages/test-tools-eds-oauth/test-tools-eds-oauth";
// import { TestToolsEdsCpnsPage } from "@bones/network/eds/pages/test-tools-eds-cpns/test-tools-eds-cpns";
import { BonesTestToolsBonesRestPage } from './pages/test-tools-bones-rest/test-tools-bones-rest';
import { BonesTestToolsTspacePage } from './pages/test-tools-tspace/test-tools-tspace';
import { TestToolsBonesDocumentPage } from './pages/test-tools-bones-document/test-tools-bones-document';
import { BonesTspaceAvatarComponent } from './components/bones-tspace-avatar/bones-tspace-avatar';
import { BonesTspaceAvatarAttuidDirective } from './directive/tSpaceAvatarAttuid';
import { BonesDocumentComponent } from './components/bones-document/bones-document';

/**
 * Routes for network test tools.
 */
const routes: Route[] =
[
    { path: 'bones/test-tools/network/log', component: BonesTestToolsNetworkLogPage },
    { path: 'bones/test-tools/network/log/detail/:id', component: BonesTestToolsNetworkLogDetailPage },
    { path: 'bones/test-tools/network/eds-gateway', component: BonesTestToolsEdsGatewayPage },
    // { path: 'bones/test-tools/network/eds-oauth', component: BonesTestToolsEdsOauthPage },
    // { path: 'bones/test-tools/network/eds-cpns', component: BonesTestToolsEdsCpnsPage },
    { path: 'bones/test-tools/network/bones-rest', component: BonesTestToolsBonesRestPage },
    { path: 'bones/test-tools/network/tspace', component: BonesTestToolsTspacePage },
    { path: 'bones/test-tools/network/bones-document', component: TestToolsBonesDocumentPage },
];

@NgModule({
    declarations: [
        BonesDocumentComponent,
        BonesTspaceAvatarAttuidDirective,
        BonesTspaceAvatarComponent,
        BonesTestToolsNetworkLogPage,
        BonesTestToolsNetworkLogDetailPage,
        BonesTestToolsEdsGatewayPage,
        BonesTtEdsGatewayConfigCardComponent,
        TestToolsEdsgwConfigPage,
        BonesTestToolsBonesRestPage,
        BonesTestToolsTspacePage,
        TestToolsBonesDocumentPage
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        IonicModule,
        RouterModule.forChild(routes),
        FormsModule, ReactiveFormsModule,
        BonesCoreModule,
        BonesFormModule
    ],
    // Use for services that are referenced only within this module
    // Put global provides in forRoot() method below
    providers: [],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: [
        BonesDocumentComponent,
        BonesTtEdsGatewayConfigCardComponent,
        BonesTspaceAvatarComponent,
        BonesTspaceAvatarAttuidDirective
    ]
})
export class BonesNetworkModule
{
    static initialize()
    {
        BonesVersion.map.set('network', '1.3.2022-12-15');

        BonesTestToolsPiglets.tools.push(
        {
            group: 'Logs',
            title: 'Network Log',
            icon: 'list',
            path: 'bones/test-tools/network/log'
        },
        {
            group: 'Network',
            title: 'Network Log',
            icon: 'list',
            path: 'bones/test-tools/network/log'
        },
        {
            group: 'Network',
            title: 'EDS Gateway',
            icon: 'water',
            path: 'bones/test-tools/network/eds-gateway'
        },
        // {
        //     group: 'Network',
        //     title: 'EDS Oauth',
        //     icon: 'log-in',
        //     path: 'bones/test-tools/network/eds-oauth'
        // },
        // {
        //     group: 'Network',
        //     title: 'EDS CPNS',
        //     icon: 'notifications',
        //     path: 'bones/test-tools/network/eds-cpns'
        // },
        {
            group: 'Network',
            title: 'Bones Rest',
            icon: 'cloud',
            path: 'bones/test-tools/network/bones-rest'
        },
        {
            group: 'Network',
            title: 'Bones Document',
            icon: 'document',
            path: 'bones/test-tools/network/bones-document'
        },
        {
            group: 'Network',
            title: 'tSpace',
            icon: 'planet',
            path: 'bones/test-tools/network/tspace'
        });

        BonesTestToolsPiglets.testCases.push(
        // {
        //     group: 'Network',
        //     title: 'OAuth - CMA',
        //     description: 'Get oauth code using CMA credentials. This not not work because OAuth server does not abide by CORS.',
        //     path: 'bones/test-tools/network/oauth',
        //     vars:
        //     [
        //         { name: 'bones-tt-oauth-url', value: 'https://attdashboard.wireless.att.com/oauth' },
        //         { name: 'bones-tt-oauth-scope', value: 'attdashboard.wireless.att.com' },
        //         { name: 'bones-tt-oauth-client-id', value: 'commonapnsservice123456789012345' },
        //         { name: 'bones-tt-oauth-username', value: 'collaborationbus_commonpns' },
        //         { name: 'bones-tt-oauth-password', value: 'oauthCollaborationBus' },
        //     ]
        // },
        // {
        //     group: 'Network',
        //     title: 'CPNS - CMA',
        //     description: 'Test CPNS using CMA credentials. This not not work because OAuth and CPNS servers do not abide by CORS.',
        //     path: 'bones/test-tools/network/cpns',
        //     vars:
        //     [
        //         { name: 'bones-tt-cpns-url', value: 'https://attdashboard.wireless.att.com/CommonApnsService' },
        //         { name: 'bones-tt-cpns-application', value: 'CBus' },
        //         { name: 'bones-tt-cpns-password', value: 'pushCollaborationBus' },
        //     ]
        // },
        {
            group: 'Network',
            title: 'BonesRest - OWP Attorney Details',
            description: 'Lookup details for OWP Attorney. Actually works to lookup CENET info for most any ATTUID.',
            path: 'bones/test-tools/network/bones-rest',
            vars:
            [
                { name: 'bones-tt-gateway-server-env', value: 'prod' },
                { name: 'bones-tt-gateway-app', value: 'OWP' },
                { name: 'bones-tt-gateway-target', value: '' },
                { name: 'bones-tt-gateway-forward-url', value: '/client/awp/detail' },
                { name: 'bones-tt-gateway-forward-body', value: JSON.stringify(
                    {
                        eesATTUID: 'kp7966'
                    })
                },
                { name: 'bones-tt-brest-server', value: '/owp' },
                { name: 'bones-tt-brest-forward-url', copy: 'bones-tt-gateway-forward-url' },
                { name: 'bones-tt-brest-forward-body', copy: 'bones-tt-gateway-forward-body' },
            ]
        },
        {
            group: 'Network',
            title: 'BonesRest - CEO Reports Hybrid Login',
            description: 'Lookup user details for CEO Reports.',
            path: 'bones/test-tools/network/bones-rest',
            vars:
            [
                { name: 'bones-tt-gateway-server-env', value: 'prod' },
                { name: 'bones-tt-gateway-app', value: 'Reports' },
                { name: 'bones-tt-gateway-target', value: 'RHCDEV' },
                { name: 'bones-tt-gateway-forward-url', value: '/user/login' },
                { name: 'bones-tt-gateway-forward-body', value: '' },
                { name: 'bones-tt-brest-server', value: '/ReportsHybrid/rest' },
                { name: 'bones-tt-brest-forward-url', copy: 'bones-tt-gateway-forward-url' },
                { name: 'bones-tt-brest-forward-body', copy: 'bones-tt-gateway-forward-body' },
            ]
        },
        {
            group: 'Network',
            title: 'BonesRest - CEO Reports Hybrid Badge Count',
            description: 'Get badge counts.',
            path: 'bones/test-tools/network/bones-rest',
            vars:
            [
                { name: 'bones-tt-gateway-server-env', value: 'prod' },
                { name: 'bones-tt-gateway-app', value: 'Reports' },
                { name: 'bones-tt-gateway-target', value: 'RHCDEV' },
                { name: 'bones-tt-gateway-forward-url', value: '/item/getBadgeCounts' },
                { name: 'bones-tt-gateway-forward-body', value: '' },
                { name: 'bones-tt-brest-server', value: '/ReportsHybrid/rest' },
                { name: 'bones-tt-brest-forward-url', copy: 'bones-tt-gateway-forward-url' },
                { name: 'bones-tt-brest-forward-body', copy: 'bones-tt-gateway-forward-body' },
            ]
        },
        {
            group: 'Network',
            title: 'BonesRest - Mock Data',
            description: 'Override web service with mock data built into the app.',
            path: 'bones/test-tools/network/bones-rest',
            vars:
            [
                { name: 'bones-tt-brest-server', value: '/myapp' },
                { name: 'bones-tt-brest-forward-url', value: '/some/rest/api' },
                { name: 'bones-tt-brest-forward-body', value: '' },
            ]
        },
        {
            group: 'Network',
            title: 'BonesDocument - OWP Town Hearth',
            description: 'Load document from server.',
            path: 'bones/test-tools/network/bones-document',
            vars:
            [
                { name: 'bones-tt-gateway-server-env', value: 'dev' },
                { name: 'bones-tt-gateway-app', value: 'OWP' },
                { name: 'bones-tt-gateway-target', value: '' },
                { name: 'bones-tt-bdoc-document-id', value: '3' },
            ]
        }
        );
    }
}
BonesNetworkModule.initialize();
