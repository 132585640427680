import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest } from '@med/core';

import { DrugStoreInfo } from '../class/DrugStoreInfo';

/**
 * Drug store info
 */
@Injectable({
  providedIn: 'root',
})
export class DrugStoreService
{
    cache: BonesCache<number, DrugStoreInfo, DrugStoreInfo>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory
    )
    {
        this.cache = this.bcf.create<number, DrugStoreInfo, DrugStoreInfo>(
        {
            pk: 'drugStoreID',
            loadCache: () => this.rest.send('drug/DrugStore.php/getDrugStores'),
            reloadOne: (id: number) => this.rest.send('drug/DrugStore.php/getDrugStore', { typeID: id }),
            converter: async r => r,
            sorter: (a: DrugStoreInfo, b: DrugStoreInfo) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single row
     */
    async getDrugStoreInfo(typeID: number) : Promise<DrugStoreInfo>
    {
        return this.cache.getEntry(typeID);
    }

    //-----------------------------------------------------------------------

}
