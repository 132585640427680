import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@med/core';

import { HomePage } from './home/home.page';
import { SearchPage } from './search/search';

/**
 * Routes for top level pages.
 */
const routes: Routes =
[
    {
        path: '',
        canActivate: [ AuthGuard ],
        children:
        [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            { path: 'home', component: HomePage },
            { path: 'search', component: SearchPage },
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }), // { preloadingStrategy: PreloadAllModules }
    ],
    exports:
    [
        RouterModule
    ]
})
export class AppRoutingModule { }
