import { PatientInfo } from '@med/core';
import { AppointmentInfo } from './AppointmentInfo';
import { ProviderLocationInfo } from './ProviderLocationInfo';
import { Provider } from './Provider';
import { Practitioner } from './Practitioner';

export class Appointment implements AppointmentInfo
{
    static now: Date;

    appointmentID: number;

    // info: AppointmentInfo;
    patientID: number;
    providerID: number;
    locationID: number;
    practitionerID: number;
    scheduled: string;
    notes: string;
    purpose: string;

    scheduledDate: Date;
    isFuture: boolean;

    provider: Provider;
    location: ProviderLocationInfo;
    practitioner: Practitioner;
    patient: PatientInfo;

    constructor(row: AppointmentInfo)
    {
        Object.assign(this, row);

        this.scheduledDate = new Date(this.scheduled);

        if (!Appointment.now)
        {
            Appointment.now = new Date();
            Appointment.now.setDate(Appointment.now.getDate() - 1);
            Appointment.now.setHours(23);
        }

        this.isFuture = this.scheduledDate.getTime() > Appointment.now.getTime();
        // console.log('Appointment', this.scheduledDate, Appointment.now, this.isFuture);
    }

    public static reverseDateSort(a: Appointment, b: Appointment)
    {
        return b.scheduledDate.getTime() - a.scheduledDate.getTime();
    }
}
