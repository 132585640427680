import { PatientInfo } from '@med/core';
import { Provider } from './Provider';
import { Practitioner } from './Practitioner';
import { AttachmentInfo } from './AttachmentInfo';
import { AttachmentTypeInfo } from './AttachmentTypeInfo';
import { Drug } from './Drug';
import { Prescription } from './Prescription';

export class Attachment implements AttachmentInfo
{
    attachmentID: number;

    // info: AttachmentInfo;
    typeID: number;
    patientID: number;
    providerID: number;
    practitionerID: number;
    testProviderID: number;
    drugID: number;
    scriptID: number;
    dt: string;
    title: string;
    contentType: string;
    contentName: string;
    contentSize: number;
    notes: string;

    dtDate: Date;
    isTestResult: boolean;

    type: AttachmentTypeInfo;
    patient: PatientInfo;
    provider: Provider;
    practitioner: Practitioner;
    testProvider: Provider;
    drug: Drug;
    script: Prescription;

    constructor(row: AttachmentInfo)
    {
        Object.assign(this, row);

        this.dtDate = new Date(this.dt);
    }

}
