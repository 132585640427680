import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { DrugService } from './drug';
import { PrescriptionInfo } from '../class/PrescriptionInfo';
import { Prescription } from '../class/Prescription';
import { PractitionerService } from './practitioner';
import { formatDate } from '@angular/common';

/**
 * Drug usage info
 */
@Injectable({
  providedIn: 'root',
})
export class PrescriptionService
{
    cache: BonesCache<number, PrescriptionInfo, Prescription>;

    constructor(
        private rest: KpsRest,
        private mtus: UserService,
        private bcf: BonesCacheFactory,
        private drugDB: DrugService,
        private practitionerDB: PractitionerService
    )
    {
        this.cache = this.bcf.create<number, PrescriptionInfo, Prescription>(
        {
            pk: 'scriptID',
            loadCache: () => this.rest.send('drug/Prescriptions.php/getPrescriptions'),
            reloadOne: (id: number) => this.rest.send('drug/Prescriptions.php/getPrescription', { scriptID: id }),
            converter: async (info: PrescriptionInfo) : Promise<Prescription> =>
            {
                const script = new Prescription(info);

                script.patient = await this.mtus.getPatient(script.patientID);

                if (script.drugID)
                {
                    script.drug = await this.drugDB.getDrug(script.drugID);
                }

                if (info.practitionerID)
                {
                    script.practitioner = await this.practitionerDB.getPractitioner(info.practitionerID);
                }

                return script;
            },
            sorter: (a: Prescription, b: Prescription) =>
            {
                let compare = 0;

                if (!compare && a.drug && b.drug)
                {
                    compare = a.drug.name.localeCompare(b.drug.name);
                }

                // if (!compare && a.productName)
                // {
                //     compare = a.productName.localeCompare(b.productName);
                // }

                if (!compare)
                {
                    if (a.issued && !b.issued)
                    {
                        compare = -1;
                    }
                    else if (!a.issued && b.issued)
                    {
                        compare = 1;
                    }
                    else
                    {
                        compare = b.issuedDate.getTime() - a.issuedDate.getTime();
                    }
                }

                return compare;
            }
        });
    }

    /**
     * Get single row
     */
    async getPrescription(scriptID: number) : Promise<Prescription>
    {
        return this.cache.getEntry(scriptID);
    }

    /**
     * Get picker of prescriptions for drug
     */
    async getPrescriptionPicker(drugID?: number) : Promise<Map<number, string>>
    {
        if (drugID)
        {
            return this.cache.getPickerMap('productName', p => p.drugID === drugID);
        }
        else
        {
            return this.cache.getPickerMap('productName');
        }
    }

    /**
     * Get picker of prescriptions for drug
     */
    async getScriptPicker(patientID: number,  drugID?: number) : Promise<Map<number, string>>
    {
        const picker = new Map(
        (await this.cache.getList())
        .filter(p => p.patientID === patientID)
        .filter(p => drugID ? p.drugID === drugID : true)
        .map(p => [ p.scriptID, p.productName + (p.issued ? ' (issued ' + formatDate(p.issuedDate, 'mediumDate', 'en') + ')' : '') ])
        );

        console.log('getScriptPicker', picker);

        return picker;
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter usage
 */
export class PrescriptionFilter
{
    constructor(public rows: Prescription[])
    {
    }

    beforeDate(beforeDate: string) : PrescriptionFilter
    {
        const b4 = new Date(beforeDate);
        this.rows = this.rows.filter(r => r.issuedDate.getTime() < b4.getTime());
        return this;
    }

    afterDate(afterDate: string) : PrescriptionFilter
    {
        const b3 = new Date(afterDate);
        this.rows = this.rows.filter(r => r.issuedDate.getTime() > b3.getTime());
        return this;
    }

    recent(days: number) : PrescriptionFilter
    {
        if (days)
        {
            const thePast = new Date();
            thePast.setDate(thePast.getDate() - days);
            const thePastTime = thePast.getTime();
            this.rows = this.rows.filter(r => r.issuedDate.getTime() > thePastTime);
        }
        return this;
    }

    byPatient(patientID: number) : PrescriptionFilter
    {
        this.rows = this.rows.filter(r => r.patientID === patientID);
        return this;
    }

    byDrug(drugID: number) : PrescriptionFilter
    {
        this.rows = this.rows.filter(r => r.drugID === drugID);
        return this;
    }

    byPractitioner(practitionerID: number) : PrescriptionFilter
    {
        this.rows = this.rows.filter(r => r.practitionerID === practitionerID);
        return this;
    }

    byKeyword(keyword: string) : PrescriptionFilter
    {
        if (keyword)
        {
            const lckw = keyword.toLowerCase();

            this.rows = this.rows.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
                match = match || (typeof r.dosage === 'string' && r.dosage.toLowerCase().indexOf(lckw) >= 0);

                if (r.drug)
                {
                    match = match || (r.drug.brandName && r.drug.brandName.toLowerCase().indexOf(lckw) >= 0);
                    match = match || (r.drug.genericName && r.drug.genericName.toLowerCase().indexOf(lckw) >= 0);
                    match = match || (r.drug.notes && r.drug.notes.toLowerCase().indexOf(lckw) >= 0);
                }

                if (r.practitioner)
                {
                    match = match || (r.practitioner.speciality && r.practitioner.speciality.toLowerCase().indexOf(lckw) >= 0);
                    match = match || (r.practitioner.name && r.practitioner.name.toLowerCase().indexOf(lckw) >= 0);
                    match = match || (r.practitioner.notes && r.practitioner.notes.toLowerCase().indexOf(lckw) >= 0);
                }

                return match;
            });
        }

        return this;
    }
}
