import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest } from '@med/core';

import { ProviderService } from '@med/provider/service/provider';
import { ProviderInfo } from '@med/provider/class/ProviderInfo';

@Component({
    selector: 'provider-edit',
    templateUrl: 'provider-edit.html'
})
export class ProviderEditPage extends BonesEditForm<ProviderInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private providerDB: ProviderService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'providerID',
                    hidden: true
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(64) ]
                },
                {
                    name: 'phone',
                    title: 'Phone',
                    validator: Validators.maxLength(12)
                },
                {
                    name: 'url',
                    title: 'URL',
                    validator: Validators.maxLength(128)
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 10
                }
            ],
            saveUrl: '/provider/Provider.php/updateProvider',
            cache: this.providerDB.cache
        });
    }
}
