import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest } from '@med/core';

import { NumberKeyInfo } from '../class/NumberKeyInfo';

/**
 * numbers - keys
 */
@Injectable({
  providedIn: 'root',
})
export class NumberKeyService
{
    cache: BonesCache<number, NumberKeyInfo, NumberKeyInfo>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory
    )
    {
        this.cache = this.bcf.create<number, NumberKeyInfo, NumberKeyInfo>(
        {
            pk: 'keyID',
            loadCache: () => this.rest.send('numbers/Key.php/getKeys'),
            reloadOne: (id: number) => this.rest.send('numbers/Key.php/getKey', { keyID: id }),
            converter: async r => r,
            sorter: (a: NumberKeyInfo, b: NumberKeyInfo) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single row
     */
    async getKey(keyID: number) : Promise<NumberKeyInfo>
    {
        return this.cache.getEntry(keyID);
    }

    //-----------------------------------------------------------------------

    /**
     * A group has been deleted which may affect the cache via cascading deletes
     */
    async groupWasDeleted(groupID: number)
    {
        // Find and delete any cached keys for the deleted group
        (await this.cache.getList())
        .filter(k => k.groupID === groupID)
        .forEach(u => this.cache.deleted(u.keyID));
    }

}
