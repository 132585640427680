import { Component } from '@angular/core';

import { BonesError } from '../../model/bones-error';
import { BonesErrorService } from '../../service/bones-error.service';

import { BonesPickerService } from '../../service/bones-picker';
import { BonesPickerReturn } from '../../model/BonesPickerReturn';
import { BonesPickerOptions } from '../../model/BonesPickerOptions';
import { BonesPickerValues } from '../../model/BonesPickerValues';
import { BonesMenuCardAction } from '../../model/bones-menu-card-action';

@Component({
  templateUrl: 'test-tools-picker.html'
})
export class BonesTestToolsPickerPage
{
    options: BonesPickerOptions;
    returnValue: BonesPickerReturn;
    pickers: BonesPickerOptions[];
    actions: BonesMenuCardAction[] = [ ];
    rvActions: BonesMenuCardAction[] =
    [
        { title: 'One', icon: '', action: (a) => console.log('Option One', a), args: 1 },
        { title: 'Two', icon: '', action: () => console.log('Option 2') },
        { title: 'Three', icon: '', action: undefined }
    ];

    constructor(
        private bes: BonesErrorService,
        private bps: BonesPickerService
    )
    {
        // Create map with numbers as keys
        const picker3Map = new Map<number, string>();
        picker3Map.set(1, 'One');
        picker3Map.set(2, 'Two');
        picker3Map.set(3, 'Three');
        picker3Map.set(4, 'Four');

        // Create map with strings as keys
        const picker4Map = new Map<string, string>();
        picker4Map.set('A', 'Apple');
        picker4Map.set('B', 'Banana');
        picker4Map.set('C', 'Cherry');

        this.pickers =
        [
            {
                title: '1 - List of strings',
                values: [ 'This', 'That', 'The other' ]
            },
            {
                title: '2 - List of strings, initialValue',
                values: [ 'This', 'That', 'The other' ],
                initialValue: 'That'
            },
            {
                title: '3a - Map of numbers, initialValue',
                values: picker3Map,
                initialValue: 2
            },
            {
                title: '3b - Map of strings, initialValue',
                values: picker4Map,
                initialValue: 'B'
            },
            {
                title: '5a - Populator, map, cache',
                populator: () => this.getDeferredPickerValues1()
            },
            {
                title: '5b - Populator, map, nocache',
                populator: () => this.getDeferredPickerValues1(),
                nocache: true
            },
            {
                title: '5c - Populator, error, nocache',
                populator: () => this.badPopulator(),
                nocache: true
            },
            {
                title: '6a - Populator, map, cache, initialValue',
                populator: () => this.getDeferredPickerValues1(),
                initialValue: 'B'
            },
            {
                title: '6b - Populator, map, cache, bad initialValue',
                populator: () => this.getDeferredPickerValues1(),
                initialValue: 'D'
            },
            {
                title: '7a - Populator, map, nocache, initialValue, multi',
                populator: () => this.getDeferredPickerValues1(),
                initialValue: [ 'A', 'C' ],
                nocache: true,
                multi: true
            },
            {
                title: '7b - List of strings, multi',
                values: [ 'yacco', 'wacko', 'dot' ],
                multi: true
            },
        ];
    }

    async getDeferredPickerValues1() : Promise<BonesPickerValues>
    {
        const map = new Map<string, string>();

        map.set('A', 'Apple');
        map.set('B', 'Banana');
        map.set('C', 'Cherry');

        console.log('getDeferredPickerValues1', map);
        return map;
    }

    async badPopulator() : Promise<BonesPickerValues>
    {
        const error = new BonesError('badPopulator always throws an error');
        this.bes.errorHandler(error);
        throw error;
    }

    async doPick(options: BonesPickerOptions)
    {
        this.options = options;
        this.returnValue = undefined;
        this.returnValue = await this.bps.pick(options);
    }
}
