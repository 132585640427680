import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '@bones/edit';

import { KpsRest } from '@med/core';
import { Drugstore } from '@med/admin/class/Drugstore';
import { DrugstoreService } from '@med/admin/service/drugstore';

@Component({
  templateUrl: 'drugstore-edit.html'
})
export class DrugstoreEditPage extends BonesEditForm<Drugstore> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private db: DrugstoreService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'drugStoreID',
                    hidden: true
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(64) ]
                },
                {
                    name: 'address',
                    title: 'Address',
                    validator: Validators.maxLength(64)
                },
                {
                    name: 'phone',
                    title: 'Phone',
                    validator: Validators.maxLength(12)
                },
                {
                    name: 'url',
                    title: 'URL',
                    type: 'textarea',
                    validator: Validators.maxLength(128)
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    type: 'textarea'
                }
            ],
            saveUrl: 'admin/Drugstore.php/updateDrugstore',
            getEditRow: () : Promise<Drugstore> =>
            {
                return this.db.getDrugstore(this.pk);
            }
        });
    }

}
