import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { UserService, AppUser } from '@med/core';
import { LaunchEditModalService, AddVaccinationDefaults } from '@med/provider/service/launch-edit-modal';
import { Vaccination } from '@med/provider/class/Vaccination';

@Component({
    selector: 'vaccination-list-card',
    templateUrl: 'vaccination-list-card.html'
})
export class VaccinationListCardComponent implements OnInit
{
    @Input() vaccinations: Vaccination[];
    @Input() title: string;
    @Input() subtitle: string;
    @Input() showDrug: boolean;
    @Input() addDefaults: AddVaccinationDefaults;

    user: AppUser;

    // Dropdown menu
    actions: BonesMenuCardAction[] =
    [
        {
            title: 'Add',
            icon: 'add-circle',
            action: () => this.launch.addVaccination(this.addDefaults)
        }
    ];

    constructor(
        private mtus: UserService,
        public launch: LaunchEditModalService
    )
    {
    }

    async ngOnInit()
    {
        this.user = this.mtus.getUser();
    }

}
