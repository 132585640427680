import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { UserService, AppUser } from '@med/core';
import { LaunchEditModalService, AddScriptDefaults } from '@med/provider/service/launch-edit-modal';
import { Prescription } from '@med/provider/class/Prescription';

@Component({
    selector: 'script-list-card',
    templateUrl: 'script-list-card.html'
})
export class ScriptListCardComponent implements OnInit
{
    @Input() scripts: Prescription[];
    @Input() title: string;
    @Input() subtitle: string;
    @Input() layout: 'drug' | 'dr';
    @Input() addDefaults: AddScriptDefaults;

    user: AppUser;
    actions: BonesMenuCardAction[];

    constructor(
        private mtus: UserService,
        public launch: LaunchEditModalService
    )
    {
        this.actions =
        [
            this.launch.getMenuItemAdd(() => this.launch.addScript(this.addDefaults)),
        ];
    }

    async ngOnInit()
    {
        this.user = this.mtus.getUser();
    }

}
