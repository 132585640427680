import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { LaunchEditModalService, AddNoteDefaults } from '@med/provider/service/launch-edit-modal';
import { Note } from '@med/provider/class/Note';

@Component({
    selector: 'note-list-card',
    templateUrl: 'note-list-card.html'
})
export class NoteListCardComponent implements OnInit
{
    @Input() notes: Note[];
    @Input() title = 'Notes';
    // @Input() layout: 'note' | 'tr' = 'note';
    @Input() addDefaults: AddNoteDefaults = { };

    actions: BonesMenuCardAction[];

    constructor(
        public launch: LaunchEditModalService
    )
    {
        this.actions =
        [
            this.launch.getMenuItemAdd(() => this.launch.addNote(this.addDefaults)),
        ];
    }

    async ngOnInit()
    {
    }

}
