import { ProviderInfo } from './ProviderInfo';
import { ProviderLocationInfo } from './ProviderLocationInfo';
// import { PractitionerInfo } from './PractitionerInfo';

export class Provider implements ProviderInfo
{
    providerID: number;
    name: string;
    phone: string;
    url: string;
    notes: string;

    locations: ProviderLocationInfo[];

    constructor(row: ProviderInfo)
    {
        Object.assign(this, row);
    }
}
