import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesEditForm } from '@bones/edit';

import { NumberGroupService } from '@med/provider/service/number-group';
import { NumberKeyService } from '@med/provider/service/number-key';
import { NumberKeyInfo } from '@med/provider/class/NumberKeyInfo';
import { NumberGroupInfo } from '@med/provider/class/NumberGroupInfo';
import { GroupEditPage } from './group-edit/group-edit';
import { KeyEditPage } from './key-edit/key-edit';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { NumberUsageService } from '@med/provider/service/number-usage';

@Component({
    selector: 'page-numbers-maint',
    templateUrl: 'numbers-maint.html'
})
export class NumbersMaintPage implements OnInit, OnDestroy
{
    public groups: NumberGroupInfo[];
    public allKeys: NumberKeyInfo[];
    public chosenGroup: NumberGroupInfo;
    public keys: NumberKeyInfo[];
    groupMenu: BonesMenuCardAction[];
    keyMenu: BonesMenuCardAction[];
    private nal: (() => void)[] = [ ];

    constructor(
        private modalCtrl: ModalController,
        private es: BonesErrorService,
        private launch: LaunchEditModalService,
        private useDB: NumberUsageService,
        private groupDB: NumberGroupService,
        private keyDB: NumberKeyService
    )
    {
        this.groupMenu = [ { title: 'Add', icon: 'pencil', action: () => this.editGroup() } ];
        this.keyMenu = [ { title: 'Add', icon: 'pencil', action: () => this.editKey() } ];
    }

    async ngOnInit()
    {
        // Load and refresh groups as needed
        this.nal.push(this.groupDB.cache.nowAndLater(
        async rows => this.groups = rows,
        error => this.es.errorHandler(error)));

        // Load and refresh keys as needed
        this.nal.push(this.keyDB.cache.nowAndLater(
        async rows => this.allKeys = rows,
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    showDetail(group: NumberGroupInfo)
    {
        this.chosenGroup = group;
        this.filterKeys();
    }

    filterKeys()
    {
        this.keys = this.allKeys.filter(k => k.groupID === this.chosenGroup.groupID);
    }

    editGroup(row?: NumberGroupInfo)
    {
        BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: GroupEditPage,
            pk: row ? row.groupID : undefined,
        })
        .then(async results =>
        {
            if (results.action === 'add')
            {
                this.showDetail(await this.groupDB.getGroup(results.payload.id));
            }
            else if (results.action === 'delete')
            {
                this.useDB.groupWasDeleted(row.groupID);
                this.keyDB.groupWasDeleted(row.groupID);

                if (row.groupID === this.chosenGroup?.groupID)
                {
                    this.chosenGroup = undefined;
                }
            }
        });
    }

    editKey(row?: NumberKeyInfo)
    {
        BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: KeyEditPage,
            pk: row ? row.keyID : undefined,
            moData:
            {
                groupID: this.chosenGroup.groupID
            }
        })
        .then(async results =>
        {
            if (results.action === 'delete')
            {
                this.useDB.keyWasDeleted(row.keyID);
            }

            if (results.action !== 'cancel')
            {
                this.filterKeys();
            }
        });
    }

    async editSubscription()
    {
        this.launch.subscribeNumbers();
    }

}
