import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { DrugService } from './drug';
import { ProviderService } from './provider';
import { ProviderLocationService } from './provider-location';
import { VaccinationInfo } from '../class/VaccinationInfo';
import { Vaccination } from '../class/Vaccination';

/**
 * Drug vaccination info
 */
@Injectable({
  providedIn: 'root',
})
export class VaccinationService
{
    cache: BonesCache<number, VaccinationInfo, Vaccination>;

    constructor(
        private rest: KpsRest,
        private mtus: UserService,
        private bcf: BonesCacheFactory,
        private drugDB: DrugService,
        private providerDB: ProviderService,
        private locationDB: ProviderLocationService
    )
    {
        this.cache = this.bcf.create<number, VaccinationInfo, Vaccination>(
        {
            pk: 'vaccinationID',
            loadCache: () => this.rest.send('drug/Vaccination.php/getVaccinations'),
            reloadOne: (id: number) => this.rest.send('drug/Vaccination.php/getVaccination', { vaccinationID: id }),
            converter: async (info: VaccinationInfo) : Promise<Vaccination> =>
            {
                const vaccination = new Vaccination(info);

                vaccination.patient = await this.mtus.getPatient(vaccination.patientID);

                if (vaccination.drugID)
                {
                    vaccination.drug = await this.drugDB.getDrug(vaccination.drugID);
                }

                if (info.providerID)
                {
                    vaccination.provider = await this.providerDB.getProvider(info.providerID);
                }

                if (info.locationID)
                {
                    vaccination.location = await this.locationDB.getLocation(info.locationID);
                }

                return vaccination;
            },
            sorter: (a: Vaccination, b: Vaccination) =>
            {
                let compare = 0;

                if (!compare && a.drug && b.drug)
                {
                    compare = a.drug.name.localeCompare(b.drug.name);
                }

                if (!compare)
                {
                    if (a.vaccinated && !b.vaccinated)
                    {
                        compare = -1;
                    }
                    else if (!a.vaccinated && b.vaccinated)
                    {
                        compare = 1;
                    }
                    else
                    {
                        compare = b.vaccinatedDate.getTime() - a.vaccinatedDate.getTime();
                    }
                }

                return compare;
            }
        });
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter vaccinations
 */
export class VaccinationFilter
{
    constructor(public rows: Vaccination[])
    {
    }

    beforeDate(beforeDate: string) : VaccinationFilter
    {
        const b4 = new Date(beforeDate);
        this.rows = this.rows.filter(r => r.vaccinatedDate.getTime() < b4.getTime());
        return this;
    }

    afterDate(afterDate: string) : VaccinationFilter
    {
        const b3 = new Date(afterDate);
        this.rows = this.rows.filter(r => r.vaccinatedDate.getTime() > b3.getTime());
        return this;
    }

    recent(days: number) : VaccinationFilter
    {
        if (days)
        {
            const thePast = new Date();
            thePast.setDate(thePast.getDate() - days);
            const thePastTime = thePast.getTime();
            this.rows = this.rows.filter(r => r.vaccinatedDate.getTime() > thePastTime);
        }
        return this;
    }

    byPatient(patientID: number) : VaccinationFilter
    {
        this.rows = this.rows.filter(r => r.patientID === patientID);
        return this;
    }

    byDrug(drugID: number) : VaccinationFilter
    {
        this.rows = this.rows.filter(r => r.drugID === drugID);
        return this;
    }

    byProvider(providerID: number) : VaccinationFilter
    {
        this.rows = this.rows.filter(r => r.providerID === providerID);
        return this;
    }

    byKeyword(keyword: string) : VaccinationFilter
    {
        if (keyword)
        {
            const lckw = keyword.toLowerCase();

            this.rows = this.rows.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);

                if (r.drug)
                {
                    match = match || (r.drug.brandName && r.drug.brandName.toLowerCase().indexOf(lckw) >= 0);
                    match = match || (r.drug.genericName && r.drug.genericName.toLowerCase().indexOf(lckw) >= 0);
                    match = match || (r.drug.notes && r.drug.notes.toLowerCase().indexOf(lckw) >= 0);
                }

                if (r.provider)
                {
                    match = match || (r.provider.name && r.provider.name.toLowerCase().indexOf(lckw) >= 0);
                    match = match || (r.provider.notes && r.provider.notes.toLowerCase().indexOf(lckw) >= 0);
                }

                return match;
            });
        }

        return this;
    }
}
