import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { PractitionerService } from './practitioner';
import { ProviderService } from './provider';
import { AttachmentInfo } from '../class/AttachmentInfo';
import { Attachment } from '../class/Attachment';
import { AttachmentTypeService } from './attachmentType';
import { PrescriptionService } from './drug-script';
import { DrugService } from './drug';

/**
 * Attachment info
 */
@Injectable({
  providedIn: 'root',
})
export class AttachmentService
{
    cache: BonesCache<number, AttachmentInfo, Attachment>;

    constructor(
        private rest: KpsRest,
        private mtus: UserService,
        private bcf: BonesCacheFactory,
        private attachmentTypeDB: AttachmentTypeService,
        private providerDB: ProviderService,
        private practitionerDB: PractitionerService,
        private drugDB: DrugService,
        private scriptDB: PrescriptionService
    )
    {
        this.cache = this.bcf.create<number, AttachmentInfo, Attachment>(
        {
            pk: 'attachmentID',
            loadCache: () => this.rest.send('provider/Attachment.php/getAttachments'),
            reloadOne: (id: number) => this.rest.send('provider/Attachment.php/getAttachment', { attachmentID: id }),
            converter: async (info: AttachmentInfo) : Promise<Attachment> =>
            {
                const attachment = new Attachment(info);

                attachment.patient = await this.mtus.getPatient(attachment.patientID);
                attachment.type = await this.attachmentTypeDB.getAttachmentTypeInfo(attachment.typeID);
                attachment.isTestResult = await this.attachmentTypeDB.isTestResult(attachment.typeID);

                if (attachment.providerID)
                {
                    attachment.provider = await this.providerDB.getProvider(attachment.providerID);
                }

                if (info.practitionerID)
                {
                    attachment.practitioner = await this.practitionerDB.getPractitioner(info.practitionerID);
                }

                if (attachment.testProviderID)
                {
                    attachment.testProvider = await this.providerDB.getProvider(attachment.testProviderID);
                }

                if (info.drugID)
                {
                    attachment.drug = await this.drugDB.getDrug(info.drugID);
                }

                if (info.scriptID)
                {
                    attachment.script = await this.scriptDB.getPrescription(info.scriptID);
                }

                return attachment;
            },
            sorter: (a: Attachment, b: Attachment) => b.dtDate.getTime() - a.dtDate.getTime()
        });
    }

    /**
     * Get single attachment
     */
    async getAttachment(attachmentID: number) : Promise<Attachment>
    {
        return this.cache.getEntry(attachmentID);
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter attachments
 */
export class AttachmentFilter
{
    constructor(public rows: Attachment[])
    {
    }

    beforeDate(beforeDate: string) : AttachmentFilter
    {
        const b4 = new Date(beforeDate);
        this.rows = this.rows.filter(r => r.dtDate.getTime() < b4.getTime());
        return this;
    }

    afterDate(afterDate: string) : AttachmentFilter
    {
        const b3 = new Date(afterDate);
        this.rows = this.rows.filter(r => r.dtDate.getTime() > b3.getTime());
        return this;
    }

    recent(days: number = 365) : AttachmentFilter
    {
        if (days)
        {
            const thePast = new Date();
            thePast.setDate(thePast.getDate() - days);
            const thePastTime = thePast.getTime();
            this.rows = this.rows.filter(r => r.dtDate.getTime() > thePastTime);
        }
        return this;
    }

    old() : AttachmentFilter
    {
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);
        this.rows = this.rows.filter(r => r.dtDate.getTime() < lastYear.getTime());
        return this;
    }

    byPatient(patientID: number) : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.patientID === patientID);
        return this;
    }

    byType(typeID: number) : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.typeID === typeID);
        return this;
    }

    testResults() : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.type.mnemonic === 'tr');
        return this;
    }

    notTestResults() : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.type.mnemonic !== 'tr');
        return this;
    }

    byProvider(providerID: number) : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.providerID === providerID);
        return this;
    }

    byPractitioner(practitionerID: number) : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.practitionerID === practitionerID);
        return this;
    }

    byTestProvider(providerID: number) : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.testProviderID === providerID);
        return this;
    }

    byDrug(drugID: number) : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.drugID === drugID);
        return this;
    }

    byScript(scriptID: number) : AttachmentFilter
    {
        this.rows = this.rows.filter(r => r.scriptID === scriptID);
        return this;
    }

    byKeyword(keyword: string) : AttachmentFilter
    {
        if (keyword)
        {
            const lckw = keyword.toLowerCase();

            this.rows = this.rows.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.title && r.title.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.provider && r.provider.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.practitioner && r.practitioner.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.testProvider && r.testProvider.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.drug && r.drug.name.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.script && r.script.productName.toLowerCase().indexOf(lckw) >= 0);

                return match;
            });
        }

        return this;
    }
}
