import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesEditForm } from '@bones/edit';

import { DrugTypeService } from '@med/admin/service/drug-type';
import { DrugType } from '@med/admin/class/DrugType';
import { DrugTypeEditPage } from '../drug-type-edit/drug-type-edit';

@Component({
    selector: 'page-drug-type-list',
    templateUrl: 'drug-type-list.html'
})
export class DrugTypeListPage implements OnInit
{
    public rows: DrugType[];
    menu: BonesMenuCardAction[];

    constructor(
        private modalCtrl: ModalController,
        private es: BonesErrorService,
        private db: DrugTypeService
    )
    {
        this.menu = [ { title: 'Add', icon: 'pencil', action: () => this.edit() } ];
    }

    async ngOnInit()
    {
        this.getData();
    }

    async getData()
    {
        this.db.getDrugTypes()
        .then(rows => this.rows = rows)
        .catch(error => this.es.errorHandler(error));
    }

    edit(row?: DrugType)
    {
        BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: DrugTypeEditPage,
            pk: row ? row.drugTypeID : undefined,
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                // Refresh page
                this.getData();
            }
        });
    }

}
