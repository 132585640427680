import { PatientInfo } from '@med/core';
import { DrugUseInfo } from './DrugUseInfo';
import { Drug } from './Drug';
// import { Refill } from './Refill';

export class DrugUse implements DrugUseInfo
{
    useID: number;

    patientID: number;
    drugID: number;
    started: string;
    stopped: string;
    dosage: string;
    frequency: string;
    notes: string;

    startedDate: Date;
    stoppedDate: Date;

    patient: PatientInfo;
    drug: Drug;
    // lastRefill: Refill;

    constructor(row: DrugUseInfo)
    {
        Object.assign(this, row);

        this.startedDate = new Date(this.started);
        this.stoppedDate = new Date(this.stopped);
    }

}
