import { Component, OnInit, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesEditForm } from '@bones/edit';

@Component({
    selector: 'med-modal-header',
    templateUrl: 'med-modal-header.html'
})
export class MedModalHeaderComponent implements OnInit
{
    @Input() title;
    // @Output() close: EventEmitter<any> = new EventEmitter();
    @Input() close: () => void;
    @Input() editForm: BonesEditForm<any>;

    constructor(
        private modalCtrl: ModalController,
    )
    {
    }

    async ngOnInit()
    {
    }

    /**
     * Dismiss modal
     */
    dismiss()
    {
        if (this.editForm)
        {
            this.editForm.cancel();
        }
        else
        if (this.close)
        {
            this.close();
        }
        else
        {
            this.modalCtrl.dismiss();
        }
    }

}
