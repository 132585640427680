import { Component, OnInit, OnDestroy } from '@angular/core';

import { BonesErrorService } from '@bones/core';
import { AppUser, UserService } from '@med/core';

import { DrugUseService, DrugUseFilter } from '@med/provider/service/drug-use';
import { DrugUse } from '@med/provider/class/DrugUse';
import { Drug } from '@med/provider/class/Drug';
import { DrugType } from '@med/admin/class/DrugType';
import { DrugService } from '@med/provider/service/drug';

interface UsageGroup
{
    drug: Drug;
    rows: DrugUse[];
}

interface UsageByType
{
    type: DrugType;
    groups: UsageGroup[];
}

@Component({
    selector: 'page-my-drugs',
    templateUrl: 'my-drugs.html'
})
export class MyDrugsPage implements OnInit, OnDestroy
{
    user: AppUser;
    public active: UsageByType[];
    public pastGroups: UsageGroup[];
    private nal: (() => void)[] = [ ];

    constructor(
        private es: BonesErrorService,
        private mtus: UserService,
        private drugdb: DrugService,
        private usedb: DrugUseService
    )
    {
        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {
        // Get usage rows
        this.nal.push(this.usedb.cache.nowAndLater(
        rows => this.refresh(rows),
        error => this.es.errorHandler(error)));

        // Watch for changes to drugs and refresh usage data which may be recatorized
        this.nal.push(this.drugdb.cache.nowAndLater(
        async () => this.refresh(await this.usedb.cache.getList()),
        error => this.es.errorHandler(error)));
    }

    async refresh(rows: DrugUse[])
    {
        // Group usage by drug
        const seen = new Set<number>();
        this.active = [ ];
        this.pastGroups = [ ];

        new DrugUseFilter(rows)
        .byPatient(this.user.patient.patientID)
        .rows
        .forEach(row =>
        {
            if (!seen.has(row.drugID))
            {
                seen.add(row.drugID);

                const group: UsageGroup = { drug: row.drug, rows: [ row ] };

                if (row.stopped)
                {
                    this.pastGroups.push(group);
                }
                else
                {
                    const ubt = this.active.find(a => a.type === row.drug.type);
                    if (ubt)
                    {
                        ubt.groups.push(group);
                    }
                    else
                    {
                        this.active.push({ type: row.drug.type, groups: [ group ] });
                    }
                }
            }
        });

        console.log(this.active);

        // Sort by drug type and name
        this.active.sort((a, b) => a.type.name.localeCompare(b.type.name));
        this.active.forEach(ubt => ubt.groups.sort((a, b) => a.drug.name.localeCompare(b.drug.name)));
        this.pastGroups.sort((a, b) => a.drug.name.localeCompare(b.drug.name));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

}
