import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { DrugUseInfo } from '../class/DrugUseInfo';
import { DrugUse } from '../class/DrugUse';
import { DrugService } from './drug';
// import { RefillService } from './drug-refill';

/**
 * Drug usage info
 */
@Injectable({
  providedIn: 'root',
})
export class DrugUseService
{
    cache: BonesCache<number, DrugUseInfo, DrugUse>;

    constructor(
        private rest: KpsRest,
        private mtus: UserService,
        private bcf: BonesCacheFactory,
        private drugDB: DrugService
        // private refillDB: RefillService
    )
    {
        this.cache = this.bcf.create<number, DrugUseInfo, DrugUse>(
        {
            pk: 'useID',
            loadCache: () => this.rest.send('drug/DrugUse.php/getDrugUsage'),
            reloadOne: (id: number) => this.rest.send('drug/DrugUse.php/getDrugUse', { useID: id }),
            converter: async (info: DrugUseInfo) : Promise<DrugUse> =>
            {
                const drugUse = new DrugUse(info);

                drugUse.patient = await this.mtus.getPatient(drugUse.patientID);
                drugUse.drug = await this.drugDB.getDrug(drugUse.drugID);
                // drugUse.lastRefill = await this.refillDB.getLastRefill(drugUse.patientID, drugUse.drugID);

                return drugUse;
            },
            sorter: (a: DrugUse, b: DrugUse) => //a.drug.name.localeCompare(b.drug.name)
            {
                // Primary sort on drug
                let compare = a.drug.name.localeCompare(b.drug.name);

                // Secondary sort on descending date started
                if (!compare)
                {
                    if (a.started && !b.started)
                    {
                        compare = -1;
                    }
                    else if (!a.started && b.started)
                    {
                        compare = 1;
                    }
                    else
                    {
                        compare = b.startedDate.getTime() - a.startedDate.getTime();
                    }
                }

                return compare;
            }
        });
    }

    /**
     * Get single row
     */
    async getAttachment(useID: number) : Promise<DrugUse>
    {
        return this.cache.getEntry(useID);
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter usage
 */
export class DrugUseFilter
{
    constructor(public rows: DrugUse[])
    {
    }

    beforeDate(beforeDate: string) : DrugUseFilter
    {
        const b4 = new Date(beforeDate);
        this.rows = this.rows.filter(r => r.startedDate.getTime() < b4.getTime());
        return this;
    }

    afterDate(afterDate: string) : DrugUseFilter
    {
        const b3 = new Date(afterDate);
        this.rows = this.rows.filter(r => r.stoppedDate.getTime() > b3.getTime());
        return this;
    }

    byPatient(patientID: number) : DrugUseFilter
    {
        this.rows = this.rows.filter(r => r.patientID === patientID);
        return this;
    }

    byDrug(drugID: number) : DrugUseFilter
    {
        this.rows = this.rows.filter(r => r.drugID === drugID);
        return this;
    }

    byDrugType(drugTypeID: number) : DrugUseFilter
    {
        this.rows = this.rows.filter(r => r.drug.drugTypeID === drugTypeID);
        return this;
    }

    recent(days: number) : DrugUseFilter
    {
        if (days)
        {
            const thePast = new Date();
            thePast.setDate(thePast.getDate() - days);
            const thePastTime = thePast.getTime();
            this.rows = this.rows.filter(r => r.startedDate.getTime() > thePastTime || r.stoppedDate.getTime() > thePastTime);
        }
        return this;
    }

    byKeyword(keyword: string) : DrugUseFilter
    {
        if (keyword)
        {
            const lckw = keyword.toLowerCase();

            this.rows = this.rows.filter(r =>
            {
                let match = false;

                match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.dosage && r.dosage.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.frequency && r.frequency.toLowerCase().indexOf(lckw) >= 0);

                match = match || (r.drug.brandName && r.drug.brandName.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.drug.genericName && r.drug.genericName.toLowerCase().indexOf(lckw) >= 0);
                match = match || (r.drug.notes && r.drug.notes.toLowerCase().indexOf(lckw) >= 0);

                return match;
            });
        }

        return this;
    }
}
