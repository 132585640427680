import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { BonesError } from '@bones/core';
import { BonesRestInterface } from '@bones/network';
import { BonesNetworkService, BnsResponse } from '@bones/network';
import { KpsRestReply } from '../class/KpsRestReply';

/**
 * Access backend rest web services build using KpsRestService
 */
@Injectable({
  providedIn: 'root',
})
export class KpsRest implements BonesRestInterface
{
    private token: string;

    private config =
    {
        restServiceUrl: undefined
    };

    constructor(
        private loadingCtrl: LoadingController,
        private rest: BonesNetworkService
    )
    {
    }

    /**
     * Set URL for server rest services
     */
    get serverUrl()
    {
        return this.config.restServiceUrl;
    }

    /**
     * Set URL for server rest services
     */
    set serverUrl(url: string)
    {
        this.config.restServiceUrl = url;
        console.log('set backend rest service: ', url);
    }

    /**
     * Send request
     */
    async send(url: string, post: any = { }) : Promise<any>
    {
        const loading = await this.loadingCtrl.create();
        loading.present();

        // Call web service
        const reply: KpsRestReply = await this.rest.post(this.config.restServiceUrl, url, post,
        {
            returnValue: 'bns',
            // headers: [ 'bob', 'bob: white ' ]
            // headers: [ 'Authentication: ' + this.token ]
            // headers: [ 'x-token: ' + this.token ]
            // headers: [ { token: this.token } ]
        })
        .then((bns: BnsResponse) =>
        {
            loading.dismiss();

            // const medtoken = bns.bnsResponseInfo.httpResponse.headers.get('X-medtoken');
            // if (medtoken)
            // {
            //     console.log('header: medtoken', medtoken);
            // }

            // Get the payload from the rest service
            return bns.bnsResponseInfo.payload;
        })
        .catch(error =>
        {
            loading.dismiss();

            throw new BonesError(
            {
                className: 'KpsRest',
                methodName: 'send',
                message: 'web service failed',
                error: error
            })
            .add({ url, post });
        });

        // Did we get a status?
        if (!reply.status)
        {
            throw new BonesError(
            {
                className: 'KpsRest',
                methodName: 'send',
                message: 'no status'
            })
            .add({ url, post });
        }

        // Rest service failed
        if (reply.status.rc !== 1)
        {
            throw new BonesError(
            {
                className: 'KpsRest',
                methodName: 'send',
                message: reply.status.message
            })
            .add({ url, post });
        }

        if (reply.status.token)
        {
            console.log('reply.status.token', reply.status.token);
            this.token = reply.status.token;
        }

        return reply.payload;
    }

    //-----------------------------------------------------------------------

}
