import { Component, OnInit, OnDestroy } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { UserService, AppUser } from '@med/core';
import { AppointmentService, Appointment, AppointmentFilter } from '@med/provider';
import { RefillService, RefillFilter } from '@med/provider/service/drug-refill';
import { Refill } from '@med/provider/class/Refill';
import { DrugUseService, DrugUseFilter } from '@med/provider/service/drug-use';
import { DrugUse } from '@med/provider/class/DrugUse';
import { DrugService, DrugFilter } from '@med/provider/service/drug';
import { Drug } from '@med/provider/class/Drug';
import { NoteService, NoteFilter } from '@med/provider/service/note';
import { Note } from '@med/provider/class/Note';
import { ProviderFilter, ProviderService } from '@med/provider/service/provider';
import { Provider } from '@med/provider/class/Provider';
import { Practitioner } from '@med/provider/class/Practitioner';
import { PractitionerService, PractitionerFilter } from '@med/provider/service/practitioner';
import { Attachment } from '@med/provider/class/Attachment';
import { AttachmentService, AttachmentFilter } from '@med/provider/service/attachment';
import { AttachmentTypeService } from '@med/provider/service/attachmentType';
import { PrescriptionService, PrescriptionFilter } from '@med/provider/service/drug-script';
import { Prescription } from '@med/provider/class/Prescription';
import { AttachmentTypeInfo } from '@med/provider/class/AttachmentTypeInfo';
import { VaccinationService, VaccinationFilter } from '@med/provider/service/drug-vaccination';
import { Vaccination } from '@med/provider/class/Vaccination';

@Component({
    selector: 'app-search',
    templateUrl: 'search.html'
})
export class SearchPage implements OnInit, OnDestroy
{
    user: AppUser;
    nal: (() => void)[] = [ ];
    testResultsType: AttachmentTypeInfo;
    resultCount: number;
    keyword: string;
    showRecent = false;
    recentDays = 60;

    providers: Provider[];
    allProviders: Provider[];
    practitioners: Practitioner[];
    allPractitioners: Practitioner[];
    appointments: Appointment[];
    allAppointments: Appointment[];
    attachments: Attachment[];
    allAttachments: Attachment[];
    testResults: Attachment[];
    allTestResults: Attachment[];
    scripts: Prescription[];
    allScripts: Prescription[];
    refills: Refill[];
    allRefills: Refill[];
    usage: DrugUse[];
    allUsage: DrugUse[];
    drugs: Drug[];
    allDrugs: Drug[];
    vaccinations: Vaccination[];
    allVaccinations: Vaccination[];
    notes: Note[];
    allNotes: Note[];

    constructor(
        private es: BonesErrorService,
        private mtus: UserService,
        private notedb: NoteService,
        private providerdb: ProviderService,
        private practitionerdb: PractitionerService,
        private appdb: AppointmentService,
        private attachmentdb: AttachmentService,
        private attachmentTypeDB: AttachmentTypeService,
        private drugdb: DrugService,
        private usedb: DrugUseService,
        private vaccinationDB: VaccinationService,
        private scriptdb: PrescriptionService,
        private refilldb: RefillService
    )
    {
    }

    async ngOnInit()
    {
        // Get information about the user
        this.user = this.mtus.getUser();
        this.testResultsType = await this.attachmentTypeDB.getTestResultsType();

        // Subscribe to note cache
        this.nal.push(this.notedb.cache.nowAndLater(
        rows => this.allNotes = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to provider cache
        this.nal.push(this.providerdb.cache.nowAndLater(
        rows => this.allProviders = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to practitioner cache
        this.nal.push(this.practitionerdb.cache.nowAndLater(
        rows => this.allPractitioners = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to appointment cache
        this.nal.push(this.appdb.cache.nowAndLater(
        rows => this.allAppointments = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to attachment cache
        this.nal.push(this.attachmentdb.cache.nowAndLater(
        rows => this.allAttachments = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to script cache
        this.nal.push(this.scriptdb.cache.nowAndLater(
        rows => this.allScripts = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to refill cache
        this.nal.push(this.refilldb.cache.nowAndLater(
        rows => this.allRefills = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to usage cache
        this.nal.push(this.usedb.cache.nowAndLater(
        rows => this.allUsage = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to drug cache
        this.nal.push(this.drugdb.cache.nowAndLater(
        rows => this.allDrugs = rows,
        error => this.es.errorHandler(error)));

        // Subscribe to vaccination cache
        this.nal.push(this.vaccinationDB.cache.nowAndLater(
        rows => this.allVaccinations = rows,
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(nal => nal());
    }

    clear()
    {
        this.keyword = undefined;
        this.showRecent = false;
        this.search();
    }

    search()
    {
        if (this.keyword && !this.showRecent)
        {
            this.providers = new ProviderFilter(this.allProviders)
            .byKeyword(this.keyword)
            .rows;

            this.practitioners = new PractitionerFilter(this.allPractitioners)
            .byKeyword(this.keyword)
            .rows;

            this.drugs = new DrugFilter(this.allDrugs)
            .byKeyword(this.keyword)
            .rows;
        }
        else
        {
            this.providers = [ ];
            this.practitioners = [ ];
            this.drugs = [ ];
        }

        if (this.keyword || this.showRecent)
        {
            this.appointments = new AppointmentFilter(this.allAppointments)
            .byPatient(this.user.patient.patientID)
            .byKeyword(this.keyword)
            .past()
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;

            this.usage = new DrugUseFilter(this.allUsage)
            .byPatient(this.user.patient.patientID)
            .byKeyword(this.keyword)
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;

            this.scripts = new PrescriptionFilter(this.allScripts)
            .byPatient(this.user.patient.patientID)
            .byKeyword(this.keyword)
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;

            this.refills = new RefillFilter(this.allRefills)
            .byPatient(this.user.patient.patientID)
            .byKeyword(this.keyword)
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;

            this.attachments = new AttachmentFilter(this.allAttachments)
            .byPatient(this.user.patient.patientID)
            .notTestResults()
            .byKeyword(this.keyword)
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;

            this.testResults = new AttachmentFilter(this.allAttachments)
            .byPatient(this.user.patient.patientID)
            .testResults()
            .byKeyword(this.keyword)
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;

            this.notes = new NoteFilter(this.allNotes)
            .byPatient(this.user.patient.patientID)
            .byKeyword(this.keyword)
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;

            this.vaccinations = new VaccinationFilter(this.allVaccinations)
            .byPatient(this.user.patient.patientID)
            .byKeyword(this.keyword)
            .recent(this.showRecent ? this.recentDays : undefined)
            .rows;
        }
        else
        {
            this.appointments = [ ];
            this.usage = [ ];
            this.scripts = [ ];
            this.refills = [ ];
            this.attachments = [ ];
            this.testResults = [ ];
            this.notes = [ ];
            this.vaccinations = [ ];
        }

        this.resultCount =
            this.providers.length +
            this.practitioners.length +
            this.appointments.length +
            this.drugs.length +
            this.scripts.length +
            this.refills.length +
            this.attachments.length +
            this.testResults.length +
            this.vaccinations.length +
            this.notes.length;
    }

}
