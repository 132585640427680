import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { UserService, AppUser } from '@med/core';
import { SearchFilters } from '../class/SearchFilters';

/**
 * Appointment info
 */
@Injectable({
  providedIn: 'root',
})
export class SearchFiltersService
{
    user: AppUser;

    constructor(
        private mtus: UserService
    )
    {
        this.user = this.mtus.getUser();
    }

    /**
     * Setup filter defaults
     */
    buildDefaults(params: Params) : SearchFilters
    {
        const defaults: SearchFilters = { };

        defaults.patientID = this.user.patient.patientID;

        if (params.providerID)
        {
            defaults.providerID = +params.providerID;
        }

        if (params.practitionerID)
        {
            defaults.practitionerID = +params.practitionerID;
        }

        if (params.drugID)
        {
            defaults.drugID = +params.drugID;
        }

        if (params.scriptID)
        {
            defaults.scriptID = +params.scriptID;
        }

        return defaults;
    }

    //-----------------------------------------------------------------------

}
