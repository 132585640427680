import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '@bones/edit';

import { KpsRest } from '@med/core';
import { NumberGroupInfo } from '@med/provider/class/NumberGroupInfo';
import { NumberGroupService } from '@med/provider/service/number-group';

@Component({
  templateUrl: 'group-edit.html'
})
export class GroupEditPage extends BonesEditForm<NumberGroupInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private db: NumberGroupService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'groupID',
                    hidden: true
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(32) ]
                },
                {
                    name: 'isLabResult',
                    title: 'Is Lab Result',
                    toggle: { on: 'X', off: undefined }
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    type: 'textarea'
                }
            ],
            cache: this.db.cache,
            saveUrl: 'numbers/Group.php/updateGroup'
        });
    }

}
