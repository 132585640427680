import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { AddDrugUseDefaults } from '@med/provider/service/launch-edit-modal';
import { formatDate } from '@angular/common';
import { Prescription } from '@med/provider/class/Prescription';
import { DrugUseInfo } from '@med/provider/class/DrugUseInfo';
import { DrugUseService } from '@med/provider/service/drug-use';
import { DrugService } from '@med/provider/service/drug';

@Component({
    selector: 'drug-use-edit',
    templateUrl: 'drug-use-edit.html'
})
export class DrugUseEditPage extends BonesEditForm<DrugUseInfo> implements OnInit
{
    prescription: Prescription;

    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private mtus: UserService,
        private useDB: DrugUseService,
        private drugDB: DrugService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'useID',
                    hidden: true
                },
                {
                    name: 'patientID',
                    title: 'Patient',
                    picker: this.mtus.getPatientPicker(),
                    validator: Validators.required
                },
                {
                    name: 'drugID',
                    title: 'Drug',
                    picker: () => this.drugDB.cache.getPickerMap('name'),
                    validator: Validators.required,
                },
                {
                    name: 'started',
                    title: 'Started',
                    date: 'yyyy-MM-dd'
                },
                {
                    name: 'stopped',
                    title: 'Stopped',
                    date: 'yyyy-MM-dd'
                },
                {
                    name: 'dosage',
                    title: 'Dosage',
                    validator: Validators.maxLength(32),
                    help: 'Current amount, e.g. 10mg'
                },
                {
                    name: 'frequency',
                    title: 'Frequency',
                    validator: Validators.maxLength(32),
                    help: 'How much and how often, e.g. 1ea after breakfast and 2ea at bedtime'
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    hideTitle: true,
                    textarea: 4
                }
            ],
            // uploadFormat: 'form',
            saveUrl: '/drug/DrugUse.php/updateUse',
            cache: this.useDB.cache
        });

        // Supply defaults for new row
        if (this.isAdd)
        {
            const defaults: AddDrugUseDefaults = this.launchOptions.moData.defaults;
            this.bonesForm.setValue('patientID', defaults.patientID);
            this.bonesForm.setValue('drugID', defaults.drugID);
            this.bonesForm.setValue('dosage', defaults.dosage);
            this.bonesForm.setValue('frequency', defaults.frequency);

            this.bonesForm.setValue('started', formatDate(new Date(), 'yyyy-MM-dd', 'en'));
        }
        // Lock down changes for exiting row
        else
        {
            [ 'patientID', 'drugID', 'started', 'dosage', 'frequency' ]
            .forEach(c => this.bonesForm.getItem(c).readonly = true);
        }
    }
}
