import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest } from '@med/core';

import { DrugService } from '@med/provider/service/drug';
import { DrugInfo } from '@med/provider/class/DrugInfo';
import { DrugTypeService } from '@med/provider/service/drug-type';

@Component({
    selector: 'drug-edit',
    templateUrl: 'drug-edit.html'
})
export class DrugEditPage extends BonesEditForm<DrugInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private drugTypeDB: DrugTypeService,
        private drugDB: DrugService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'drugID',
                    hidden: true
                },
                {
                    name: 'brandName',
                    title: 'Brand Name',
                    validator: Validators.maxLength(64)
                },
                {
                    name: 'genericName',
                    title: 'Generic Name',
                    validator: Validators.maxLength(64)
                },
                {
                    name: 'drugTypeID',
                    title: 'Drug Type',
                    picker: () => this.drugTypeDB.cache.getPickerMap('name'),
                    validator: Validators.required,
                },
                {
                    name: 'url',
                    title: 'URL',
                    validator: Validators.maxLength(256),
                    help: 'Link to more information'
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 4
                }
            ],
            saveUrl: '/drug/Drug.php/updateDrug',
            cache: this.drugDB.cache
        });

        // Supply defaults for new row
        // if (this.isAdd)
        // {
        //     const defaults: AddDrugUseDefaults = this.launchOptions.moData.defaults;
        //     this.bonesForm.setValue('patientID', defaults.patientID);
        //     this.bonesForm.setValue('drugID', defaults.drugID);
        //     this.bonesForm.setValue('dosage', defaults.dosage);
        //     this.bonesForm.setValue('frequency', defaults.frequency);
        // }
        // // Lock down changes for exiting row
        // else
        // {
        //     [ 'patientID', 'drugID', 'started', 'dosage', 'frequency' ]
        //     .forEach(c => this.bonesForm.getItem(c).readonly = true);
        // }
    }
}
