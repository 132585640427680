import { Component, OnInit, OnDestroy } from '@angular/core';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { AppUser, UserService } from '@med/core';

import { Drug } from '@med/provider/class/Drug';
import { VaccinationService, VaccinationFilter } from '@med/provider/service/drug-vaccination';
import { Vaccination } from '@med/provider/class/Vaccination';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';

class ByDrug
{
    drug: Drug;
    rows: Vaccination[];

    constructor(vaccination: Vaccination)
    {
        this.drug = vaccination.drug;
        this.rows = [ vaccination ];
    }
}

class ByYear
{
    year: number;
    rows: Vaccination[];

    constructor(vaccination: Vaccination)
    {
        this.year = vaccination.vaccinatedDate.getFullYear();
        this.rows = [ vaccination ];
    }
}

@Component({
    selector: 'page-my-vaccinations',
    templateUrl: 'my-vaccinations.html'
})
export class MyVaccinationsPage implements OnInit, OnDestroy
{
    user: AppUser;
    vaccinations: Vaccination[];
    keyword: string;
    public byDrug: ByDrug[];
    public byYear: ByYear[];
    private nal: (() => void)[] = [ ];

    // Dropdown menu
    actions: BonesMenuCardAction[] =
    [
        {
            title: 'Add',
            icon: 'add-circle',
            action: () => this.launch.addVaccination()
        }
    ];

    constructor(
        private es: BonesErrorService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private vaccinationDB: VaccinationService
    )
    {
        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {
        // Load and refresh vaccinations as needed
        this.nal.push(this.vaccinationDB.cache.nowAndLater(
        rows =>
        {
            this.vaccinations = rows;
            this.refresh();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    //-----------------------------------------------------------------------

    async refresh()
    {
        const byDrugMap = new Map<number, ByDrug>();
        const byYearMap = new Map<number, ByYear>();

        new VaccinationFilter(this.vaccinations)
        .byPatient(this.user.patient.patientID)
        .byKeyword(this.keyword)
        .rows
        .forEach(row =>
        {
            // Group vaccinations by drug
            if (!byDrugMap.has(row.drugID))
            {
                byDrugMap.set(row.drugID, new ByDrug(row));
            }
            else
            {
                byDrugMap.get(row.drugID).rows.push(row);
            }

            // Group vaccinations by year vaccinated
            const year = row.vaccinatedDate.getFullYear();
            if (!byYearMap.has(year))
            {
                byYearMap.set(year, new ByYear(row));
            }
            else
            {
                byYearMap.get(year).rows.push(row);
            }
        });

        // Extract and sort map values
        this.byDrug = Array.from(byDrugMap.values()).sort((a, b) => a.drug.name.localeCompare(b.drug.name));
        this.byYear = Array.from(byYearMap.values()).sort((a, b) => b.year - a.year);
    }

}
