import { Injectable, EventEmitter } from '@angular/core';

import { BonesError } from '@bones/core';

import { AppUser } from '../class/AppUser';
import { KpsRest } from './KpsRest';
import { PatientInfo } from '../class/PatientInfo';

/**
 * Access eco user information
 */
@Injectable({
  providedIn: 'root',
})
export class UserService
{
    private user: AppUser = new AppUser();
    public userChange = new EventEmitter<AppUser>();

    constructor(
        private rest: KpsRest
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Get user object
     */
    getUser() : AppUser
    {
        return this.user;
    }

    //-----------------------------------------------------------------------

    /**
     * Authenticate user
     */
    async authenticateUser(username: string, password: string) : Promise<AppUser>
    {
        return this.rest.send('core/Login.php', { username, password })
        .then(async userPayload =>
        {
            // Update user
            this.user.authenticated = true;
            this.user.info = userPayload.user;

            return this.rest.send('core/UserService.php/getPatientsAccessibleToUser')
            .then(patientPayload =>
            {
                this.user.patients = patientPayload;

                // Does user have a defualt patient?
                if (this.user.info.patientID)
                {
                    // Assign default patient
                    this.user.patient = this.user.patients.find(p => p.patientID === this.user.info.patientID);
                }
                else if (this.user.patients.length === 1)
                {
                    // Assign user to only patient to which they have access
                    this.user.patient = this.user.patients[0];
                }
                else if (this.user.patients.length > 1)
                {
                    // Assign user to first patient to which they have access
                    this.user.patient = this.user.patients[0];
                }

                // Override default patient for kp to be sp
                // if (this.user.info.userID === 1)
                // {
                //     this.user.patient = this.user.patients.find(p => p.patientID === 2);
                // }

                // Broadcast user change event
                this.userChange.emit(this.user);

                return this.user;
            })
            .catch(error =>
            {
                throw new BonesError('Unable to get patients', error);
            });
        })
        .catch(error =>
        {
            throw new BonesError('login failed', error);
        });
    }

    //-----------------------------------------------------------------------

    async getPatient(patientID: number) : Promise<PatientInfo>
    {
        return this.user.patients.find(p => p.patientID === patientID);
    }

    /**
     * Get picker of patients for user
     */
    getPatientPicker() : Map<number, string>
    {
        const pickerMap = new Map<number, string>();
        this.user.patients.forEach(p => pickerMap.set(p.patientID, p.name));
        return pickerMap;
    }

    //-----------------------------------------------------------------------

}
