import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';

import { AppUser, UserService } from '@med/core';

import { LaunchEditModalService, AddScriptDefaults } from '@med/provider/service/launch-edit-modal';
import { DrugService } from '@med/provider/service/drug';
import { Drug } from '@med/provider/class/Drug';
import { DrugUseService, DrugUseFilter } from '@med/provider/service/drug-use';
import { DrugUse } from '@med/provider/class/DrugUse';
import { PrescriptionService, PrescriptionFilter } from '@med/provider/service/drug-script';
import { Prescription } from '@med/provider/class/Prescription';
import { NoteService, NoteFilter } from '@med/provider/service/note';
import { Note } from '@med/provider/class/Note';
import { VaccinationService, VaccinationFilter } from '@med/provider/service/drug-vaccination';
import { Vaccination } from '@med/provider/class/Vaccination';

@Component({
    selector: 'page-drug-detail',
    templateUrl: 'drug-detail.html'
})
export class DrugDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    drugID: number;
    drug: Drug;
    usage: DrugUse[];
    vaccinations: Vaccination[];
    scripts: Prescription[];
    notes: Note[];
    drugMenu: BonesMenuCardAction[];
    scriptDefaults: AddScriptDefaults;
    private nal: (() => void)[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private es: BonesErrorService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private drugDB: DrugService,
        private usedb: DrugUseService,
        private vaccinationDB: VaccinationService,
        private noteDB: NoteService,
        private scriptDB: PrescriptionService
    )
    {
        // Access providerID passed in URL
        this.drugID = +this.route.snapshot.params.drugID;
        this.user = this.mtus.getUser();

        // Note that the drug card is not tall enough to support a long menu :-(
        this.drugMenu =
        [
            {
                title: 'Edit',
                icon: 'pencil',
                action: () => this.edit()
            },
            {
                title: 'Notes',
                icon: 'musical-notes',
                action: () => this.router.navigate([ '/provider/drug-notes', this.drugID ])
            },
            {
                title: 'Usage',
                icon: 'list',
                action: () => this.router.navigate([ '/provider/drug-use-list', this.drugID ])
            },
            {
                title: 'Attachments',
                icon: 'attach',
                action: () => this.router.navigate([ '/provider/drug-attachment-list', this.drugID ])
            }
        ];
    }

    async ngOnInit()
    {
        // Load provider details
        this.drugDB.getDrug(this.drugID)
        .then(async row => this.drug = row)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh usage as needed
        this.nal.push(this.usedb.cache.nowAndLater(
        rows =>
        {
            this.usage = new DrugUseFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byDrug(this.drugID)
            .rows;
        },
        error => this.es.errorHandler(error)));

        // Load and refresh vaccinations as needed
        this.nal.push(this.vaccinationDB.cache.nowAndLater(
        rows =>
        {
            this.vaccinations = new VaccinationFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byDrug(this.drugID)
            .rows;
        },
        error => this.es.errorHandler(error)));

        // Load and refresh scripts as needed
        this.nal.push(this.scriptDB.cache.nowAndLater(
        rows =>
        {
            this.scripts = new PrescriptionFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byDrug(this.drugID)
            .rows;

            // Defaults when adding new prescription
            const mostRecentScript = this.scripts?.length > 0 ? this.scripts[0] : undefined;
            this.scriptDefaults =
            {
                patientID: this.user.patient.patientID,
                drugID: this.drugID,
                practitionerID: mostRecentScript?.practitionerID,
                productName: mostRecentScript?.productName
            };
        },
        error => this.es.errorHandler(error)));

        // Load and refresh notes as needed
        this.nal.push(this.noteDB.cache.nowAndLater(
        rows =>
        {
            this.notes = new NoteFilter(rows)
            .byPatient(this.user.patient.patientID)
            .byDrug(this.drugID)
            .rows;
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    async edit()
    {
        const results = await this.launch.editDrug(this.drug);

        if (results.action === 'delete')
        {
            this.location.back();
        }
    }

}
