// https://codewithstyle.info/Deep-property-access-in-TypeScript/

// Don't know a good name for this type.
// It's used to include `| undefined` in the return type if a property upstream of
// the end of the chain is possibly `null` or `undefined`.
type MaybeUndefined<T> = T extends null | undefined ? undefined : never;

export function dpget<
    T,
    K1 extends keyof NonNullable<T>
    >(
        obj: T,
        k1: K1
    ) : NonNullable<T>[K1]
    | MaybeUndefined<T>;

export function dpget<
    T,
    K1 extends keyof NonNullable<T>,
    K2 extends keyof NonNullable<NonNullable<T>[K1]>
    >(
        obj: T,
        k1: K1,
        k2: K2
    ) : NonNullable<NonNullable<T>[K1]>[K2]
    | MaybeUndefined<T>
    | MaybeUndefined<NonNullable<T>[K1]>;

export function dpget<
    T,
    K1 extends keyof NonNullable<T>,
    K2 extends keyof NonNullable<NonNullable<T>[K1]>,
    K3 extends keyof NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>
    >(
        obj: T,
        k1: K1,
        k2: K2,
        k3: K3
    ) : NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]
    | MaybeUndefined<T>
    | MaybeUndefined<NonNullable<T>[K1]>
    | MaybeUndefined<NonNullable<NonNullable<T>[K1]>[K2]>;

export function dpget<
    T,
    K1 extends keyof NonNullable<T>,
    K2 extends keyof NonNullable<NonNullable<T>[K1]>,
    K3 extends keyof NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>,
    K4 extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]>
    >(
        obj: T,
        k1: K1,
        k2: K2,
        k3: K3,
        k4: K4
    ) : NonNullable<NonNullable<NonNullable<NonNullable<T>[K1]>[K2]>[K3]>[K4]
    | MaybeUndefined<T>
    | MaybeUndefined<NonNullable<T>[K1]>
    | MaybeUndefined<NonNullable<NonNullable<T>[K1]>[K2]>
    | MaybeUndefined<MaybeUndefined<NonNullable<NonNullable<T>[K1]>[K2]>[K3]>;

/**
 * Get deep property value from an object
 * @param obj top level object
 * @param keys property keys to traverse
 */
export function dpget(obj: any, ...keys: string[]) : any
{
    return keys.reduce(
        (result, key) => result == null ? undefined : result[key],
        obj
    );
}

