/**
 * All public exports for module
 */

// Export the module itself
export { AdminModule } from './module';

// Export pages that are accessed externally, normally just the root page, may be better to use
// a component instead

// Services can be re-exported to import from the top level module instead of having to import from
// the nested component .ts file
// export { Appointment } from './class/Appointment';

// export { SmartObjectService } from './service/smart';
