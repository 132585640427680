import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';
import { KpsRest } from '@med/core';

import { Provider } from '../class/Provider';
import { ProviderInfo } from '../class/ProviderInfo';
import { ProviderLocationService } from './provider-location';

/**
 * Provider info
 */
@Injectable({
  providedIn: 'root',
})
export class ProviderService
{
    cache: BonesCache<number, ProviderInfo, Provider>;

    constructor(
        private rest: KpsRest,
        private bcf: BonesCacheFactory,
        private locationDB: ProviderLocationService
    )
    {
        this.cache = this.bcf.create<number, ProviderInfo, Provider>(
        {
            pk: 'providerID',
            loadCache: () => this.rest.send('provider/Provider.php/getProviders'),
            reloadOne: (id: number) => this.rest.send('provider/Provider.php/getProvider', { providerID: id }),
            converter: async (info: ProviderInfo) : Promise<Provider> =>
            {
                const provider = new Provider(info);

                provider.locations = await this.locationDB.getLocationsForProvider(provider.providerID);

                return provider;
            },
            sorter: (a: Provider, b: Provider) => a.name.localeCompare(b.name)
        });
    }

    /**
     * Get single provider
     */
    async getProvider(providerID: number) : Promise<Provider>
    {
        return this.cache.getEntry(providerID);
    }

    //-----------------------------------------------------------------------

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter usage
 */
export class ProviderFilter
{
    constructor(public rows: Provider[])
    {
    }

    // byDrugType(drugTypeID: number) : ProviderFilter
    // {
    //     this.rows = this.rows.filter(r => r.drug.drugTypeID === drugTypeID);
    //     return this;
    // }

    byKeyword(keyword: string) : ProviderFilter
    {
        const lckw = keyword.toLowerCase();

        this.rows = this.rows.filter(r =>
        {
            let match = false;

            match = match || (r.notes && r.notes.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.name && r.name.toLowerCase().indexOf(lckw) >= 0);
            match = match || (r.phone && r.phone.toLowerCase().indexOf(lckw) >= 0);

            match = match || (r.locations && !!r.locations.find(l => l.name && l.name.toLowerCase().indexOf(lckw) >= 0));
            match = match || (r.locations && !!r.locations.find(l => l.notes && l.notes.toLowerCase().indexOf(lckw) >= 0));

            return match;
        });

        return this;
    }
}
