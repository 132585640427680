import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

// import { AuthGuard } from './service/AuthGuard';

import { NotAuthorizedPage } from './pages/not-authorized/not-authorized';
import { TestToolsUserPage } from './pages/test-tools-user/test-tools-user';

/**
 * Routes for admin pages.
 */
const routes: Route[] =
[
    {
        path: '',
        // canActivate: [ AuthGuard ],
        children:
        [
            { path: 'notauthorized', component: NotAuthorizedPage },
            { path: 'test-tools/app/user', component: TestToolsUserPage },
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forChild(routes),
    ],
    exports:
    [
        RouterModule
    ]
})
export class AppRoutingModule
{
}
