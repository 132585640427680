import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '@bones/edit';

import { KpsRest } from '@med/core';
import { DrugType } from '@med/admin/class/DrugType';
import { DrugTypeService } from '@med/admin/service/drug-type';

@Component({
  templateUrl: 'drug-type-edit.html'
})
export class DrugTypeEditPage extends BonesEditForm<DrugType> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private db: DrugTypeService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'drugTypeID',
                    hidden: true
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(32) ]
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    type: 'textarea'
                }
            ],
            saveUrl: 'admin/DrugType.php/updateDrugType',
            getEditRow: () : Promise<DrugType> =>
            {
                return this.db.getDrugType(this.pk);
            }
        });
    }

}
