import { PractitionerInfo } from './PractitionerInfo';

import { ProviderInfo } from './ProviderInfo';

export class Practitioner implements PractitionerInfo
{
    practitionerID: number;
    providerID: number;
    name: string;
    speciality: string;
    notes: string;

    provider: ProviderInfo;

    constructor(row: PractitionerInfo)
    {
        Object.assign(this, row);
    }
}
