import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { AppUser, UserService } from '@med/core';
import { KpsRest } from '@med/core';

import { AddDrugRefillDefaults } from '@med/provider/service/launch-edit-modal';
import { DrugStoreService } from '@med/provider/service/drug-store';
import { RefillService } from '@med/provider/service/drug-refill';
import { formatDate } from '@angular/common';
import { Prescription } from '@med/provider/class/Prescription';
import { RefillInfo } from '@med/provider/class/RefillInfo';
import { PrescriptionService } from '@med/provider/service/drug-script';

@Component({
    selector: 'refill-edit',
    templateUrl: 'refill-edit.html'
})
export class DrugRefillEditPage extends BonesEditForm<RefillInfo> implements OnInit
{
    user: AppUser;
    prescription: Prescription;

    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private mtus: UserService,
        private refillDB: RefillService,
        private scriptDB: PrescriptionService,
        private drugStoreDB: DrugStoreService
    )
    {
        super();

        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'refillID',
                    hidden: true
                },
                {
                    name: 'scriptID',
                    title: 'Prescription',
                    picker: async () =>
                    {
                        // Limit script picker to current drug if a default script has been provided
                        const defaultScriptID = this.launchOptions.moData.defaults.scriptID;
                        let drugID: number;
                        if (defaultScriptID)
                        {
                            const script = await this.scriptDB.getPrescription(defaultScriptID);
                            drugID = script.drugID;
                        }

                        // Get picker for this patient and possibly drug
                        return this.scriptDB.getScriptPicker(this.user.patient.patientID, drugID);
                    },
                    validator: Validators.required
                },
                {
                    name: 'drugstoreID',
                    title: 'Drug Store',
                    picker: () => this.drugStoreDB.cache.getPickerMap('name'),
                    validator: Validators.required,
                },
                {
                    name: 'filled',
                    title: 'Filled',
                    date: 'yyyy-MM-dd',
                    initialValue: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
                    help: 'What date was this filled?'
                },
                {
                    name: 'days',
                    title: 'Supply in Days',
                    // type: 'number',
                    validator: Validators.pattern(/^[0-9]*$/),
                    help: 'The number of days that this refill will last.'
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    validator: Validators.maxLength(32),
                    help: 'The quantity received in pills, boxes, weight, etc.'
                },
                {
                    name: 'cost',
                    title: 'Cost',
                    // type: 'number',
                    validator: Validators.pattern(/^[0-9\.]*$/)
                },
                {
                    name: 'refills',
                    title: 'Remaining Refills',
                    // type: 'number',
                    validator: Validators.pattern(/^[0-9]*$/),
                    help: 'How many refilles are left before CVS automatically asks the Dr for more? Who cares?'
                },
                {
                    name: 'file',
                    title: 'Receipt',
                    type: 'file',
                    help: 'An image of the receipt or receipt document upload.'
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 4
                }
            ],
            uploadFormat: 'form',
            saveUrl: '/drug/Refill.php/updateRefill',
            cache: this.refillDB.cache
        });

        this.prescription = this.launchOptions.moData.prescription;

        // Supply defaults
        if (this.isAdd)
        {
            const defaults: AddDrugRefillDefaults = this.launchOptions.moData.defaults;
            this.bonesForm.setValue('scriptID', defaults.scriptID);
            this.bonesForm.setValue('drugstoreID', defaults.drugstoreID);
            this.bonesForm.setValue('days', defaults.days);
            this.bonesForm.setValue('quantity', defaults.quantity);
            this.bonesForm.setValue('cost', defaults.cost);
            this.bonesForm.setValue('refills', defaults.refills);
        }
    }
}
