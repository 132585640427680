import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService } from '@bones/core';
import { BonesForm } from '@bones/form';

import { AttachmentService, AttachmentFilter } from '@med/provider/service/attachment';
import { SearchFilters } from '@med/provider/class/SearchFilters';
import { SearchFiltersService } from '@med/provider/service/search-filter';
import { SearchFiltersComponent } from '@med/provider/component/search-filters/search-filters';
import { Attachment } from '@med/provider/class/Attachment';
import { AttachmentTypeService } from '@med/provider/service/attachmentType';

@Component({
    selector: 'page-attachment-list',
    templateUrl: 'attachment-list.html'
})
export class AttachmentListPage implements OnInit, OnDestroy
{
    public rows: Attachment[];
    public filtered: Attachment[];
    nowAndLaterCleanup: () => void;
    useControls =
    [
        'keyword', 'beginDate', 'endDate', 'patientID', 'typeID', 'providerID', 'practitionerID', 'testProviderID',
        'drugID', 'scriptID'
    ];
    defaults: SearchFilters;
    @ViewChild('filters', {static: false}) filters: SearchFiltersComponent;
    listLayout: string;
    isTestResults: boolean;
    title = 'Attachments';

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        private sfs: SearchFiltersService,
        private appdb: AttachmentService,
        private attachmentTypeDB: AttachmentTypeService
    )
    {
        // Setup filter defaults
        this.defaults = this.sfs.buildDefaults(this.route.snapshot.params);
    }

    async ngOnInit()
    {
        // Get cache rows
        this.nowAndLaterCleanup = this.appdb.cache.nowAndLater(
        rows =>
        {
            this.rows = rows;

            // Kickoff search if the filters are ready
            if (this.filters)
            {
                this.doSearch(this.filters.getValues());
            }
        },
        error => this.es.errorHandler(error));
    }

    ngOnDestroy()
    {
        this.nowAndLaterCleanup();
    }

    async doReady(form: BonesForm)
    {
        // Test results page
        if (this.route.snapshot.data?.type === 'tr')
        {
            const type = await this.attachmentTypeDB.getTestResultsType();
            this.defaults.typeID = type.typeID;
            form.setValue('typeID', type.typeID);
            console.log('mod defaults', this.defaults);
        }

        this.doSearch(form.getValues());
    }

    async doSearch(values: SearchFilters)
    {
        if (!this.rows)
        {
            return;
        }

        // console.log('SearchFilters', values);

        // Is this a search for test results?
        this.isTestResults = values.typeID && await this.attachmentTypeDB.isTestResult(values.typeID);

        // Set title based upon search type
        this.title = this.isTestResults ? 'Test Results' : 'Attachments';

        // Set layout based upon filter settings
        this.listLayout = this.isTestResults && (values.providerID || values.practitionerID) ? 'tr' : undefined;

        // Build filter
        const ap = new AttachmentFilter(this.rows);

        if (values.keyword)
        {
            ap.byKeyword(values.keyword);
        }

        if (values.patientID)
        {
            ap.byPatient(values.patientID);
        }

        if (values.beginDate)
        {
            ap.afterDate(values.beginDate);
        }

        if (values.endDate)
        {
            ap.beforeDate(values.endDate);
        }

        if (values.typeID)
        {
            ap.byType(values.typeID);
        }

        if (values.providerID)
        {
            ap.byProvider(values.providerID);
        }

        if (values.practitionerID)
        {
            ap.byPractitioner(values.practitionerID);
        }

        if (values.testProviderID)
        {
            ap.byTestProvider(values.testProviderID);
        }

        if (values.drugID)
        {
            ap.byDrug(values.drugID);
        }

        if (values.scriptID)
        {
            ap.byScript(values.scriptID);
        }

        this.filtered = ap.rows;

        // console.log('SearchResults', this.isTestResults, this.listLayout, this.title);
    }
}
