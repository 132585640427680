import { DrugTypeInfo } from './DrugTypeInfo';
import { DrugInfo } from './DrugInfo';

export class Drug implements DrugInfo
{
    drugID: number;

    drugTypeID: number;
    brandName: string;
    genericName: string;
    url: string;
    notes: string;

    type: DrugTypeInfo;

    constructor(row: DrugInfo)
    {
        Object.assign(this, row);
    }

    get name() : string
    {
        if (this.brandName && this.genericName)
        {
            return this.brandName + ' (' + this.genericName + ')';
        }
        else
        {
            return this.brandName || this.genericName;
        }
    }

    get isVaccination() : boolean
    {
        return this.drugTypeID === 4;
    }

}
