import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { BonesCoreModule } from '@bones/core';
import { BonesFormModule } from '@bones/form';
import { BonesEditModule } from '@bones/edit';
import { BonesNetworkModule } from '@bones/network';
import { AppCoreModule } from '@med/core';

import { RoutingModule } from './routing.module';

import { DrugTypeListPage } from './pages/drug-type-list/drug-type-list';

import { DrugstoreService } from './service/drugstore';
import { DrugTypeService } from './service/drug-type';
import { DrugstoreListPage } from './pages/drugstore-list/drugstore-list';
import { DrugTypeEditPage } from './pages/drug-type-edit/drug-type-edit';
import { DrugstoreEditPage } from './pages/drugstore-edit/drugstore-edit';

@NgModule({
    declarations: [
        DrugstoreListPage,
        DrugstoreEditPage,
        DrugTypeListPage,
        DrugTypeEditPage
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule, ReactiveFormsModule,
        IonicModule,
        BonesCoreModule,
        BonesFormModule,
        BonesEditModule,
        BonesNetworkModule,
        AppCoreModule,
        RoutingModule
    ],
    // Use for services that are referenced only within this module
    providers: [
        DrugstoreService,
        DrugTypeService
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: []
})
export class AdminModule
{
}
