import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Route } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { BonesCoreModule, BonesVersion } from '@bones/core';
import { BonesTestToolsPiglets } from '@bones/core';

import { BonesFormItemComponent } from './components/form-item/form-item';
import { BonesFormItemPickerComponent } from './components/form-item-picker/form-item-picker';
import { BonesFormItemToggleComponent } from './components/form-item-toggle/form-item-toggle';
import { BonesFormItemTextareaComponent } from './components/form-item-textarea/form-item-textarea';
import { BonesFormItemDateComponent } from './components/form-item-date/form-item-date';
import { BonesFormItemFileComponent } from './components/form-item-file/form-item-file';
import { BonesFormItemsComponent } from './components/form-items/form-items';
import { BonesFormErrorComponent } from './components/form-error/form-error';
import { BonesFormLabelComponent } from './components/form-label/form-label';
// import { BonesFormEditButtonBarComponent } from '@bones/form/core/components/form-edit-button-bar/form-edit-button-bar';

import { BonesFormItemPickerPage } from './pages/form-item-picker-modal/form-item-picker-modal';
import { BonesTestToolsFormBasicPage } from './pages/test-tools-bones-form/test-tools-bones-form';
import { TestToolsBonesFormControlsPage } from './pages/test-tools-bones-form-controls/test-tools-bones-form-controls';
import { BonesFormItemElapsedTimeComponent } from './components/form-item-elapsed-time/form-item-elapsed-time';

/**
 * Routes for form test tools.
 */
const routes: Route[] =
[
    { path: 'bones/test-tools/form/basic', component: BonesTestToolsFormBasicPage },
    { path: 'bones/test-tools/form/controls', component: TestToolsBonesFormControlsPage },
];

@NgModule({
    declarations: [
        BonesFormItemComponent,
        BonesFormItemPickerComponent,
        BonesFormItemPickerPage,
        BonesFormItemToggleComponent,
        BonesFormItemTextareaComponent,
        BonesFormItemDateComponent,
        BonesFormItemElapsedTimeComponent,
        BonesFormItemFileComponent,
        BonesFormItemsComponent,
        BonesFormErrorComponent,
        BonesFormLabelComponent,
        // BonesFormEditButtonBarComponent,
        BonesTestToolsFormBasicPage,
        TestToolsBonesFormControlsPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes),
        FormsModule, ReactiveFormsModule,
        BonesCoreModule,
    ],
    // Use for services that are referenced only within this module
    // Put global provides in forRoot() method below
    providers: [
        DatePipe
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: [
        BonesFormItemComponent,
        BonesFormItemPickerComponent,
        BonesFormItemToggleComponent,
        BonesFormItemTextareaComponent,
        BonesFormItemsComponent,
        BonesFormErrorComponent,
        BonesFormLabelComponent,
        // BonesFormEditButtonBarComponent
    ]
})
export class BonesFormModule
{
    static initialize()
    {
        BonesVersion.map.set('form', '1.3.2023-01-25c');

        BonesTestToolsPiglets.tools.push(
        {
            group: 'Form',
            title: 'Basic Form',
            icon: 'list',
            path: 'bones/test-tools/form/basic'
        },
        {
            group: 'Form',
            title: 'Form Controls',
            icon: 'game-controller',
            path: 'bones/test-tools/form/controls'
        });
    }
}
BonesFormModule.initialize();
