import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { UserService } from '@med/core/service/UserService';
import { AppUser } from '@med/core/class/AppUser';

@Component({
    selector: 'med-page-header',
    templateUrl: 'med-page-header.html',
    styleUrls: [ 'med-page-header.scss' ]
})
export class MedPageHeaderComponent implements OnInit
{
    @Input() title: string;
    @Input() showBreadcrumbs = false;
    user: AppUser;

    constructor(
        private nav: NavController,
        private mtus: UserService
    )
    {
    }

    async ngOnInit()
    {
        this.user = this.mtus.getUser();
    }

    /**
     * Go home
     */
    async home()
    {
        // Navigate to home page and remove all pages from the stack
        this.nav.navigateRoot('/');
    }

    /**
     * Go search
     */
    async search()
    {
        // Navigate to home page and remove all pages from the stack
        this.nav.navigateRoot('/search');
    }

}
