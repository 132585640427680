import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService } from '@bones/core';
import { BonesForm } from '@bones/form';

import { SearchFilters } from '@med/provider/class/SearchFilters';
import { SearchFiltersService } from '@med/provider/service/search-filter';
import { SearchFiltersComponent } from '@med/provider/component/search-filters/search-filters';
import { Drug } from '@med/provider/class/Drug';
import { LaunchEditModalService } from '@med/provider/service/launch-edit-modal';
import { DrugService, DrugFilter } from '@med/provider/service/drug';

@Component({
    selector: 'page-drug-list',
    templateUrl: 'drug-list.html'
})
export class DrugListPage implements OnInit, OnDestroy
{
    public rows: Drug[];
    public filtered: Drug[];
    nowAndLaterCleanup: () => void;
    useControls =
    [
        'keyword', 'drugID', 'drugTypeID' //, 'isActive'
    ];
    defaults: SearchFilters;
    @ViewChild('filters', {static: false}) filters: SearchFiltersComponent;

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        private launch: LaunchEditModalService,
        private sfs: SearchFiltersService,
        private drugdb: DrugService
    )
    {
        // Setup filter defaults
        this.defaults = this.sfs.buildDefaults(this.route.snapshot.params);
    }

    async ngOnInit()
    {
        // Get cache rows
        this.nowAndLaterCleanup = this.drugdb.cache.nowAndLater(
        rows =>
        {
            this.rows = rows;

            // Kickoff search if the filters are ready
            if (this.filters)
            {
                this.doSearch(this.filters.getValues());
            }
        },
        error => this.es.errorHandler(error));
    }

    ngOnDestroy()
    {
        this.nowAndLaterCleanup();
    }

    async doReady(form: BonesForm)
    {
        form.setValue('isActive', true);
        this.doSearch(form.getValues());
    }

    async doSearch(values: SearchFilters)
    {
        if (!this.rows)
        {
            return;
        }

        // console.log('SearchFilters', values);

        // Build filter
        const ap = new DrugFilter(this.rows);

        if (values.keyword)
        {
            ap.byKeyword(values.keyword);
        }

        if (values.drugID)
        {
            ap.byDrug(values.drugID);
        }

        if (values.drugTypeID)
        {
            ap.byDrugType(values.drugTypeID);
        }

        this.filtered = ap.rows;

        // if (values.isActive)
        // {
        // }
    }

    add()
    {
        this.launch.addDrug();
    }
}
