import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { BonesError } from '@bones/core';
import { BonesDeviceService } from '@bones/core';

import { BnsOptions } from '../class/BnsOptions';
import { BnsFormOptions } from '../class/BnsFormOptions';
import { BnsResponse } from '../class/BnsResponse';
import { BonesRestInterface } from '../class/BonesRestInterface';
import { EDSGatewayService } from './eds-gateway';
import { BonesNetworkService } from './ws';
import { DirectAccessEnabled } from '../class/DirectAccessEnabled';
import { DirectAccessOptions } from '../class/DirectAccessOptions';

/**
 * Rest service that interfaces to StdWebRest server-side Java class
 */
@Injectable({
  providedIn: 'root'
})
export class BonesRestService implements BonesRestInterface
{
    private _directEnabled: boolean = false;

    /**
     * @ignore
     */
    constructor(
        private alertCtrl: AlertController,
        private bns: BonesNetworkService,
        private edsgw: EDSGatewayService,
        private bds: BonesDeviceService
    )
    {
    }

    /**
     * Has application been enabled to send direct web service calls?
     */
    public get directEnabled() : boolean
    {
        return this._directEnabled;
    }

    //-----------------------------------------------------------------------

    // Configuration
    private config =
    {
        directServerUrl: '/appContentRoot/rest'
    };

    /**
     * Set server base url that bypasses eds gateway and goes directly to backend server.
     * The url must be externally facing or the device needs to be using vpn.
     * 
     * Note that due to CORS, direct access is not going to work unless the app was downloaded
     * from the same server and not run in a device or on a desktop via 'ionic serve'.
     * 
     * @param url Full or partial URL for server rest services. 
     */
    public set directServerUrl(url: string)
    {
        this.config.directServerUrl = url;
    }

    /**
     * Get previously configured server base url
     */
    public get directServerUrl()
    {
        return this.config.directServerUrl;
    }

    //-----------------------------------------------------------------------

    /**
     * Call back end web service derived from StdWebRest via EDS Gateway
     * 
     * @param url web service url
     * @param postData data to post to server
     * @param options low level networking options
     */
    public forward(url: string, postData: any = { }, options = new BnsOptions()) : Promise<any>
    {
        return this.ghost('gw', url, postData, options);
    }

    /**
     * Call back end web service derived from StdWebRest bypassing EDS Gateway
     * 
     * @param url web service url
     * @param postData data to post to server
     * @param options low level networking options
     */
    public direct(url: string, postData: any = { }, options = new BnsOptions()) : Promise<any>
    {
        return this.ghost('d', url, postData, options);
    }

    /**
     * Call back end web service (could be either direct or via eds gateway)
     * 
     * @param url web service url
     * @param postData data to post to server
     * @param options low level networking options
     */
    public send(url: string, postData: any = { }, options = new BnsOptions()) : Promise<any>
    {
        return this.ghost(this._directEnabled ? 'd' : 'gw', url, postData, options);
    }

    //-----------------------------------------------------------------------

    /**
     * Call back end web service
     * 
     * @param url web service url
     * @param postData data to post to server
     * @param options low level networking options
     */
    private async ghost(via: 'd' | 'gw', url: string, postData: any, options: BnsOptions) : Promise<any>
    {
        // Local options to override passed options
        const localOptions: BnsOptions =
        {
            ...options,
            returnValue: 'bns'
        };

        let pp: Promise<any>;
        let badStatus = false;
        if (via === 'gw')
        {
            // Call web service via eds gateway
            pp = this.edsgw.forward(url, postData, localOptions);
        }
        else if (via === 'd')
        {
            // Call web service directly
            pp = this.bns.post(this.config.directServerUrl, url, postData, localOptions);
        }

        // Process results
        return pp.then((bnsResponse: BnsResponse) =>
        {
            const payload = bnsResponse.bnsResponseInfo.payload;

            // Return non-json data without any processing
            if (options.responseType !== 'json')
            {
                return payload;
            }

            // Response is json data
            const json = payload;

            // // Global login is no longer valid
            // if (json.status === 'InvalidCSP')
            // {
            // }

            // Check status set by bones StdWebRest server class
            if (json.status !== 'ok')
            {
                badStatus = true;
                const error = new BonesError(
                {
                    className: 'BonesRestService',
                    methodName: 'ghost-' + via,
                    message: json.status
                });

                // Update log to show failure
                bnsResponse.logEntry.status = 'failure';
                bnsResponse.logEntry.error = error;

                throw error;
            }

            // Analyze network latency
            if (json.stats)
            {
                // Save stats into response info
                const stats = bnsResponse.bnsResponseInfo.stats;
                stats.serverExecutionTime = json.stats.totalTime;
                stats.networkOverheadTime = stats.totalElapsedTime - stats.serverExecutionTime;
                stats.networkOverheadPercent = Math.round(stats.networkOverheadTime / stats.totalElapsedTime * 100);

                if (stats.networkOverheadPercent > 50)
                {
                    console.log('excessive network overhead time',
                        stats.networkOverheadPercent + '%',
                        '(total:', stats.totalElapsedTime + 'ms,',
                        'server:', stats.serverExecutionTime + 'ms,',
                         'network overhead:', stats.networkOverheadTime + 'ms)');
                }
            }

            // console.log(url, json);
            return json.payload;
        })
        .catch(error =>
        {
            // Pass on original error if it was caused by bad status rather then server error
            // if (BonesError.prototype.isPrototypeOf(error)) throw error;
            if (badStatus)
            {
                throw error;
            }

            // Default error message
            let errorMessage = 'unable to access backend web service';

            // Check for http error details bundled with error object
            if (error.otherData && error.otherData.bnsResponseInfo)
            {
                // Get http error details
                const httpErrorResponse = error.otherData.bnsResponseInfo.httpErrorResponse;

                // Get payload sent from server along with error
                const payload = httpErrorResponse.error;

                // Override error message with status returned from server
                if (payload && payload.status)
                {
                    errorMessage = payload.status;
                }
            }

            throw new BonesError(
            {
                className: 'BonesRestService',
                methodName: 'ghost-' + via,
                message: errorMessage,
                error: error
            });
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Send request in new tab.
     * 
     * This method is useful for opening files within the browser such as text, image, or PDF files.
     * 
     * The jsonRequest will be encoded as a string and passed to the server as a query parameter named 'jsonRequest'.
     * It may be retrieved on the server using swc.getParms().getJsonObjectParameter("jsonRequest").
     * 
     * @param url web service url
     * @param jsonRequest data to pass to server
     */
    public async openTab(url: string, jsonRequest: any = { })
    {
         const arg = encodeURIComponent(JSON.stringify(jsonRequest));
         const fullurl = this.directServerUrl + url + '?jsonRequest=' + arg;
         window.open(fullurl);
    }

    /**
     * Send request as form.
     * 
     * This method is useful for downloading files.
     * 
     * By using a form rather than an XHR request, the output of the web server will replace the contents of the
     * browser tab unless the server sends back a download with a content-disposition of 'attachment'.
     * 
     * The jsonRequest will be encoded as a string and passed to the server as a form parameter named 'jsonRequest'.
     * It may be retrieved on the server using swc.getParms().getJsonObjectParameter("jsonRequest").
     * 
     * @param url web service url
     * @param jsonRequest data to send to server
     * @param options method options
     */
    public async sendForm(url: string, jsonRequest: any = { }, options: BnsFormOptions = { })
    {
        // Create hidden form for the download
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', this.directServerUrl + url);
        form.style.display = 'none';

        // Form has single input element named jsonRequest that contains the json request as a string
        const input = document.createElement('input');
        input.setAttribute('name', 'jsonRequest');
        input.setAttribute('value', JSON.stringify(jsonRequest));
        form.appendChild(input);

        // The form has to be appended to the body to be submitted
        document.body.appendChild(form);

        // Submit the form
        form.submit();

        // Cleanup by removing the temporary form
        document.body.removeChild(form);
        form.remove();

        // Show alert that download is in progress
        if (!options.silent)
        {
            const message = options.message
                || 'Download should start shortly.<br><br>File will be in your browser\'s downloads folder.';

            (await this.alertCtrl.create(
            {
                header: 'Download Started',
                message: message,
                buttons:
                [
                    {
                        text: 'OK'
                    }
                ]
            })).present();
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Try to configure direct access to back end web service if running in a browser
     * 
     * deprecated - Use configureDirect instead
     * 
     * @param url web service url
     * @param postData data to post to server
     * @param options low level networking options
     * 
     * @returns true = app is enabled to use direct access to backend web services
     * @deprecated Use configureDirect
     */
    async configureDirectWebServiceAccess(config:
    {
        /**
         * Partial URL to reach web services from client, e.g. /AppContext/rest
         */
        directServerUrl: string,
        /**
         * Web service that can be pinged to verify authentication is present, e.g. /user/ping
         */
        pingServiceUrl: string,
        /**
         * Backend URL that will trigger server authentication, e.g. /AppContext/authenticate
         */
        redirecteUrl: string
    }) : Promise<boolean>
    {
        // Check to see if app is running from within a browser
        const info = await this.bds.getInfo();

        // App is not running within a browser
        if (!info.isBrowserDevice)
        {
            return false;
        }

        // Setup for direct access to web services from in-browser app
        this.directServerUrl = config.directServerUrl;

        try
        {
            // Ping web service to make sure that user has been authenticated
            const payload = await this.direct(config.pingServiceUrl);

            // console.log('direct web service access enabled', payload);
            this._directEnabled = true;
            return this._directEnabled;
        }
        catch (error)
        {
            // InvalidCSP indicates we were aple to reach the server, but there is no
            // global login (or other authentication) in place.
            // The clent will redirect to a web url that will trigger the global login.
            if (error.message === 'InvalidCSP')
            {
                console.log('user not authenticated, a redirect is in order');
                window.location.href = config.redirecteUrl;
                return undefined;
            }
            else
            {
                // Unable to reach server
                throw new BonesError(
                {
                    className: 'BonesRestService',
                    methodName: 'configureDirectWebServiceAccess',
                    message: 'unable to ping backend web service',
                    error: error
                });

                // this.bes.errorHandler(error, 'silent');
            }

        }
    }

    //-----------------------------------------------------------------------

    /**
     * Try to configure direct access to back end web service if running in a browser
     * 
     * @param config Configuration options
     * @returns DirectAccessEnabled object to indicate possible results
     */
    async configureDirect(config: DirectAccessOptions) : Promise<DirectAccessEnabled>
    {
        // Check to see if app is running from within a browser
        const info = await this.bds.getInfo();

        // App is not running within a browser
        if (!info.isBrowserDevice)
        {
            return new DirectAccessEnabled('n');
        }

        // Setup for direct access to web services from in-browser app
        this.directServerUrl = config.directServerUrl;

        try
        {
            // Ping web service to make sure that user has been authenticated
            await this.direct(config.pingServiceUrl);

            // console.log('direct web service access enabled', payload);
            this._directEnabled = true;
            return new DirectAccessEnabled('y');
        }
        catch (error)
        {
            // InvalidCSP indicates we were aple to reach the server, but there is no
            // global login (or other authentication) in place.
            // The clent will redirect to a web url that will trigger the global login.
            if (error.message === 'InvalidCSP')
            {
                console.log('user not authenticated, a redirect is in order');
                window.location.href = config.redirecteUrl;
                return new DirectAccessEnabled('InvalidCSP');
            }
            // InvalidATTUID indicates that the server is accessible, and the user has valid global login (csp)
            // credentials, but the application does not allow this user to access the app
            else if (error.message === 'InvalidATTUID')
            {
                console.log('user global login is valid, but they do not have access to app');
                return new DirectAccessEnabled('InvalidATTUID');
            }
            // Unable to reach server
            else
            {
                throw new BonesError(
                {
                    className: 'BonesRestService',
                    methodName: 'configureDirectWebServiceAccess',
                    message: 'unable to ping backend web service',
                    error: error
                });

                // this.bes.errorHandler(error, 'silent');
            }

        }
    }

}
