import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { KpsRest, UserService } from '@med/core';

import { AppointmentInfo } from '@med/provider/class/AppointmentInfo';
import { AppointmentService } from '@med/provider/service/appointment';
import { PractitionerService } from '@med/provider/service/practitioner';
import { ProviderService } from '@med/provider/service/provider';
import { ProviderLocationService } from '@med/provider/service/provider-location';

@Component({
    selector: 'appointment-edit',
    templateUrl: 'appointment-edit.html'
})
export class AppointmentEditPage extends BonesEditForm<AppointmentInfo> implements OnInit
{
    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: KpsRest,
        private mtus: UserService,
        private appointmentDB: AppointmentService,
        private providerDB: ProviderService,
        private locationDB: ProviderLocationService,
        private practitionerDB: PractitionerService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'appointmentID',
                    hidden: true
                },
                {
                    name: 'patientID',
                    title: 'Patient',
                    picker: this.mtus.getPatientPicker(),
                    validator: Validators.required
                },
                {
                    name: 'providerID',
                    title: 'Provider',
                    picker: () => this.providerDB.cache.getPickerMap('name'),
                    onChange: (value) =>
                    {
                        this.bonesForm.setValue('locationID', undefined);
                        this.bonesForm.setValue('practitionerID', undefined);
                    },
                    validator: Validators.required
                },
                {
                    name: 'locationID',
                    title: 'Location',
                    picker:
                    {
                        populator: () => this.locationDB.getLocationPicker(this.bonesForm.getValue('providerID')),
                        nocache: true
                    }
                },
                {
                    name: 'practitionerID',
                    title: 'Practitioner',
                    picker:
                    {
                        populator: () => this.practitionerDB.getPractitionerPicker(this.bonesForm.getValue('providerID')),
                        nocache: true
                    }
                },
                {
                    name: 'scheduled',
                    title: 'Scheduled',
                    date: 'yyyy-MM-dd HH:mm',
                    validator: Validators.required
                },
                {
                    name: 'purpose',
                    title: 'Purpose',
                    validator: Validators.maxLength(64)
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 10
                }
            ],
            saveUrl: '/provider/Appointment.php/updateAppointment',
            cache: this.appointmentDB.cache
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue('patientID', this.launchOptions.moData.patientID);
            this.bonesForm.setValue('providerID', this.launchOptions.moData.providerID);
            this.bonesForm.setValue('locationID', this.launchOptions.moData.locationID);
            this.bonesForm.setValue('practitionerID', this.launchOptions.moData.practitionerID);
        }
    }
}
