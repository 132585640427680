import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { UserService } from './UserService';
import { LoginPage } from '../pages/login/login';

/**
 * Make sure user is authenticated before any routes can be loaded
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    constructor(
        private modalCtrl: ModalController,
        private router: Router,
        private mtus: UserService
    )
    {
    }

    /**
     * Can user activate a given route?
     */
    async canActivate() : Promise<boolean | UrlTree>
    {
        // Get user info
        const user = this.mtus.getUser();

        // Authenticate user if it has not yet been authenticated
        if (!user.authenticated)
        {
            // Launch login modal and wait to see if the user becomes authenticated
            if (!await LoginPage.launch(this.modalCtrl))
            {
                // Route to the not authorized page if the user fails to authenticate
                return this.router.parseUrl('/notauthorized');
            }
        }

        return user.authenticated;
    }

    async canActivateChild() : Promise<boolean | UrlTree>
    {
        return this.canActivate();
    }

    async canLoad() : Promise<boolean>
    {
        return !!this.canActivate();
    }

    //-----------------------------------------------------------------------

}
